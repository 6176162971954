import styled from 'styled-components/macro'

export const Chart = styled.div`
  position: relative;
  display: block;
  max-width: 170px;

  & > svg {
    width: 100%;
    height: auto;
  }

  ${props =>
    props.center &&
    `
    margin: 0 auto;
  `}
`

const thickness = '8px'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: ${thickness};
  left: ${thickness};
  right: ${thickness};
  bottom: ${thickness};
  text-align: center;
`

export const BasicDonutChartLabel = styled.p`
  font-family: 'Open Sans';
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
`

export const BasicDonutChartValue = styled.h3`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.6px;
  padding: 0;
  margin: 0;
`
