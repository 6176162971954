import React from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ProjectProvider } from 'context/ProjectContext/ProjectContext'
import routes from './routes/index'
import {
  StokrLoader,
  LoginWithOTPFlow,
  AuthProvider,
  AuthConsumer,
} from '@stokr/components-library'

const App = () => {
  const checkVentureUserIsValid = (user) => {
    if (user.user_type !== 'company_sa' && user.user_type !== 'company_pa') {
      window.location.href = `https://dashboard.${process.env.REACT_APP_WEBSITE_DOMAIN}`
      return true
    }
    return false
  }

  return (
    <AuthProvider customValidateGetUser={checkVentureUserIsValid}>
      <AuthConsumer>
        {({ user, isFetchingUser }) =>
          user ? (
            <ProjectProvider user={user}>
              <Routes>
                {routes.map(({ path, component }) => (
                  <Route key={path} path={path} element={component} />
                ))}
              </Routes>
            </ProjectProvider>
          ) : isFetchingUser ? (
            <StokrLoader />
          ) : (
            <LoginWithOTPFlow withBackground />
          )
        }
      </AuthConsumer>
    </AuthProvider>
  )
}

export default App
