import React from 'react'
import PropTypes from 'prop-types'

import { ColorBoxWithTitle } from 'components/AdminDashboard/ColorBoxWithTitle/ColorBoxWithTitle.styles'

import {
  BottomSlot,
  Container,
  ContentRow,
  ContentCol,
  Title,
  Content,
  RightSlot,
  ContentCenter,
  ColorBoxWrapper,
} from './OverviewItem.styles'

const OverviewItem = ({
  title,
  content,
  bottom,
  right,
  rightAlign,
  boxColor,
  boxText,
}) => (
  <Container>
    <ContentRow>
      <ContentCol>
        <Title>{title}</Title>
        <ContentCenter>
          {content && <Content>{content}</Content>}
          {boxColor && boxText && (
            <ColorBoxWrapper>
              <ColorBoxWithTitle color="#ffffff" background={boxColor}>
                {boxText}
              </ColorBoxWithTitle>
            </ColorBoxWrapper>
          )}
        </ContentCenter>
      </ContentCol>
      {right && <RightSlot rightAlign={rightAlign}>{right}</RightSlot>}
    </ContentRow>
    {bottom && <BottomSlot>{bottom}</BottomSlot>}
  </Container>
)

OverviewItem.propTypes = {
  title: PropTypes.node.isRequired,
  content: PropTypes.node,
  bottom: PropTypes.node,
  right: PropTypes.node,
  rightAlign: PropTypes.bool,
  boxColor: PropTypes.string,
  boxText: PropTypes.string,
}

OverviewItem.defaultProps = {
  content: '',
  bottom: '',
  right: '',
  rightAlign: false,
  boxColor: '',
  boxText: '',
}

export default OverviewItem
