import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import getAuthHeader from 'utils/getAuthHeader'

const TeamContext = React.createContext()

export class TeamProvider extends Component {
  state = {
    users: [],
    error: null,
    success: false,
  }

  componentDidMount() {
    this.getData()
  }

  getData = async () => {
    try {
      const users = (
        await axios.post(
          `${process.env.REACT_APP_API_URL}/user/list`,
          {},
          { headers: getAuthHeader() },
        )
      ).data.list

      this.setState({
        users: users.map((user) => ({
          ...user,
          isSelected: false,
        })),
        error: null,
      })
    } catch (error) {
      this.setState({ error: error.message })
    }
  }

  toggleMemberSelection = (e) => {
    const { users } = this.state
    const { name, checked } = e.target

    this.setState({
      users: users.map((member) =>
        member._id !== name
          ? member
          : {
              ...member,
              isSelected: checked,
            },
      ),
    })
  }

  addNewMember = ({ email, sto_name }) => {
    this.setState({ success: false, error: null })
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/auth/create-user`,
        {
          email,
          sto_name,
          userType: 'company_sa',
          isActive: true,
        },
        { headers: getAuthHeader() },
      )
      .then((response) => {
        this.getData()
        this.setState({ success: true })
      })
      .catch((error) => {
        this.setState({ error: error.message })
      })
  }

  removeMemberItem = (id, bulk = false) => {
    if (bulk) {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/user/delete`,
        {
          userId: id,
        },
        { headers: getAuthHeader() },
      )
    }
    return axios
      .post(
        `${process.env.REACT_APP_API_URL}/user/delete`,
        {
          userId: id,
        },
        { headers: getAuthHeader() },
      )
      .then((response) => {
        this.getData()
      })
      .catch((error) => {
        this.setState({ error: error.message })
      })
  }

  removeBulkMemberItem = async (users) => {
    Promise.all(users.map((user) => this.removeMemberItem(user._id, true)))
      .then((response) => {
        this.getData()
      })
      .catch((error) => {
        this.setState({ error: error.message })
      })

    this.setState({
      error: null,
    })
  }

  render() {
    const { children } = this.props
    const { error, users, success } = this.state

    return (
      <TeamContext.Provider
        value={{
          error,
          success,
          users,
          toggleMemberSelection: this.toggleMemberSelection,
          addNewMember: this.addNewMember,
          removeMemberItem: this.removeMemberItem,
          removeBulkMemberItem: this.removeBulkMemberItem,
        }}
      >
        {children}
      </TeamContext.Provider>
    )
  }
}

TeamProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const TeamConsumer = TeamContext.Consumer
