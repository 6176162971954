import styled, { css } from 'styled-components/macro'
import { Wrapper, InputWrap } from 'components/Input/Input.styles'

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 50px 20px;
  background-color: ${(props) => props.theme.cWhite};
  border: 1px solid transparent;
  transition: 0.2s border-color;

  ${(props) =>
    props.active &&
    `
    border: 1px solid ${props.theme.cPrimary};
  `}
`

export const ImageWrapper = styled.div`
  overflow: hidden;
  display: block;
  flex-grow: 0;
  height: 120px;
  width: 120px;
  border-radius: 60px;
  margin-bottom: 25px;
  box-shadow: none;
`

export const Avatar = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`

export const InputWrapper = styled.div`
  flex-grow: 0;
  margin-top: 20px;
  margin-bottom: 30px;

  ${(props) => css`
    ${Wrapper} {
      ${InputWrap} {
        & > input {
          border-radius: 22px;
          border: 1px solid ${props.theme.cGrey};
          width: 100%;
          height: 44px;
          padding: 12px 28px;
          transition: border-color 0.2s ease;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;

          &:focus,
          &:hover {
            border-color: ${props.theme.cPrimary};
          }

          &::placeholder {
            color: ${props.theme.cGrey2};
            opacity: 1;
          }
        }
      }
    }
  `}
`

export const Title = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.6px;
  text-align: center;
  padding-bottom: 4px;
`

export const ErrorMessageBox = styled.div`
  height: 15px;
  position: relative;
  top: -15px;
`

export const ErrorMessage = styled.p`
  ${(props) => css`
    color: ${props.theme.cWarning};
  `}
`

export const SuccessMessageBox = styled.div`
  height: 15px;
  position: relative;
  top: -15px;
`

export const SuccessMessage = styled.p`
  ${(props) => css`
    color: ${props.theme.cSuccess};
  `}
`
