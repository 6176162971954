import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import { uid } from 'react-uid'

import Button from 'components/Button/Button.styles'
import InfoIcon from 'components/InfoIcon/InfoIcon'
import ComponentScroll from 'components/ComponentScroll/ComponentScroll'
import EmojiPicker from 'components/AdminDashboard/EmojiPicker/EmojiPicker'

import {
  Container,
  Body,
  Foot,
  InfoIconWrapper,
  Infos,
  Counter,
  ErrorMsg,
  Actions,
  TextWrapper,
  TextArea,
  TextPusher,
  EmojiPickerWrapper,
  Extension,
} from './TextEditor.styles'

const OptionalScroll = ({ children, minHeight, maxHeight }) => {
  if (maxHeight !== 0) {
    return (
      <ComponentScroll
        autoHeight
        autoHeightMin={minHeight + 10}
        autoHeightMax={maxHeight + 10}
        offset={-10}
        barPadding={24}
      >
        {children}
      </ComponentScroll>
    )
  }
  return <Fragment>{children}</Fragment>
}

OptionalScroll.propTypes = {
  children: PropTypes.node.isRequired,
  minHeight: PropTypes.number,
  maxHeight: PropTypes.number,
}

OptionalScroll.defaultProps = {
  minHeight: 66,
  maxHeight: 0,
}

class TextEditor extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      currentValue: props.value,
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      currentValue: nextProps.value,
    }
  }

  insertAtCaret = (text = '') => {
    /*
      based on https://stackoverflow.com/a/19961519
    */

    const el = this.textarea

    if (document.selection) {
      // IE
      el.focus()
      const sel = document.selection.createRange()
      sel.text = text
      this.emitInput()
      return
    }

    if (el.selectionStart || el.selectionStart === 0) {
      // Others
      const startPos = el.selectionStart
      const endPos = el.selectionEnd
      const { value } = el
      el.value = `${value.substring(0, startPos)}${text}${value.substring(
        endPos,
        value.length,
      )}`
      el.selectionStart = startPos + text.length
      el.selectionEnd = startPos + text.length
      this.emitInput()
      return
    }

    el.value += text
    this.emitInput()
  }

  emitInput = () => {
    this.textarea.dispatchEvent(new Event('input', { bubbles: true }))
  }

  onFocus = (e) => {
    const { onFocus } = this.props
    onFocus && onFocus(e)
  }

  onBlur = (e) => {
    const { onBlur } = this.props
    onBlur && onBlur(e)
  }

  onChange = (e) => {
    const { onChange, limit } = this.props
    onChange && onChange(e)

    let currentValue = e.target.value
    if (limit) currentValue = currentValue.slice(0, limit)

    this.setState({
      currentValue,
    })
  }

  valuetoElements = (value) =>
    value
      .replace(/\n/g, '{??}<br />{??}')
      .split('{??}')
      .map((line, lineKey) => {
        if (line === '<br />') return <br key={uid(line, lineKey)} />
        if (line) return <Fragment key={uid(line, lineKey)}>{line}</Fragment>
        return <span key={uid(line, lineKey)}>&nbsp;</span>
      })

  onEmojiSelect = (emoji) => {
    this.insertAtCaret(emoji.native)
  }

  render() {
    const { currentValue } = this.state
    const {
      id,
      name,
      placeholder,
      info,
      error,
      touched,
      limit,
      done,
      cancel,
      minHeight,
      maxHeight,
      noRadiusTop,
      extension,
      isUpdates,
      emojiPicker,
      noFooter,
      smallPadding,
      maxLength,
    } = this.props

    return (
      <Container noRadiusTop={noRadiusTop}>
        <Body smallPadding={smallPadding}>
          <OptionalScroll minHeight={minHeight} maxHeight={maxHeight}>
            <TextWrapper>
              <TextPusher>{this.valuetoElements(currentValue)}</TextPusher>
              <TextArea
                ref={(el) => {
                  this.textarea = el
                }}
                id={id}
                name={name}
                value={currentValue}
                onInput={this.onChange}
                onChange={this.onChange}
                onBlur={this.onBlur}
                onFocus={this.onFocus}
                maxLength={maxLength}
                placeholder={placeholder}
              />
            </TextWrapper>
          </OptionalScroll>
          {info !== '' && (
            <InfoIconWrapper>
              <InfoIcon position="top" title={info} />
            </InfoIconWrapper>
          )}
          {emojiPicker && (
            <EmojiPickerWrapper>
              <EmojiPicker handleSelect={this.onEmojiSelect} />
            </EmojiPickerWrapper>
          )}
        </Body>
        {!noFooter && (
          <Foot>
            <Infos>
              <Counter>
                {`${currentValue.length}${
                  limit !== 0 ? `/${limit}` : ''
                } characters`}
              </Counter>
              {error !== '' && touched && <ErrorMsg>{error}</ErrorMsg>}
            </Infos>
            <Actions>
              {extension && <Extension>{extension}</Extension>}
              {cancel && (
                <Button
                  onlyText
                  onClick={cancel.onClick}
                  isUpdates={isUpdates}
                  isCancel
                >
                  {cancel.label}
                </Button>
              )}
              {done && (
                <Button
                  negative={error !== ''}
                  onClick={done.onClick}
                  isUpdates={isUpdates}
                  isDone
                >
                  {done.label}
                </Button>
              )}
            </Actions>
          </Foot>
        )}
      </Container>
    )
  }
}

TextEditor.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  limit: PropTypes.number,
  info: PropTypes.string,
  maxHeight: PropTypes.number,
  minHeight: PropTypes.number,
  done: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  cancel: PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }),
  noRadiusTop: PropTypes.bool,
  extension: PropTypes.node,
  emojiPicker: PropTypes.bool,
  noFooter: PropTypes.bool,
  smallPadding: PropTypes.bool,
  maxLength: PropTypes.number,
}

TextEditor.defaultProps = {
  placeholder: '',
  value: '',
  error: '',
  touched: false,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  cancel: null,
  done: null,
  limit: 0,
  info: '',
  maxHeight: 0,
  minHeight: 66,
  noRadiusTop: false,
  extension: null,
  emojiPicker: true,
  noFooter: false,
  smallPadding: false,
  maxLength: 1000,
}

export default TextEditor
