import styled from 'styled-components/macro'

export const StyledTabs = styled.div`
  position: relative;
  width: 100%;
`

export const Tab = styled.div`
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
`
