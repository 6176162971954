import React from 'react'
import PropTypes from 'prop-types'

import { Container, Flag, FlagPlaceholder, Text } from './Country.styles'

const Country = ({ countryAbbreviation, children }) => (
  <Container>
    {/* {countryAbbreviation ? (
      <>
        <Flag
          src={`https://stokr.matomo.cloud/plugins/Morpheus/icons/dist/flags/${countryAbbreviation}.png`}
        />
      </>
    ) : (
      <FlagPlaceholder />
    )} */}
    <Text>{children}</Text>
  </Container>
)

Country.propTypes = {
  countryAbbreviation: PropTypes.string,
  children: PropTypes.node,
}

Country.defaultProps = {
  children: null,
}

export default Country
