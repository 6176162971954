import axios from 'axios'
import { ProjectContext } from 'context/ProjectContext/ProjectContext'

class Matomo {
  MATOMO_API_URL = 'https://stokr.matomo.cloud'
  MATOMO_AUTH_TOKEN = 'c3989ca407e65354764eb16bfc0def79'

  MATOMO_DEFAULT_PARAMS = {
    module: 'API',
    format: 'json',
    token_auth: this.MATOMO_AUTH_TOKEN,
    idSite: null,
  }

  constructor(siteId) {
    this.MATOMO_DEFAULT_PARAMS.idSite = siteId
  }

  // Receive the class method and its arguments
  // Set site ID if not defined
  // Call the method with its arguments
  async init(callback, ...rest) {
    const response = await callback.bind(this)(...rest)
    if (response.result === 'error') {
      throw response
    }
    return response
  }

  /**
   * Get real time counters
   * @param lastMinutes
   */
  async LiveGetCounters(lastMinutes, column) {
    try {
      const response = await axios.get(this.MATOMO_API_URL, {
        params: {
          ...this.MATOMO_DEFAULT_PARAMS,
          method: 'Live.getCounters',
          lastMinutes: lastMinutes,
          showColumns: column,
        },
      })
      return parseInt(response.data[0][column])
    } catch (_) {
      return 0
    }
  }

  /**
   * Get visits summary for date range
   * @param range
   */
  async GetVisitsSummary(period, date) {
    try {
      const response = await axios.get(this.MATOMO_API_URL, {
        params: {
          ...this.MATOMO_DEFAULT_PARAMS,
          method: 'VisitsSummary.get',
          period: period,
          date: date,
        },
      })
      return response.data
    } catch (_) {
      return {}
    }
  }

  /**
   * Get summary per country
   * @param range
   */
  async GetCountrySummary(period, date) {
    try {
      const response = await axios.get(this.MATOMO_API_URL, {
        params: {
          ...this.MATOMO_DEFAULT_PARAMS,
          method: 'UserCountry.getCountry',
          period: period,
          date: date,
        },
      })
      return response.data
    } catch (_) {
      return []
    }
  }

  /**
   * Get summary for social networks
   * @param range
   */
  async GetSocialSummary(period, date) {
    try {
      const response = await axios.get(this.MATOMO_API_URL, {
        params: {
          ...this.MATOMO_DEFAULT_PARAMS,
          method: 'Referrers.getSocials',
          period: period,
          date: date,
        },
      })
      return response.data
    } catch (_) {
      return []
    }
  }

  /**
   * Get summary for os families
   * @param range
   */
  async GetDeviceOsFamilies(period, date) {
    try {
      const response = await axios.get(this.MATOMO_API_URL, {
        params: {
          ...this.MATOMO_DEFAULT_PARAMS,
          method: 'DevicesDetection.getOsFamilies',
          period: period,
          date: date,
        },
      })
      return response.data
    } catch (_) {
      return []
    }
  }

  /**
   * Get summary for browsers
   * @param range
   */
  async GetDeviceBrowsers(period, date) {
    try {
      const response = await axios.get(this.MATOMO_API_URL, {
        params: {
          ...this.MATOMO_DEFAULT_PARAMS,
          method: 'DevicesDetection.getBrowsers',
          period: period,
          date: date,
        },
      })
      return response.data
    } catch (_) {
      return []
    }
  }

  /**
   * Get summary for browsers
   * @param range
   */
  async GetDeviceModels(period, date) {
    try {
      const response = await axios.get(this.MATOMO_API_URL, {
        params: {
          ...this.MATOMO_DEFAULT_PARAMS,
          method: 'DevicesDetection.getModel',
          period: period,
          date: date,
        },
      })
      return response.data
    } catch (_) {
      return []
    }
  }

  /**
   * Get visit times per local time
   * @param range
   */
  async GetVisitTimePerLocalTime(period, date) {
    try {
      const response = await axios.get(this.MATOMO_API_URL, {
        params: {
          ...this.MATOMO_DEFAULT_PARAMS,
          method: 'VisitTime.getVisitInformationPerLocalTime ',
          period: period,
          date: date,
        },
      })
      return response.data
    } catch (_) {
      return {}
    }
  }
}

export default Matomo
