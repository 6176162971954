import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  text-align: center;
  padding-bottom: 20px;
`

export const Title = styled.h3`
  color: ${props => props.theme.cBlack};
  font-family: 'Open Sans';
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 2px;
  line-height: 20px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
`

export const Value = styled.p`
  color: ${props => props.theme.cBlack};
  font-family: 'Open Sans';
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1.13px;
  line-height: 30px;
  margin: 0;
  margin-top: 8px;
`

export const Percent = styled.div`
  margin-top: 20px;
`
