import React from 'react'
import { usePagination, DOTS } from './usePagination'
import { StyledList } from './Pagination.styles'
import Icon, { iconsMap } from 'components/Icon/Icon.style'
import PropTypes from 'prop-types'

const Pagination = (props) => {
  const { onPageChange, totalCount, siblingCount, currentPage, pageSize } =
    props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    onPageChange(1)
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange[paginationRange.length - 1]
  return (
    <StyledList>
      {/* Left navigation arrow */}
      <StyledList.Item disabled={currentPage === 1} onClick={onPrevious} arrow>
        <Icon icon={iconsMap.arrowLeft} />
      </StyledList.Item>

      {paginationRange.map((pageNumber) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <StyledList.Item dots key={pageNumber}>
              &#8230;
            </StyledList.Item>
          )
        }

        // Render our Page Pills
        return (
          <StyledList.Item
            key={pageNumber}
            selected={pageNumber === currentPage}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </StyledList.Item>
        )
      })}

      {/*  Right Navigation arrow */}
      <StyledList.Item
        disabled={currentPage === lastPage}
        onClick={onNext}
        arrow
      >
        <Icon icon={iconsMap.arrowRight} />
      </StyledList.Item>
    </StyledList>
  )
}

Pagination.propTypes = {
  onPageChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  siblingCount: PropTypes.number,
  pageSize: PropTypes.number,
}
Pagination.defaultProps = {
  siblingCount: 1,
  pageSize: 10,
}

export default Pagination
