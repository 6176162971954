import axios from 'axios'
import getAuthHeader from 'utils/getAuthHeader'

const baseURL = process.env.REACT_APP_API_URL
const baseURLPublic = process.env.REACT_APP_BASE_URL_PUBLIC

class CheckboxClass {
  //ideally this chould be moved to its own context
  static getCheckboxes = async (data) =>
    (
      await axios.post(`${baseURL}/compliance/get-checkboxes`, data, {
        headers: getAuthHeader(),
      })
    ).data
}

export default CheckboxClass
