import React from 'react'

const AdminBadgeSvg = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 15.475 19.243"
    width="15.475"
    height="19.243"
    style={{
      width: '16px',
      height: '20px',
    }}
  >
    <path
      transform="translate(-27 -21.8)"
      d="M34.737,21.8A12.061,12.061,0,0,1,27,24.895V35.021a10.318,10.318,0,0,0,6.627,5.719l1.11.3,1.11-.336a10.139,10.139,0,0,0,6.627-5.719V24.895A12.242,12.242,0,0,1,34.737,21.8Zm2.489,13.288-2.489-1.312-2.489,1.312.471-2.792L30.7,30.311l2.792-.4,1.245-2.523,1.245,2.523,2.792.4L36.756,32.3Z"
    />
  </svg>
)

export default AdminBadgeSvg
