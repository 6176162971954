import React from 'react'
import styled, { keyframes } from 'styled-components'

import media from './media'

export function StokrLoader() {
  const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
`

  const StyledStokrLoader = styled.div`
    align-items: center;
    animation: ${fadeIn} 1.8s ease-in-out forwards;
    background: rgb(254, 254, 254);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 1000;

    ${media.mobileS`
    img {
      height: 15rem;
      width: 15rem;
    }
  `};

    ${media.mobile`
    img {
      height: 18rem;
      width: 18rem;
    }
  `};

    ${media.tablet`
    img {
      height: 30rem;
      width: 30rem;
    }
  `};
  `

  // Render a new image everytime, avoiding jump on the spinning
  const gif = `https://res.cloudinary.com/stokr/image/upload/f_auto,q_auto/v1575035788/Static/stokr_loader_white_400x400_egnhgb.gif?a=${Math.random()}`

  return (
    <StyledStokrLoader>
      <img src={gif} alt="&nbsp;" />
    </StyledStokrLoader>
  )
}

export default StokrLoader
