import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { AdminBadgeSvg } from 'components/SvgIcons'

import {
  Container,
  ImageWrapper,
  ContentWrapper,
  Avatar,
  Name,
  ContentGroup,
  SmallHeader,
  ProjectStats,
  ProjectStat,
  PersonPosition,
  PersonEmail,
  StatLabel,
  StatValue,
} from './TeamOverview.styles'
import avatarPlaceholder from 'static/images/avatar-placeholder.png'
import getProfilePicture from 'utils/getProfilePicture'

const TeamOverview = ({
  name,
  memberRole,
  userId,
  position,
  email,
  projectName,
  stats,
}) => {
  const [profilePicture, setProfilePicture] = useState(avatarPlaceholder)

  useEffect(() => {
    const f = (x) => console.log(x) || x
    const setPicture = async () =>
      setProfilePicture(f(await getProfilePicture(userId)))

    setPicture()
  })

  return (
    <Container>
      <ImageWrapper>
        <Avatar src={profilePicture} alt="avatar" />
      </ImageWrapper>

      <ContentWrapper>
        <ContentGroup>
          <SmallHeader>
            {memberRole === 'company_sa' && <AdminBadgeSvg />}
            <span>{memberRole}</span>
          </SmallHeader>
          <Name>{name}</Name>

          <PersonPosition>{position}</PersonPosition>
          <PersonEmail>{email}</PersonEmail>
        </ContentGroup>

        <ContentGroup>
          <SmallHeader>
            <span>Project</span>
          </SmallHeader>
          <Name>{projectName}</Name>

          {stats && (
            <ProjectStats>
              {stats.map((stat) => (
                <ProjectStat key={stat.name}>
                  <StatLabel>{stat.name}</StatLabel>
                  <StatValue>{stat.count}</StatValue>
                </ProjectStat>
              ))}
            </ProjectStats>
          )}
        </ContentGroup>
      </ContentWrapper>
    </Container>
  )
}

TeamOverview.propTypes = {
  name: PropTypes.string,
  memberRole: PropTypes.string,
  avatar: PropTypes.string,
  position: PropTypes.string,
  email: PropTypes.string,
  projectName: PropTypes.string,
  stats: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      count: PropTypes.number,
    }),
  ),
}

TeamOverview.defaultProps = {
  stats: [],
  name: '',
  memberRole: '',
  avatar: '',
  position: '',
  email: '',
  projectName: '',
}

export default TeamOverview
