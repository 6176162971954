import styled from 'styled-components/macro'

export const Chart = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
`

export default Chart

export const BasicLineChartWrapper = styled.div`
  position: relative;
  display: block;
  width: 100px;

  ${props =>
    props.width &&
    `
    width: ${props.width};
  `}
`
