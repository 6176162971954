import styled from 'styled-components/macro'
import rwd from 'styles/rwd'
import BaseIcon from 'components/Icon/Icon.style'
import { InvestorState } from 'pages/admin-dashboard/private-investor'

export const Container = styled.div`
  position: relative;
`

export const Column = styled.div`
  position: relative;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  ${(props) =>
    props.marginTop &&
    `
  margin-top:10px;`}
`

export const Icon = styled(BaseIcon)`
  font-size: 20px;
  width: 18px;
  text-align: center;
  padding: 0 20px;

  ${(props) =>
    props.state === InvestorState.DONE &&
    `
    font-size: 13px;
    color: ${props.theme.cSuccess};
  `}

  ${(props) =>
    props.state === InvestorState.DENIED &&
    `
    color: ${props.theme.cWarning};
  `}

  
  ${(props) =>
    props.state === InvestorState.NOT_DONE &&
    `
    color: ${props.theme.cYellowWarning};
  `}

  ${(props) =>
    props.state === InvestorState.IN_PROGRESS &&
    `
    opacity: 0.3;
  `}

  ${rwd.Medium`
    padding: 0 30px;
  `}
`

export const Value = styled.div`
  @media (max-width: 450px) {
    min-width: 60%;
  }

  position: relative;
  font-weight: 300;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.4px;
  min-width: 40%;

  ${(props) =>
    props.title &&
    `
   font-weight: bold;
  font-size: 11px !important;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  `}

  ${(props) =>
    props.notes &&
    `
  width: 100%;
    font-size: 11px !important;
    font-style: italic;
  `}

  ${rwd.Large`

  font-size:22px;

  `}
`

export const ValueContainer = styled.div`
  @media (max-width: 450px) {
    width: 100%;
  }
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`
