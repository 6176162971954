import React, { PureComponent, Fragment } from 'react'
import styled from 'styled-components/macro'
import Layout from 'components/Layout/Layout'
import { Row as StdRow, Column as StdColumn } from 'components/Grid/Grid.styles'
import {
  Row,
  Column,
  RowWrapper,
} from 'components/AdminDashboard/Grid/Grid.styles'
import SectionTitle from 'components/SectionTitle/SectionTitle.styles'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
import Text from 'components/Text/Text.styles'
import {
  Card,
  CardTitle,
  CardLeftText,
  CardRightText,
  CardTitleContainer,
} from 'components/AdminDashboard/Card/Card.styles'
import BarChart from 'components/BarChart/BarChart'
import BarChartLegend from 'components/BarChartLegend/BarChartLegend'
import { BarChartTooltip } from 'components/BarChart/BarChart.styles'
import TeamMemberItem from 'components/AdminDashboard/TeamMemberItem/TeamMemberItem'
import TeamMemberItemAdd from 'components/AdminDashboard/TeamMemberItemAdd/TeamMemberItemAdd'
import TeamOverview from 'components/TeamOverview/TeamOverview'
import OverviewItem from 'components/AdminDashboard/OverviewItem/OverviewItem'
import { RightSlotText } from 'components/AdminDashboard/OverviewItem/OverviewItem.styles'
import { Trend } from 'components/AdminDashboard/Trend/Trend.styles'
import Country from 'components/AdminDashboard/Country/Country'
import ItemOptions from 'components/AdminDashboard/ItemOptions/ItemOptions'
import CircleProgress from 'components/AdminDashboard/CircleProgress/CircleProgress'
import UpdateItem from 'components/AdminDashboard/UpdateItem/UpdateItem'
import CommentItem from 'components/AdminDashboard/CommentItem/CommentItem'
import NotificationItem from 'components/AdminDashboard/NotificationItem/NotificationItem'
import Table from 'components/AdminDashboard/Table/Table'
import { TableCellType } from 'components/AdminDashboard/Table/Table.styles'
import CountriesMap from 'components/AdminDashboard/CountriesMap/CountriesMap'
import CountriesMapLegend from 'components/AdminDashboard/CountriesMapLegend/CountriesMapLegend'
import { CountriesMapTooltip } from 'components/AdminDashboard/CountriesMap/CountriesMap.styles'
import DateTime from 'components/AdminDashboard/DateTime/DateTime'
import LineChart from 'components/AdminDashboard/LineChart/LineChart'
import BasicLineChart from 'components/AdminDashboard/BasicLineChart/BasicLineChart'
import BasicLineChartInfo from 'components/AdminDashboard/BasicLineChartInfo/BasicLineChartInfo'
import { BasicLineChartWrapper } from 'components/AdminDashboard/BasicLineChart/BasicLineChart.styles'

import { LineChartTooltip } from 'components/AdminDashboard/LineChart/LineChart.styles'
import StatusItem from 'components/AdminDashboard/StatusItem/StatusItem'
import SingleProgressBar from 'components/AdminDashboard/SingleProgressBar/SingleProgressBar'
import CapItem from 'components/AdminDashboard/CapItem/CapItem'
import ContributorItem from 'components/AdminDashboard/ContributorItem/ContributorItem'
import CategoryButton from 'components/AdminDashboard/CategoryButton/CategoryButton'
import ButtonTabs, {
  ButtonTab,
} from 'components/AdminDashboard/ButtonTabs/ButtonTabs'
import Heatmap from 'components/AdminDashboard/Heatmap/Heatmap'
import BasicDonutChart from 'components/AdminDashboard/BasicDonutChart/BasicDonutChart'
import {
  BasicDonutChartLabel,
  BasicDonutChartValue,
} from 'components/AdminDashboard/BasicDonutChart/BasicDonutChart.styles'
import BasicDonutChartLegend from 'components/AdminDashboard/BasicDonutChartLegend/BasicDonutChartLegend'
import InvestmentRatio from 'components/AdminDashboard/InvestmentRatio/InvestmentRatio'
import TextEditor from 'components/AdminDashboard/TextEditor/TextEditor'
import { ColorBoxWithTitle } from 'components/AdminDashboard/ColorBoxWithTitle/ColorBoxWithTitle.styles'

import memberItemPicture from 'static/images/member-item-picture.png'
import profilePicture from 'static/images/profile-picture.png'
import avatar from 'static/images/avatar.png'

const ComponentContainer = styled.div`
  margin: -1px;
  border: 1px dashed #ff33aa;

  ${(props) =>
    props.inline &&
    `
    display: inline-block;
  `}
`

const tableColumns = [
  {
    key: 'timestamp',
    label: 'Timestamp',
    width: '25%',
  },
  {
    key: 'token_sold',
    label: 'Token sold',
    width: '20%',
  },
  {
    key: 'country',
    label: 'Country',
    width: '20%',
  },
  {
    key: 'eth_addess',
    label: 'Ethereum address',
    width: '35%',
  },
]

const tableData = [
  {
    id: '01',
    timestamp: new Date('08/02/2019 01:30'),
    token_sold: '+€2500.00',
    country: 'pl',
    country_name: 'Poland',
    eth_addess: '0x61c01767fb484241e95d6435f242f9cf1246e5f2',
  },
  {
    id: '02',
    timestamp: new Date('08/06/2019 03:27'),
    token_sold: '-€2500.00',
    country: 'de',
    country_name: 'Germany',
    eth_addess: '0x61c01767fb484241e95d6435f242f9cf1246e5f2',
  },
  {
    id: '03',
    timestamp: new Date('09/03/2019 17:22'),
    token_sold: '+€500.00',
    country: 'it',
    country_name: 'Italy',
    eth_addess: '0x61c01767fb484241e95d6435f242f9cf1246e5f2',
  },
  {
    id: '04',
    timestamp: new Date('09/05/2019 16:11'),
    token_sold: '-€1500.00',
    country: 'pl',
    country_name: 'Poland',
    eth_addess: '0x61c01767fb484241e95d6435f242f9cf1246e5f2',
  },
  {
    id: '05',
    timestamp: new Date('11/07/2019 01:30'),
    token_sold: '+€2000.00',
    country: 'gb',
    country_name: 'United Kingdom',
    eth_addess: '0x61c01767fb484241e95d6435f242f9cf1246e5f2',
  },
  {
    id: '06',
    timestamp: new Date('12/12/2019 14:45'),
    token_sold: '-€1500.00',
    country: 'de',
    country_name: 'Germany',
    eth_addess: '0x61c01767fb484241e95d6435f242f9cf1246e5f2',
  },
]

const tableColumnsCountry = [
  {
    key: 'country',
    label: 'Country',
    width: '160px',
  },
  {
    key: 'visits',
    label: 'Visits',
    width: '80px',
  },
  {
    key: 'unique_visits',
    label: 'Unique visits',
    width: '100px',
  },
  {
    key: 'bounce_rate',
    label: 'Bounce rate',
    width: '200px',
  },
]

const tableDataCountry = [
  {
    id: '01',
    country: 'gb',
    country_name: 'United Kingdom',
    visits: '4,182',
    unique_visits: '2,123',
    bounce_rate: '85,55%',
    chart: {
      id: 'chart1',
      fill: '#E4ECF9',
      stroke: '#0050CA',
      data: [
        { date: 1, value: 1 },
        { date: 2, value: 5 },
        { date: 3, value: 2 },
        { date: 4, value: 2 },
        { date: 5, value: 3 },
        { date: 6, value: 9 },
      ],
    },
  },
  {
    id: '02',
    country: 'de',
    country_name: 'Germany',
    visits: '4,182',
    unique_visits: '2,123',
    bounce_rate: '85,55%',
    chart: {
      id: 'chart1',
      fill: '#E4ECF9',
      stroke: '#0050CA',
      data: [
        { date: 1, value: 1 },
        { date: 2, value: 5 },
        { date: 3, value: 2 },
        { date: 4, value: 2 },
        { date: 5, value: 3 },
        { date: 6, value: 9 },
      ],
    },
  },
  {
    id: '03',
    country: 'pl',
    country_name: 'Poland',
    visits: '4,182',
    unique_visits: '2,123',
    bounce_rate: '85,55%',
    chart: {
      id: 'chart1',
      fill: '#E4ECF9',
      stroke: '#0050CA',
      data: [
        { date: 1, value: 1 },
        { date: 2, value: 5 },
        { date: 3, value: 2 },
        { date: 4, value: 2 },
        { date: 5, value: 3 },
        { date: 6, value: 9 },
      ],
    },
  },
]

const countriesMapCategories = {
  default: {
    fill: '#f0f0f0',
    stroke: '#dbdbdb',
    label: '0',
    percent: '19,5%',
  },
  from1to100: {
    fill: '#9cb9eb',
    stroke: '#4e76bb',
    label: '1-100',
    percent: '26,4%',
  },
  from101to500: {
    fill: '#cefec6',
    stroke: '#7ff36d',
    label: '101-500',
    percent: '42,6%',
  },
  from500: {
    fill: '#f8a7a4',
    stroke: '#a73b38',
    label: '500+',
    percent: '11,5%',
  },
}

const createCountriesMapTooltip = (country) => (
  <CountriesMapTooltip>
    <h3>{country.properties.name}</h3>
    <h4>{`${country.properties.investors} investors`}</h4>
    <p>{`(€${country.properties.value.toLocaleString()})`}</p>
  </CountriesMapTooltip>
)

const mapCountryData = {
  CAN: { category: 'from1to100' },
  AUS: { category: 'from1to100' },
  COD: { category: 'from1to100' },
  TZA: { category: 'from1to100' },
  KEN: { category: 'from1to100' },
  ETH: { category: 'from1to100' },
  SSD: { category: 'from1to100' },
  IND: { category: 'from1to100' },
  PAK: { category: 'from1to100' },
  AFG: { category: 'from1to100' },
  MMR: { category: 'from1to100' },
  THA: { category: 'from1to100' },
  IDN: { category: 'from1to100' },
  FIN: { category: 'from1to100' },
  SWE: { category: 'from1to100' },
  NOR: { category: 'from1to100' },
  GBR: { category: 'from1to100' },
  IRL: { category: 'from1to100' },
  USA: { category: 'from101to500' },
  RUS: { category: 'from101to500' },
  MNG: { category: 'from101to500' },
  KAZ: { category: 'from101to500' },
  MRT: { category: 'from101to500' },
  TCD: { category: 'from101to500' },
  BRA: { category: 'from101to500' },
  IRN: { category: 'from101to500' },
  DZA: { category: 'from500' },
  LBY: { category: 'from500' },
  ESP: { category: 'from500' },
  FRA: { category: 'from500' },
  DEU: { category: 'from500' },
  POL: { category: 'from500' },
  BLR: { category: 'from500' },
  UKR: { category: 'from500' },
  ITA: { category: 'from500' },
  CZE: { category: 'from500' },
  ROU: { category: 'from500' },
  HUN: { category: 'from500' },
  AUT: { category: 'from500' },
  MEX: { category: 'from500' },
  PER: { category: 'from500' },
  COL: { category: 'from500' },
  VEN: { category: 'from500' },
  ECU: { category: 'from500' },
  NIC: { category: 'from500' },
  HND: { category: 'from500' },
  SUR: { category: 'from500' },
  GUY: { category: 'from500' },
  CUB: { category: 'from500' },
  DOM: { category: 'from500' },
  PAN: { category: 'from500' },
  JAM: { category: 'from500' },
  GTM: { category: 'from500' },
  CRI: { category: 'from500' },
  HTI: { category: 'from500' },
  PRI: { category: 'from500' },
}

const heatmapDaysLabel = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']

const heatmapHoursLabel = [
  '12AM',
  '1AM',
  '2AM',
  '3AM',
  '4AM',
  '5AM',
  '6AM',
  '7AM',
  '8AM',
  '9AM',
  '10AM',
  '11AM',
  '12PM',
  '1PM',
  '2PM',
  '3PM',
  '4PM',
  '5PM',
  '6PM',
  '7PM',
  '8PM',
  '9PM',
  '10PM',
  '11PM',
]

const heatmapData = [
  { group: 'MON', variable: '12AM', value: 12 },
  { group: 'MON', variable: '1AM', value: 1 },
  { group: 'MON', variable: '2AM', value: 18 },
  { group: 'MON', variable: '3AM', value: 0 },
  { group: 'MON', variable: '4AM', value: 22 },
  { group: 'MON', variable: '5AM', value: 32 },
  { group: 'MON', variable: '6AM', value: 32 },
  { group: 'MON', variable: '7AM', value: 53 },
  { group: 'MON', variable: '8AM', value: 54 },
  { group: 'MON', variable: '9AM', value: 13 },
  { group: 'MON', variable: '10AM', value: 66 },
  { group: 'MON', variable: '11AM', value: 32 },
  { group: 'MON', variable: '12PM', value: 9 },
  { group: 'MON', variable: '1PM', value: 1 },
  { group: 'MON', variable: '2PM', value: 3 },
  { group: 'MON', variable: '3PM', value: 8 },
  { group: 'MON', variable: '4PM', value: 4 },
  { group: 'MON', variable: '5PM', value: 2 },
  { group: 'MON', variable: '6PM', value: 13 },
  { group: 'MON', variable: '7PM', value: 16 },
  { group: 'MON', variable: '8PM', value: 32 },
  { group: 'MON', variable: '9PM', value: 16 },
  { group: 'MON', variable: '10PM', value: 22 },
  { group: 'MON', variable: '11PM', value: 42 },
  { group: 'TUE', variable: '12AM', value: 12 },
  { group: 'TUE', variable: '1AM', value: 1 },
  { group: 'TUE', variable: '2AM', value: 18 },
  { group: 'TUE', variable: '3AM', value: 68 },
  { group: 'TUE', variable: '4AM', value: 22 },
  { group: 'TUE', variable: '5AM', value: 32 },
  { group: 'TUE', variable: '6AM', value: 32 },
  { group: 'TUE', variable: '7AM', value: 53 },
  { group: 'TUE', variable: '8AM', value: 54 },
  { group: 'TUE', variable: '9AM', value: 13 },
  { group: 'TUE', variable: '10AM', value: 66 },
  { group: 'TUE', variable: '11AM', value: 32 },
  { group: 'TUE', variable: '12PM', value: 55 },
  { group: 'TUE', variable: '1PM', value: 13 },
  { group: 'TUE', variable: '2PM', value: 30 },
  { group: 'TUE', variable: '3PM', value: 8 },
  { group: 'TUE', variable: '4PM', value: 4 },
  { group: 'TUE', variable: '5PM', value: 2 },
  { group: 'TUE', variable: '6PM', value: 13 },
  { group: 'TUE', variable: '7PM', value: 16 },
  { group: 'TUE', variable: '8PM', value: 32 },
  { group: 'TUE', variable: '9PM', value: 16 },
  { group: 'TUE', variable: '10PM', value: 22 },
  { group: 'TUE', variable: '11PM', value: 42 },
  { group: 'WED', variable: '12AM', value: 12 },
  { group: 'WED', variable: '1AM', value: 1 },
  { group: 'WED', variable: '2AM', value: 18 },
  { group: 'WED', variable: '3AM', value: 68 },
  { group: 'WED', variable: '4AM', value: 22 },
  { group: 'WED', variable: '5AM', value: 32 },
  { group: 'WED', variable: '6AM', value: 32 },
  { group: 'WED', variable: '7AM', value: 53 },
  { group: 'WED', variable: '8AM', value: 54 },
  { group: 'WED', variable: '9AM', value: 13 },
  { group: 'WED', variable: '10AM', value: 66 },
  { group: 'WED', variable: '11AM', value: 32 },
  { group: 'WED', variable: '12PM', value: 55 },
  { group: 'WED', variable: '1PM', value: 13 },
  { group: 'WED', variable: '2PM', value: 30 },
  { group: 'WED', variable: '3PM', value: 8 },
  { group: 'WED', variable: '4PM', value: 4 },
  { group: 'WED', variable: '5PM', value: 2 },
  { group: 'WED', variable: '6PM', value: 43 },
  { group: 'WED', variable: '7PM', value: 16 },
  { group: 'WED', variable: '8PM', value: 12 },
  { group: 'WED', variable: '9PM', value: 16 },
  { group: 'WED', variable: '10PM', value: 22 },
  { group: 'WED', variable: '11PM', value: 42 },
  { group: 'THU', variable: '12AM', value: 12 },
  { group: 'THU', variable: '1AM', value: 1 },
  { group: 'THU', variable: '2AM', value: 18 },
  { group: 'THU', variable: '3AM', value: 68 },
  { group: 'THU', variable: '4AM', value: 22 },
  { group: 'THU', variable: '5AM', value: 32 },
  { group: 'THU', variable: '6AM', value: 22 },
  { group: 'THU', variable: '7AM', value: 53 },
  { group: 'THU', variable: '8AM', value: 54 },
  { group: 'THU', variable: '9AM', value: 13 },
  { group: 'THU', variable: '10AM', value: 66 },
  { group: 'THU', variable: '11AM', value: 32 },
  { group: 'THU', variable: '12PM', value: 55 },
  { group: 'THU', variable: '1PM', value: 13 },
  { group: 'THU', variable: '2PM', value: 30 },
  { group: 'THU', variable: '3PM', value: 8 },
  { group: 'THU', variable: '4PM', value: 4 },
  { group: 'THU', variable: '5PM', value: 2 },
  { group: 'THU', variable: '6PM', value: 13 },
  { group: 'THU', variable: '7PM', value: 16 },
  { group: 'THU', variable: '8PM', value: 32 },
  { group: 'THU', variable: '9PM', value: 16 },
  { group: 'THU', variable: '10PM', value: 44 },
  { group: 'THU', variable: '11PM', value: 42 },
  { group: 'FRI', variable: '12AM', value: 12 },
  { group: 'FRI', variable: '1AM', value: 1 },
  { group: 'FRI', variable: '2AM', value: 18 },
  { group: 'FRI', variable: '3AM', value: 68 },
  { group: 'FRI', variable: '4AM', value: 22 },
  { group: 'FRI', variable: '5AM', value: 32 },
  { group: 'FRI', variable: '6AM', value: 32 },
  { group: 'FRI', variable: '7AM', value: 53 },
  { group: 'FRI', variable: '8AM', value: 54 },
  { group: 'FRI', variable: '9AM', value: 13 },
  { group: 'FRI', variable: '10AM', value: 66 },
  { group: 'FRI', variable: '11AM', value: 32 },
  { group: 'FRI', variable: '12PM', value: 55 },
  { group: 'FRI', variable: '1PM', value: 13 },
  { group: 'FRI', variable: '2PM', value: 30 },
  { group: 'FRI', variable: '3PM', value: 8 },
  { group: 'FRI', variable: '4PM', value: 4 },
  { group: 'FRI', variable: '5PM', value: 2 },
  { group: 'FRI', variable: '6PM', value: 13 },
  { group: 'FRI', variable: '7PM', value: 16 },
  { group: 'FRI', variable: '8PM', value: 32 },
  { group: 'FRI', variable: '9PM', value: 44 },
  { group: 'FRI', variable: '10PM', value: 22 },
  { group: 'FRI', variable: '11PM', value: 42 },
  { group: 'SAT', variable: '12AM', value: 12 },
  { group: 'SAT', variable: '1AM', value: 1 },
  { group: 'SAT', variable: '2AM', value: 18 },
  { group: 'SAT', variable: '3AM', value: 68 },
  { group: 'SAT', variable: '4AM', value: 22 },
  { group: 'SAT', variable: '5AM', value: 32 },
  { group: 'SAT', variable: '6AM', value: 32 },
  { group: 'SAT', variable: '7AM', value: 53 },
  { group: 'SAT', variable: '8AM', value: 54 },
  { group: 'SAT', variable: '9AM', value: 13 },
  { group: 'SAT', variable: '10AM', value: 16 },
  { group: 'SAT', variable: '11AM', value: 32 },
  { group: 'SAT', variable: '12PM', value: 12 },
  { group: 'SAT', variable: '1PM', value: 13 },
  { group: 'SAT', variable: '2PM', value: 30 },
  { group: 'SAT', variable: '3PM', value: 8 },
  { group: 'SAT', variable: '4PM', value: 4 },
  { group: 'SAT', variable: '5PM', value: 2 },
  { group: 'SAT', variable: '6PM', value: 13 },
  { group: 'SAT', variable: '7PM', value: 16 },
  { group: 'SAT', variable: '8PM', value: 32 },
  { group: 'SAT', variable: '9PM', value: 16 },
  { group: 'SAT', variable: '10PM', value: 22 },
  { group: 'SAT', variable: '11PM', value: 42 },
  { group: 'SUN', variable: '12AM', value: 12 },
  { group: 'SUN', variable: '1AM', value: 1 },
  { group: 'SUN', variable: '2AM', value: 18 },
  { group: 'SUN', variable: '3AM', value: 68 },
  { group: 'SUN', variable: '4AM', value: 22 },
  { group: 'SUN', variable: '5AM', value: 32 },
  { group: 'SUN', variable: '6AM', value: 32 },
  { group: 'SUN', variable: '7AM', value: 53 },
  { group: 'SUN', variable: '8AM', value: 14 },
  { group: 'SUN', variable: '9AM', value: 13 },
  { group: 'SUN', variable: '10AM', value: 66 },
  { group: 'SUN', variable: '11AM', value: 32 },
  { group: 'SUN', variable: '12PM', value: 55 },
  { group: 'SUN', variable: '1PM', value: 13 },
  { group: 'SUN', variable: '2PM', value: 30 },
  { group: 'SUN', variable: '3PM', value: 8 },
  { group: 'SUN', variable: '4PM', value: 4 },
  { group: 'SUN', variable: '5PM', value: 2 },
  { group: 'SUN', variable: '6PM', value: 13 },
  { group: 'SUN', variable: '7PM', value: 16 },
  { group: 'SUN', variable: '8PM', value: 32 },
  { group: 'SUN', variable: '9PM', value: 16 },
  { group: 'SUN', variable: '10PM', value: 22 },
  { group: 'SUN', variable: '11PM', value: 42 },
]

const createBarChartTooltip = (value) => (
  <BarChartTooltip>
    <h3>
      {'€ '}
      {value.toLocaleString()}
    </h3>
    <p>
      {'= ETH '}
      {(value / 125).toFixed(4)}
    </p>
  </BarChartTooltip>
)

const createLineChartTooltip = (value) => (
  <LineChartTooltip>
    <h3>
      {'€ '}
      {value.toLocaleString()}
    </h3>
    <h4>Revenue</h4>
  </LineChartTooltip>
)

const heatmapRange = {
  colors: ['#EFEFEF', '#CEDDF5', '#9CB9EB', '#6A97E0', '#3873D5', '#0050CA'],
  steps: [1, 13, 27, 40, 54, 68],
}

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const logEventFn = (e) => console.log(e) // eslint-disable-line no-console

const tableColumnsDownload = [
  {
    key: 'document_name',
    label: 'Document',
  },
  {
    key: 'type',
    label: 'Type',
    width: '80px',
    align: 'center',
  },
  {
    key: 'downloads',
    label: 'Downloads',
    align: 'right',
  },
]

const tableDataDownload = [
  {
    id: '01',
    document_name: 'Financial raport',
    type: 'PDF',
    color: '#EE220D',
    downloads: '4182',
  },
  {
    id: '02',
    document_name: 'Sales analysis',
    type: 'XLS',
    color: '#2D7343',
    downloads: '3182',
  },
]

const basicDonutChartData = [
  { date: 1, value: 1 },
  { date: 2, value: 5 },
  { date: 3, value: 2 },
  { date: 4, value: 2 },
  { date: 5, value: 3 },
  { date: 6, value: 9 },
  { date: 7, value: 6 },
  { date: 8, value: 2 },
  { date: 9, value: 3 },
  { date: 10, value: 1 },
  { date: 11, value: 5 },
  { date: 12, value: 4 },
]

class UiPage extends PureComponent {
  state = {
    category: 'Unread',
  }

  changeCategory = (category) => {
    this.setState({
      category,
    })
  }

  render() {
    const { category } = this.state

    return (
      <Layout>
        <StdRow main>
          <StdColumn part={8} mainFixed>
            <ComponentWrapper>
              <Text>
                <h1>UI guide</h1>
              </Text>
            </ComponentWrapper>
          </StdColumn>

          <StdColumn part={8} mainRelative>
            <ComponentWrapper borderTop>
              <h2>Heatmap</h2>
              <ComponentContainer>
                <Heatmap
                  hoursLabels={heatmapHoursLabel}
                  daysLabels={heatmapDaysLabel}
                  data={heatmapData}
                  range={heatmapRange}
                  width={236}
                  height={525}
                  padding={50}
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Download Table</h2>
              <ComponentContainer>
                <Table
                  columns={tableColumnsDownload}
                  data={tableDataDownload.map((row) => {
                    // eslint-disable-line
                    return {
                      ...row,
                      type: (
                        <TableCellType color={row.color}>
                          {row.type}
                        </TableCellType>
                      ),
                    }
                  })}
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Country Table</h2>
              <ComponentContainer>
                <Table
                  columns={tableColumnsCountry}
                  data={tableDataCountry.map((row) => ({
                    ...row,
                    country: <Country flag_url="">{row.country_name}</Country>,
                    bounce_rate: (
                      <RowWrapper>
                        <Row spaceBetween middle>
                          <Column auto>{row.bounce_rate}</Column>
                          <Column auto>
                            <BasicLineChartWrapper>
                              <BasicLineChart
                                data={row.chart.data}
                                padding={0}
                                id={row.chart.id}
                                fill={row.chart.fill}
                                stroke={row.chart.stroke}
                              />
                            </BasicLineChartWrapper>
                          </Column>
                        </Row>
                      </RowWrapper>
                    ),
                  }))}
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>ColorBoxWithTitle</h2>

              <ComponentContainer inline>
                <ColorBoxWithTitle>default</ColorBoxWithTitle>
              </ComponentContainer>
              <ComponentContainer inline>
                <ColorBoxWithTitle background="#79ca65">
                  +9.1%
                </ColorBoxWithTitle>
              </ComponentContainer>
              <ComponentContainer inline>
                <ColorBoxWithTitle background="#ee220d">
                  -7.2%
                </ColorBoxWithTitle>
              </ComponentContainer>
              <ComponentContainer inline>
                <ColorBoxWithTitle color="#eed78d">
                  custom color
                </ColorBoxWithTitle>
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>BasicLineChartInfo</h2>

              <SectionTitle>Up</SectionTitle>
              <ComponentContainer>
                <BasicLineChartInfo
                  title="realtime users"
                  value="848"
                  percent={9.1}
                />
              </ComponentContainer>

              <SectionTitle>Down</SectionTitle>
              <ComponentContainer>
                <BasicLineChartInfo
                  title="realtime users"
                  value="848"
                  percent={-7.2}
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>BasicLineChart width info</h2>

              <SectionTitle>Up</SectionTitle>
              <ComponentContainer>
                <BasicLineChart
                  data={basicDonutChartData}
                  padding={40}
                  id="BasicLineChart01"
                  fill="#E4ECF9"
                  stroke="#0050CA"
                />
              </ComponentContainer>

              <SectionTitle>Down</SectionTitle>
              <ComponentContainer>
                <BasicLineChart
                  data={basicDonutChartData}
                  padding={40}
                  id="BasicLineChart02"
                  fill="#EFF8EC"
                  stroke="#69BD53"
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>BasicLineChart width info</h2>

              <SectionTitle>Up</SectionTitle>
              <ComponentContainer>
                <BasicLineChartInfo
                  title="realtime users"
                  value="848"
                  percent={9.1}
                />
                <BasicLineChart
                  data={basicDonutChartData}
                  padding={40}
                  id="BasicLineChartWithInfo01"
                  fill="#E4ECF9"
                  stroke="#0050CA"
                />
              </ComponentContainer>

              <SectionTitle>Down</SectionTitle>
              <ComponentContainer>
                <BasicLineChartInfo
                  title="realtime users"
                  value="848"
                  percent={-7.2}
                />
                <BasicLineChart
                  data={basicDonutChartData}
                  padding={40}
                  id="BasicLineChartWithInfo02"
                  fill="#EFF8EC"
                  stroke="#69BD53"
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Card</h2>

              <SectionTitle>Card Title</SectionTitle>
              <ComponentContainer>
                <CardTitle>CardTitle Component</CardTitle>
              </ComponentContainer>

              <SectionTitle>Card Title with left text</SectionTitle>
              <ComponentContainer>
                <CardTitleContainer>
                  <CardTitle>CardTitle Component</CardTitle>
                  <CardLeftText>ETH 0.01</CardLeftText>
                </CardTitleContainer>
              </ComponentContainer>

              <SectionTitle>Card Title with right text</SectionTitle>
              <ComponentContainer>
                <CardTitleContainer>
                  <CardTitle>CardTitle Component</CardTitle>
                  <CardRightText>ETH 0.01</CardRightText>
                </CardTitleContainer>
              </ComponentContainer>

              <SectionTitle>Single</SectionTitle>
              <ComponentContainer>
                <Card>
                  <Text>
                    <h3>Single card</h3>
                  </Text>
                </Card>
              </ComponentContainer>

              <SectionTitle>Side</SectionTitle>
              <ComponentContainer>
                <Card lessPadding>
                  <Text>
                    <h3>Side card</h3>
                  </Text>
                </Card>
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>BarChart</h2>

              <SectionTitle>Red</SectionTitle>
              <ComponentContainer>
                <BarChart
                  id="redBarChart"
                  colorTheme="red"
                  data={[
                    { name: '21.02', value: 25.27 },
                    { name: '22.02', value: 23.62 },
                    { name: '23.02', value: 28.14 },
                    { name: '24.02', value: 34.02 },
                    { name: '25.02', value: 34.36 },
                    { name: '26.02', value: 35.63 },
                    { name: '27.02', value: 39.11 },
                  ].map((datum) => ({
                    ...datum,
                    tooltip: createBarChartTooltip(datum.value),
                  }))}
                />
              </ComponentContainer>

              <SectionTitle>Blue</SectionTitle>
              <ComponentContainer>
                <BarChart
                  id="blueBarChart"
                  theme="blue"
                  data={[
                    { name: '21.02', value: 25.27 },
                    { name: '22.02', value: 23.62 },
                    { name: '23.02', value: 28.14 },
                    { name: '24.02', value: 34.02 },
                    { name: '25.02', value: 34.36 },
                    { name: '26.02', value: 35.63 },
                    { name: '27.02', value: 39.11 },
                  ].map((datum) => ({
                    ...datum,
                    tooltip: createBarChartTooltip(datum.value),
                  }))}
                />
              </ComponentContainer>

              <SectionTitle>Blue without tooltip</SectionTitle>
              <ComponentContainer>
                <BarChart
                  id="blueBarChart"
                  colorTheme="blue"
                  data={[
                    { name: '21.02', value: 25.27 },
                    { name: '22.02', value: 23.62 },
                    { name: '23.02', value: 28.14 },
                    { name: '24.02', value: 34.02 },
                    { name: '25.02', value: 34.36 },
                    { name: '26.02', value: 35.63 },
                    { name: '27.02', value: 39.11 },
                  ]}
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>BarChart legend</h2>

              <SectionTitle>Red</SectionTitle>
              <ComponentContainer>
                <BarChartLegend
                  id="redBarChartLegend"
                  colorTheme="red"
                  label="red label"
                />
              </ComponentContainer>

              <SectionTitle>Blue</SectionTitle>
              <ComponentContainer>
                <BarChartLegend
                  id="blueBarChartLegend"
                  colorTheme="blue"
                  label="blue label"
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Team Member Item</h2>

              <SectionTitle>Default</SectionTitle>
              <ComponentContainer>
                <TeamMemberItem
                  id="team-member-01"
                  checkboxName="team-member-01"
                  name="Kelly Ross"
                  memberRole="Marketing Technologist"
                  memberEmail="kelly ross91@gmail.com"
                  avatar={memberItemPicture}
                />
              </ComponentContainer>

              <SectionTitle>Active</SectionTitle>
              <ComponentContainer>
                <TeamMemberItem
                  id="team-member-02"
                  checkboxName="team-member-02"
                  name="Kelly Ross"
                  memberRole="Marketing Technologist"
                  memberEmail="kelly ross91@gmail.com"
                  avatar={memberItemPicture}
                  active
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Team Member Item (add new)</h2>

              <SectionTitle>Placeholder</SectionTitle>
              <ComponentContainer>
                <TeamMemberItemAdd
                  id="add-new-member-01"
                  name="add-new-member-01"
                  title="Add New Member"
                  placeholder="email address"
                  buttonText="Invite"
                />
              </ComponentContainer>

              <SectionTitle>Init Value</SectionTitle>
              <ComponentContainer>
                <TeamMemberItemAdd
                  id="add-new-member-02"
                  name="add-new-member-02"
                  title="Add New Member"
                  value="brandon.ford@gm"
                  placeholder="email address"
                  buttonText="Invite"
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Overview item</h2>
              <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                <ComponentContainer inline>
                  <OverviewItem title="Simple item" content="9 000" />
                </ComponentContainer>
              </ComponentWrapper>
              <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                <ComponentContainer inline>
                  <OverviewItem
                    title="Item with bottom slot"
                    content="9 000"
                    bottom={<a href="http://example.com">coinmarketcap.com</a>}
                  />
                </ComponentContainer>
              </ComponentWrapper>
              <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                <ComponentContainer inline>
                  <OverviewItem
                    title="Item with right slot"
                    content="9 000"
                    right={<CircleProgress id="cp0" progress={80} />}
                  />
                </ComponentContainer>
              </ComponentWrapper>
              <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                <ComponentContainer inline>
                  <OverviewItem
                    title="Item with bottom and right slot"
                    right={<RightSlotText>€ 421,250.00</RightSlotText>}
                    bottom={<SingleProgressBar progress={70} />}
                  />
                </ComponentContainer>
              </ComponentWrapper>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Notification Item</h2>

              <SectionTitle>Type user</SectionTitle>
              <ComponentContainer>
                <NotificationItem
                  id="1"
                  type="user"
                  title="Danny Miles commented: "
                  content="Oh no I Missed it! Will there be any other inv…"
                  avatar={avatar}
                />
              </ComponentContainer>

              <SectionTitle>Type system</SectionTitle>
              <ComponentContainer>
                <NotificationItem
                  id="2"
                  type="system"
                  title="Your update has been approved and is now live"
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Table</h2>
              <ComponentContainer>
                <Table
                  columns={tableColumns}
                  data={tableData.map((row) => {
                    const trendInc = row.token_sold.substr(0, 1) === '+'
                    const trendDec = row.token_sold.substr(0, 1) === '-'

                    return {
                      ...row,
                      timestamp: <DateTime timestamp={row.timestamp} />,
                      token_sold: (
                        <Trend inc={trendInc} dec={trendDec}>
                          {row.token_sold}
                        </Trend>
                      ),
                      country: (
                        <Country flag_url="">{row.country_name}</Country>
                      ),
                    }
                  })}
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>DateTime</h2>
              <ComponentContainer inline>
                <DateTime timestamp={new Date('11/14/2019 14:45')} />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Country</h2>
              <ComponentContainer inline>
                <Country flag_url="">United Kingdom</Country>
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Trend</h2>

              <SectionTitle>Increase</SectionTitle>
              <ComponentContainer inline>
                <Trend inc>+€500</Trend>
              </ComponentContainer>

              <SectionTitle>Decrease</SectionTitle>
              <ComponentContainer inline>
                <Trend dec>-€500</Trend>
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>CountriesMap</h2>

              <SectionTitle>Default</SectionTitle>
              <ComponentContainer>
                <CountriesMap
                  categories={{
                    default: {
                      fill: '#f0f0f0',
                      stroke: '#dbdbdb',
                      label: '0',
                      percent: '19,5%',
                    },
                  }}
                  handleClick={logEventFn}
                />
              </ComponentContainer>

              <SectionTitle>Zoom (1.5)</SectionTitle>
              <ComponentContainer>
                <CountriesMap
                  zoom={1.5}
                  categories={{
                    default: {
                      fill: '#f0f0f0',
                      stroke: '#dbdbdb',
                      label: '0',
                      percent: '19,5%',
                    },
                  }}
                  handleClick={logEventFn}
                />
              </ComponentContainer>

              <SectionTitle>With categories mapping and tooltip</SectionTitle>
              <ComponentContainer>
                <CountriesMap
                  categories={countriesMapCategories}
                  tooltip
                  countryMapping={(country) => {
                    const countryObj = {
                      ...country,
                      properties: {
                        ...country.properties,
                        category: 'default',
                        investors: Math.floor(Math.random() * 9) + 1,
                        value: Math.random() * 1e6 + 1e3,
                        ...mapCountryData[country.id],
                      },
                    }
                    countryObj.properties.tooltip =
                      createCountriesMapTooltip(countryObj)
                    return countryObj
                  }}
                  handleClick={logEventFn}
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>CountriesMapLegend</h2>

              <ComponentContainer>
                <CountriesMapLegend categories={countriesMapCategories} />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Single progress bar</h2>
              <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                <SectionTitle>Primary</SectionTitle>
                <ComponentContainer>
                  <SingleProgressBar colorTheme="blue" progress={70} />
                </ComponentContainer>
              </ComponentWrapper>
              <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                <SectionTitle>Red</SectionTitle>
                <ComponentContainer>
                  <SingleProgressBar
                    colorTheme="red"
                    progress={60}
                    label="1111111"
                  />
                </ComponentContainer>
              </ComponentWrapper>
              <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                <SectionTitle>Green</SectionTitle>
                <ComponentContainer>
                  <SingleProgressBar
                    colorTheme="green"
                    progress={80}
                    label="1111111"
                    labelSuccess
                  />
                </ComponentContainer>
              </ComponentWrapper>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Status Item</h2>

              <SectionTitle>Default</SectionTitle>
              <ComponentContainer>
                <StatusItem message="Status item" />
              </ComponentContainer>

              <SectionTitle>With tooltip</SectionTitle>
              <ComponentContainer>
                <StatusItem message="Status item" tooltip="Example tooltip" />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Cap item</h2>
              <ComponentContainer>
                <CapItem
                  title="Reached soft cap"
                  days={20}
                  text="Days"
                  overviewItemProps={{
                    title: 'Soft cap',
                    right: <RightSlotText>€ 421,250.00</RightSlotText>,
                    bottom: (
                      <SingleProgressBar colorTheme="green" progress={100} />
                    ),
                    rightAlign: true,
                  }}
                />
              </ComponentContainer>
              <SectionTitle>Without days</SectionTitle>
              <ComponentContainer>
                <CapItem
                  title="Reached hard cap"
                  text="in progress"
                  overviewItemProps={{
                    title: 'Hard cap',
                    right: <RightSlotText>€ 421,250.00</RightSlotText>,
                    bottom: (
                      <SingleProgressBar colorTheme="red" progress={70} />
                    ),
                    rightAlign: true,
                  }}
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Item Options</h2>

              <SectionTitle>options</SectionTitle>
              <ComponentContainer inline>
                <ItemOptions
                  options={[
                    { name: 'remove', icon: 'trash', onClick: logEventFn },
                    { name: 'edit', icon: 'pen', onClick: logEventFn },
                  ]}
                />
              </ComponentContainer>

              <SectionTitle>share</SectionTitle>
              <ComponentContainer inline>
                <ItemOptions
                  icon="share"
                  options={[
                    { name: 'linkedin', icon: 'linkedin', href: '#' },
                    { name: 'twitter', icon: 'twitter', href: '#' },
                    { name: 'telegram', icon: 'telegram', href: '#' },
                  ]}
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Update item</h2>

              <SectionTitle>Default</SectionTitle>
              <ComponentContainer>
                <UpdateItem
                  date="17/06/2019"
                  title="Leading the digital"
                  text="There is such a lot of talk going around about branding, but what exactly is your brand and how do you use it to help you products or services?"
                />
              </ComponentContainer>

              <SectionTitle>Soft</SectionTitle>
              <ComponentContainer>
                <UpdateItem
                  date="17/06/2019"
                  title="STO Started"
                  text="Token sales open to everyone"
                  soft="1"
                />
              </ComponentContainer>

              <SectionTitle>Hard</SectionTitle>
              <ComponentContainer>
                <UpdateItem
                  date="17/06/2019"
                  title="Whoop la! we rocked it to the the max"
                  text="All our tokens sold in 23 days!"
                  hard="Hard cap reached"
                />
              </ComponentContainer>

              <SectionTitle>With options</SectionTitle>
              <ComponentContainer>
                <UpdateItem
                  date="17/06/2019"
                  title="Leading the digital"
                  text="There is such a lot of talk going around about branding, but what exactly is your brand and how do you use it to help you products or services?"
                  options={
                    <ItemOptions
                      options={[
                        { name: 'remove', icon: 'trash', onClick: logEventFn },
                        { name: 'edit', icon: 'pen', onClick: logEventFn },
                      ]}
                    />
                  }
                />
              </ComponentContainer>

              <SectionTitle>With share</SectionTitle>
              <ComponentContainer>
                <UpdateItem
                  date="17/06/2019"
                  title="Leading the digital"
                  text="There is such a lot of talk going around about branding, but what exactly is your brand and how do you use it to help you products or services?"
                  options={
                    <ItemOptions
                      icon="share"
                      options={[
                        { name: 'linkedin', icon: 'linkedin', href: '#' },
                        { name: 'twitter', icon: 'twitter', href: '#' },
                        { name: 'telegram', icon: 'telegram', href: '#' },
                      ]}
                    />
                  }
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Comment Item</h2>

              <SectionTitle>Default</SectionTitle>
              <ComponentContainer>
                <CommentItem
                  avatar={avatar}
                  name="Danny Miles"
                  time="5 minutes ago"
                  message="Not so many years ago businesses used to grunt at using illustrations in their marketing materials. But today, the use and influence of illustrations are"
                  likes={0}
                  options={
                    <ItemOptions
                      options={[
                        { name: 'remove', icon: 'trash', onClick: logEventFn },
                        { name: 'lock', icon: 'lock', onClick: logEventFn },
                      ]}
                    />
                  }
                  handleLikeClick={() => {}}
                  handleReplyClick={() => {}}
                />
              </ComponentContainer>

              <SectionTitle>Super Admin</SectionTitle>
              <ComponentContainer>
                <CommentItem
                  avatar={avatar}
                  name="Danny Miles"
                  time="5 minutes ago"
                  message="Not so many years ago businesses used to grunt at using illustrations in their marketing materials. But today, the use and influence of illustrations are"
                  likes={10}
                  superAdmin
                  options={
                    <ItemOptions
                      options={[
                        { name: 'remove', icon: 'trash', onClick: logEventFn },
                        { name: 'lock', icon: 'lock', onClick: logEventFn },
                      ]}
                    />
                  }
                />
              </ComponentContainer>

              <SectionTitle>User like Admin</SectionTitle>
              <ComponentContainer>
                <CommentItem
                  avatar={avatar}
                  name="Danny Miles"
                  time="5 minutes ago"
                  message="Not so many years ago businesses used to grunt at using illustrations in their marketing materials. But today, the use and influence of illustrations are"
                  likes={8}
                  userLike
                  options={
                    <ItemOptions
                      options={[
                        { name: 'remove', icon: 'trash', onClick: logEventFn },
                        { name: 'lock', icon: 'lock', onClick: logEventFn },
                      ]}
                    />
                  }
                />
              </ComponentContainer>

              <SectionTitle>With replies</SectionTitle>
              <ComponentContainer>
                <CommentItem
                  avatar={avatar}
                  name="Anne Woodworth"
                  time="15 minutes ago"
                  message="Not so many years ago businesses used to grunt at using illustrations in their marketing materials. But today, the use and influence of illustrations are growing right along. An illustration, image possible. Originality cannot help flowing from the pencil of the good artist."
                  likes={66}
                  options={
                    <ItemOptions
                      options={[
                        { name: 'remove', icon: 'trash', onClick: logEventFn },
                        { name: 'lock', icon: 'lock', onClick: logEventFn },
                      ]}
                    />
                  }
                  replies={
                    <Fragment>
                      <CommentItem
                        avatar={avatar}
                        name="Danny Miles"
                        time="5 minutes ago"
                        message="Not so many years ago businesses used to grunt at using illustrations in their marketing materials. But today, the use and influence of illustrations are"
                        likes={2}
                        userLike
                        allowReply={false}
                        options={
                          <ItemOptions
                            options={[
                              {
                                name: 'remove',
                                icon: 'trash',
                                onClick: logEventFn,
                              },
                              {
                                name: 'lock',
                                icon: 'lock',
                                onClick: logEventFn,
                              },
                            ]}
                          />
                        }
                      />
                      <CommentItem
                        avatar={avatar}
                        name="Anne Woodworth"
                        time="10 minutes ago"
                        message="Not so many years ago businesses used to grunt at using illustrations in their marketing materials. But today, the use and influence of illustrations are"
                        likes={0}
                        allowReply={false}
                        options={
                          <ItemOptions
                            options={[
                              {
                                name: 'remove',
                                icon: 'trash',
                                onClick: logEventFn,
                              },
                              {
                                name: 'lock',
                                icon: 'lock',
                                onClick: logEventFn,
                              },
                            ]}
                          />
                        }
                      />
                    </Fragment>
                  }
                />
              </ComponentContainer>

              <SectionTitle>Multiple items</SectionTitle>
              <ComponentContainer>
                <CommentItem
                  avatar={avatar}
                  name="Danny Miles"
                  time="5 minutes ago"
                  message="Not so many years ago businesses used to grunt at using illustrations in their marketing materials. But today, the use and influence of illustrations are"
                  likes={3}
                  options={
                    <ItemOptions
                      options={[
                        { name: 'remove', icon: 'trash', onClick: logEventFn },
                        { name: 'lock', icon: 'lock', onClick: logEventFn },
                      ]}
                    />
                  }
                />
                <CommentItem
                  avatar={avatar}
                  name="Anne Woodworth"
                  time="15 minutes ago"
                  message="Not so many years ago businesses used to grunt at using illustrations in their marketing materials. But today, the use and influence of illustrations are growing right along. An illustration, image possible. Originality cannot help flowing from the pencil of the good artist."
                  likes={11}
                  userLike
                  options={
                    <ItemOptions
                      options={[
                        { name: 'remove', icon: 'trash', onClick: logEventFn },
                        { name: 'lock', icon: 'lock', onClick: logEventFn },
                      ]}
                    />
                  }
                />
                <CommentItem
                  avatar={avatar}
                  name="Danny Miles"
                  time="50 minutes ago"
                  message="Not so many years ago businesses used to grunt at using illustrations in their marketing materials. But today, the use and influence of illustrations are"
                  likes={20}
                  options={
                    <ItemOptions
                      options={[
                        { name: 'remove', icon: 'trash', onClick: logEventFn },
                        { name: 'lock', icon: 'lock', onClick: logEventFn },
                      ]}
                    />
                  }
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper>
              <h2>Buttons tabs</h2>

              <ComponentContainer inline>
                <ButtonTabs activeTab={1}>
                  <ButtonTab tabId={0} onClick={() => {}}>
                    Tab title 1
                  </ButtonTab>
                  <ButtonTab tabId={1} onClick={() => {}}>
                    Tab title 2
                  </ButtonTab>
                  <ButtonTab tabId={2} onClick={() => {}}>
                    Tab title 3
                  </ButtonTab>
                  <ButtonTab tabId={3} onClick={() => {}}>
                    Tab title 4
                  </ButtonTab>
                </ButtonTabs>
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper>
              <h2>Contributor item</h2>

              <SectionTitle>Single</SectionTitle>
              <ComponentContainer>
                <ContributorItem
                  avatar={avatar}
                  name="Danny Miles"
                  comments={11}
                  to="/"
                />
              </ComponentContainer>

              <SectionTitle>Multiple</SectionTitle>
              <ComponentContainer>
                <ContributorItem
                  avatar={avatar}
                  name="Danny Miles"
                  comments={7}
                  to="/"
                />
                <ContributorItem
                  avatar={avatar}
                  name="Elly Woodworth"
                  comments={3}
                  to="/"
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper>
              <h2>Category button</h2>

              <SectionTitle>Single</SectionTitle>
              <ComponentContainer>
                <CategoryButton
                  icon="unread"
                  name="Unread"
                  color="#0050ca"
                  counter={17}
                />
              </ComponentContainer>

              <SectionTitle>Multiple</SectionTitle>
              <ComponentContainer>
                <CategoryButton
                  icon="unread"
                  name="Unread"
                  color="#0050ca"
                  counter={17}
                  handleClick={() => {
                    this.changeCategory('Unread')
                  }}
                  isActive={category === 'Unread'}
                />
                <CategoryButton
                  icon="heart"
                  name="Likes"
                  color="#ee220d"
                  counter={11}
                  handleClick={() => {
                    this.changeCategory('Likes')
                  }}
                  isActive={category === 'Likes'}
                />
                <CategoryButton
                  icon="star"
                  name="Total comments"
                  color="#2f2f2f"
                  counter={12}
                  handleClick={() => {
                    this.changeCategory('Total comments')
                  }}
                  isActive={category === 'Total comments'}
                />
                <CategoryButton
                  icon="user"
                  name="Total user’s engagement"
                  color="#69bd53"
                  counter={7}
                  handleClick={() => {
                    this.changeCategory('Total user’s engagement')
                  }}
                  isActive={category === 'Total user’s engagement'}
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>BasicDonutChart</h2>

              <SectionTitle>Standard</SectionTitle>
              <ComponentContainer inline>
                <BasicDonutChart
                  data={[
                    { name: 'Tokens sold', value: 5052, color: '#ee220d' },
                    { name: 'Tokens left', value: 2456, color: '#0050ca' },
                  ]}
                />
              </ComponentContainer>

              <SectionTitle>With content</SectionTitle>
              <ComponentContainer inline>
                <BasicDonutChart
                  data={[
                    { name: 'Tokens sold', value: 5052, color: '#ee220d' },
                    { name: 'Tokens left', value: 2456, color: '#0050ca' },
                  ]}
                >
                  <BasicDonutChartLabel>Distribution</BasicDonutChartLabel>
                  <BasicDonutChartValue>€ 2100</BasicDonutChartValue>
                </BasicDonutChart>
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>BasicDonutChartLegend</h2>

              <SectionTitle>Single</SectionTitle>
              <ComponentContainer>
                <BasicDonutChartLegend
                  color="#ee220d"
                  label="Tokens Sold"
                  percent="(68%)"
                  value="5052"
                />
              </ComponentContainer>

              <SectionTitle>Multiple</SectionTitle>
              <ComponentContainer>
                <BasicDonutChartLegend
                  color="#ee220d"
                  label="Tokens Sold"
                  percent="(68%)"
                  value="5052"
                />
                <BasicDonutChartLegend
                  color="#0050ca"
                  label="Tokens Left"
                  percent="(32%)"
                  value="2456"
                />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>InvestmentRatio</h2>

              <SectionTitle>Single</SectionTitle>
              <ComponentContainer>
                <InvestmentRatio
                  currency="ETH"
                  percent={87.1}
                  colorTheme="blue"
                />
              </ComponentContainer>

              <SectionTitle>Multiple</SectionTitle>
              <ComponentContainer>
                <InvestmentRatio
                  currency="ETH"
                  percent={87.1}
                  colorTheme="blue"
                />
                <InvestmentRatio currency="€" percent={12.9} colorTheme="red" />
              </ComponentContainer>
            </ComponentWrapper>

            <ComponentWrapper borderTop>
              <h2>Text Editor</h2>

              <SectionTitle>Basic</SectionTitle>
              <ComponentContainer>
                <TextEditor
                  id="text-editor-basic-1"
                  name="text-editor-basic-1"
                  placeholder="Write comment or question…"
                />
              </ComponentContainer>

              <SectionTitle>Full</SectionTitle>
              <ComponentContainer>
                <TextEditor
                  id="text-editor-1"
                  name="text-editor-1"
                  placeholder="Write comment or question…"
                  info="Write the comment"
                  limit={280}
                  done={{
                    label: 'Post',
                    onClick: () => {},
                  }}
                  cancel={{
                    label: 'Cancel',
                    onClick: () => {},
                  }}
                />
              </ComponentContainer>

              <SectionTitle>Full with scroll</SectionTitle>
              <ComponentContainer>
                <TextEditor
                  id="text-editor-1"
                  name="text-editor-1"
                  placeholder="Write comment or question…"
                  info="Write the comment"
                  limit={280}
                  minHeight={66}
                  maxHeight={66}
                  done={{
                    label: 'Post',
                    onClick: () => {},
                  }}
                  cancel={{
                    label: 'Cancel',
                    onClick: () => {},
                  }}
                />
              </ComponentContainer>

              <SectionTitle>Full with error</SectionTitle>
              <ComponentContainer>
                <TextEditor
                  id="text-editor-2"
                  name="text-editor-2"
                  placeholder="Write comment or question…"
                  info="Write the comment"
                  limit={280}
                  error="Message is required"
                  done={{
                    label: 'Post',
                    onClick: () => {},
                  }}
                  cancel={{
                    label: 'Cancel',
                    onClick: () => {},
                  }}
                />
              </ComponentContainer>
            </ComponentWrapper>
          </StdColumn>

          <StdColumn>
            <ComponentWrapper>
              <h2>Line Chart with tooltipt</h2>
              <ComponentContainer>
                {/* <LineChart
                  data={[
                    { date: 0, value: 15400 },
                    { date: 1, value: 33000 },
                    { date: 2, value: 24300 },
                    { date: 3, value: 21300 },
                    { date: 4, value: 32400 },
                    { date: 5, value: 11500 },
                    { date: 6, value: 39800 },
                    { date: 7, value: 33400 },
                    { date: 8, value: 21600 },
                    { date: 9, value: 19700 },
                    { date: 10, value: 31500 },
                    { date: 11, value: 12300 },
                  ].map((datum) => ({
                    ...datum,
                    tooltip: createLineChartTooltip(datum.value),
                  }))}
                  months={months}
                  id="LineChart2"
                  fill="#ceddf5"
                  stroke="#0050CA"
                /> */}
              </ComponentContainer>
            </ComponentWrapper>
            <ComponentWrapper borderTop noPadding>
              <Card>
                <TeamOverview
                  name="Sarah Neumann"
                  avatar={profilePicture}
                  memberRole="superadmin"
                  superAdmin
                  position="Chief Executive Officer"
                  email="sarah.neumann@gmail.com"
                  projectName="St Dronard"
                  stats={[
                    { name: 'Members', count: 6 },
                    { name: 'Pending', count: 3 },
                  ]}
                />
              </Card>
            </ComponentWrapper>
          </StdColumn>
        </StdRow>
      </Layout>
    )
  }
}

export default UiPage
