import styled from 'styled-components/macro'

const StyledList = styled.ul`
  display: flex;
  list-style-type: none;
`

const ListItem = styled.li`
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: ${(props) => props.theme.cBlack};
  display: flex;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 16px;
  line-height: 1.43;
  font-size: 15px;
  font-weight: 500;
  min-width: 32px;

  ${(props) =>
    props.arrow &&
    `
    padding:0 9px;
    `}

  &:hover {
    background-color: ${(props) => props.theme.cPrimary};
    color: ${(props) => props.theme.cWhite};
    cursor: pointer;
  }

  ${(props) =>
    props.selected &&
    `
    color: ${props.theme.cWhite};
    background-color: ${props.theme.cPrimary};
  `}

  ${(props) =>
    props.disabled &&
    `
    pointer-events:none;
    opacity: 0.6;
  `}

  ${(props) =>
    props.dots &&
    `
     &:hover{
         background-color: transparent;
      cursor: default;
      color:${props.theme.cBlack}
     }
  `}
`
StyledList.Item = ListItem

export { StyledList }
