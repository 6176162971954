import React from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  Items,
  Item,
  Bar,
  Label,
  // Percent,
} from './CountriesMapLegend.styles'

const CountriesMapLegend = ({ categories }) => (
  <Container>
    <Items>
      {Object.keys(categories).map(categoryKey => {
        const category = categories[categoryKey]

        return (
          <Item key={categoryKey}>
            <Bar barColor={category.fill} />
            <Label>
              {category.label}
              {/* {category.percent && <Percent>{category.percent}</Percent>} */}
            </Label>
          </Item>
        )
      })}
    </Items>
  </Container>
)

CountriesMapLegend.propTypes = {
  categories: PropTypes.instanceOf(Object).isRequired,
}

export default CountriesMapLegend
