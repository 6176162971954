import styled, { css } from 'styled-components/macro'

export const Container = styled.div`
  display: block;
  position: relative;
  border: 1px solid ${(props) => props.theme.cLightGrey};
  border-radius: 10px;
  overflow: hidden;

  ${(props) =>
    props.noRadiusTop &&
    `
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  `}
`

export const Body = styled.div`
  position: relative;
  padding: 24px 32px;
  padding-right: 46px;
  padding-bottom: 46px;
  display: block;

  ${(props) =>
    props.smallPadding &&
    `
  padding:18px 24px;
  `}
`

export const Foot = styled.div`
  padding: 24px 32px;
  border-top: 1px solid ${(props) => props.theme.cLightGrey};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const InfoIconWrapper = styled.div`
  display: block;
  position: absolute;
  top: 24px;
  right: 20px; /* 32 - 12 */
`

export const Infos = styled.div`
  display: block;
`

export const Counter = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-weight: 300;
  font-size: 9px;
  letter-spacing: 1.65px;
  text-transform: uppercase;
  color: ${(props) => props.theme.cLightGrey};
  margin-right: 12px;
`

export const ErrorMsg = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-size: 9px;
  letter-spacing: 1.65px;
  color: ${(props) => props.theme.cWarning};
`

export const Actions = styled.div`
  display: block;
  text-align: right;
`

export const TextWrapper = styled.div`
  position: relative;
  display: block;
`

const textStyles = css`
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.53px;
`

export const TextArea = styled.textarea`
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  ${textStyles};
  resize: none;
  color: ${(props) => props.theme.cBlack};

  &::placeholder {
    color: ${(props) => props.theme.cGrey2};
    opacity: 1;
  }
`

export const TextPusher = styled.div`
  z-index: -1;
  position: relative;
  ${textStyles};
  min-height: 66px;
  visibility: hidden;
`

export const EmojiPickerWrapper = styled.div`
  display: block;
  position: absolute;
  bottom: 20px;
  left: 32px;
`

export const Extension = styled.div`
  display: inline-block;
  vertical-align: middle;
`

export const TextEditorHeader = styled.div`
  border: 1px solid ${(props) => props.theme.cLightGrey};
  border-bottom: 0;
  border-radius: 10px 10px 0 0;
  padding: 24px 32px;
`

export const ErrorMessageBox = styled.div`
  height: 15px;
  position: relative;
  top: 15px;
`

export const ErrorMessage = styled.p`
  ${(props) => css`
    color: ${props.theme.cWarning};
  `}
  font-size: 12px;
  text-align: right;
`
