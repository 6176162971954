import styled from 'styled-components/macro'
import rwd from 'styles/rwd'

export const StyledTabs = styled.div`
  position: relative;
  padding: 8px 0;

  ${rwd.Medium`
    padding: 8px 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  `}
`

export const TabsWrapper = styled.div`
  position: relative;
  margin-right: -16px;
  padding-bottom: 4px;

  ${rwd.Medium`
    margin-right: -32px;
  `}
`

export const ActionsWrapper = styled.div`
  position: relative;
`

export const LinkText = styled.span`
  display: block;
  position: relative;
  font-family: 'Open Sans';
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 4px 0;

  &:before {
    content: '';
    width: 100%;
    height: 4px;
    background-color: transparent;
    position: absolute;
    top: 100%;
    left: 0;
    transition: background-color 0.2s;
  }

  ${props =>
    props.isActive &&
    `
    &:before {
      background-color: ${props.theme.cPrimary}!important;
    }
  `}
`

export const StyledTab = styled.button`
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  margin-right: 16px;
  color: ${props => props.theme.cBlack};
  transition: color 0.2s;

  ${rwd.Medium`
    margin-right: 32px;
  `}

  &:focus ,
  &:hover {
    color: ${props => props.theme.cPrimary};

    ${LinkText} {
      &:before {
        background-color: ${props => props.theme.cGrey};
      }
    }
  }
`
