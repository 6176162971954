import avatarPlaceholder from 'static/images/avatar-placeholder.png'
import { cache } from './cacheHandler'
import axios from 'axios'

const photoBaseUrl = process.env.REACT_APP_PHOTO_API_URL

const getProfilePicture = async (userId) => {
  if (!userId) {
    return avatarPlaceholder
  }

  const url = `${photoBaseUrl}/media/picture/view/${userId}?type=avatar`

  try {
    await axios.head(url, { headers: { 'Content-Type': 'application/json' } })
  } catch (error) {
    return avatarPlaceholder
  }

  return url
}

export default getProfilePicture
