import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Checkbox from 'components/Checkbox/Checkbox'
import getProfilePicture from 'utils/getProfilePicture'

import {
  Container,
  CheckboxWrapper,
  ImageWrapper,
  Avatar,
  MemberRole,
  Name,
  MemberEmail,
  MemberTrash,
  TrashIcon,
} from './TeamMemberItem.styles'
import avatarPlaceholder from 'static/images/avatar-placeholder.png'

const TeamMemberItem = ({
  id,
  checkboxName,
  name,
  memberRole,
  memberEmail,
  isSelected,
  handleIsSelectedChange,
  handleRemoveClick,
  currentUserType,
}) => {
  const [profilePicture, setProfilePicture] = useState(avatarPlaceholder)

  useEffect(() => {
    const getPicture = async () => {
      const picture = await getProfilePicture(id)

      setProfilePicture(picture)
    }

    getPicture()
  }, [])

  return (
    <Container active={isSelected}>
      {/* {memberRole !== 'company_sa' && (
        <CheckboxWrapper>
          <Checkbox
            id={id}
            name={checkboxName}
            text=""
            large
            value="isSelected"
            checked={isSelected}
            onChange={handleIsSelectedChange}
          />
        </CheckboxWrapper>
      )} */}
      <ImageWrapper>
        <Avatar src={profilePicture} alt="avatar" />
      </ImageWrapper>
      <Name>{name}</Name>
      <MemberRole>{memberRole}</MemberRole>
      <MemberEmail>{memberEmail}</MemberEmail>
      {currentUserType === 'company_sa' && (
        <MemberTrash>
          <TrashIcon onClick={handleRemoveClick} />
        </MemberTrash>
      )}
    </Container>
  )
}

TeamMemberItem.propTypes = {
  avatar: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  checkboxName: PropTypes.string.isRequired,
  memberEmail: PropTypes.string.isRequired,
  memberRole: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  handleIsSelectedChange: PropTypes.func,
  handleRemoveClick: PropTypes.func,
}

TeamMemberItem.defaultProps = {
  isSelected: false,
  handleIsSelectedChange: () => {},
  handleRemoveClick: () => {},
}

export default TeamMemberItem
