import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  height: 100%;

  .slick-list {
    z-index: 0;
  }

  .slick-arrow {
    z-index: 1;
    width: 24px;
    height: 24px;

    &.slick-prev,
    &.slick-next {
      &:before {
        font-family: 'Ionicons';
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        text-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
        opacity: 1;
        color: #fff;
        transition: color 0.2s;
      }

      &:hover {
        &:before {
          color: #0050ca;
        }
      }
    }

    &.slick-prev {
      left: 4px;

      &:before {
        content: '\f3cf';
      }
    }

    &.slick-next {
      right: 4px;

      &:before {
        content: '\f3d1';
      }
    }
  }

  .slick-dots {
    z-index: 1;
    height: auto;
    bottom: 4px;

    li {
      display: inline-block;
      width: 24px;
      height: 24px;
      margin: 0;

      button {
        display: block;
        padding: 8px;
        width: 24px;
        height: 24px;

        &:before {
          position: relative;
          content: '';
          display: block;
          width: 8px;
          height: 8px;
          border-radius: 4px;
          background-color: #fff;
          opacity: 1;
          box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
          transition: background-color 0.2s;
        }

        &:hover {
          &:before {
            background-color: #0050ca;
          }
        }
      }

      &.slick-active {
        button {
          &:before {
            background-color: #ee220d;
          }
        }
      }
    }
  }
`

export const ImageSlide = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`

export const ImageSlideWrapper = styled.div`
  height: 193px;
  position: relative;
`
