import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 50px 20px;
  background-color: ${props => props.theme.cWhite};
  border: 1px solid transparent;
  transition: 0.2s border-color;

  ${props =>
    props.active &&
    `
    border: 1px solid ${props.theme.cPrimary};
  `}
`

export const ImageWrapper = styled.div`
  overflow: hidden;
  display: block;
  flex-grow: 0;
  height: 120px;
  width: 120px;
  border-radius: 60px;
  margin-bottom: 25px;
  box-shadow: none;
`

export const Avatar = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`

export const CheckboxWrapper = styled.div`
  position: absolute;
  top: 15px;
  right: 15px;
`

export const MemberRole = styled.div`
  margin-top: 10px;
  color: ${props => props.theme.cPrimary};
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.45px;
  line-height: 24px;
  text-transform: uppercase;
  text-align: center;
`

export const MemberEmail = styled.div`
  margin-top: 6px;
  color: #202020;
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
  word-break: break-all;
`

export const MemberTrash = styled.div`
  margin-top: 45px;
  text-align: center;
  font-size: 0;
`

export const Name = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  letter-spacing: 0.6px;
  text-align: center;
`

export const TrashIcon = styled.button.attrs({
  className: 'ion ion-ios-trash',
})`
  display: inline-block;
  width: 34px;
  height: 34px;
  color: ${props => props.theme.cLightGrey};
  font-size: 34px;
  cursor: pointer;
  transition: 0.2s color;

  &:hover {
    color: ${props => props.theme.cWarning};
  }

  &:focus {
    outline: none;
  }
`
