import styled from 'styled-components/macro'

export const WrapButton = styled.button.attrs({
  type: 'button',
})`
  display: inline-block;
  margin: 0;
  padding: 0;
  outline: 0;
  text-align: left;
  cursor: pointer;
`

export default WrapButton
