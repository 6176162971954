import Cookies from 'js-cookie'

export default () => {
  const accessToken = Cookies.get('STOKR_ACCESS_TOKEN')
  return accessToken
    ? {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      }
    : { 'Content-Type': 'application/json' }
}
