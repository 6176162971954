import styled, { css } from 'styled-components/macro'
import rwd from 'styles/rwd'
import {
  TrackH,
  TrackV,
  ThumbV,
} from 'components/ComponentScroll/ComponentScroll.styles'

export const StyledSidebar = styled.div`
  position: fixed;
  top: 64px;
  bottom: 0;
  left: 0;
  width: 300px;
  height: calc(100vh - 64px);
  background: ${(props) => props.theme.cWarning};
  transition: 0.3s width, 0.3s transform;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transform: translateX(-100%);
  z-index: 1000;
  user-select: none;

  ${(props) =>
    props.isSidebarExpanded &&
    css`
      transform: translateX(0);
    `}

  ${rwd.MLarge`
    top: 96px;
    width: 100px;
    height: calc(100vh - ${(props) => props.theme.headerHeight}px);
    transform: translateX(0);

    ${(props) =>
      props.isSidebarExpanded &&
      `
      width: 300px;
    `}
  `}

  ${TrackV} {
    right: 6px !important;
    background: rgba(255, 255, 255, 0.4);
  }

  ${ThumbV} {
    background: rgb(255, 255, 255);
  }

  ${TrackH} {
    visibility: hidden !important;
  }
`

export const ItemsContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
`

export const ItemsWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`
