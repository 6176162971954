import React from 'react'
import PropTypes from 'prop-types'
import { ThemeProvider } from 'styled-components/macro'

import GlobalStyle from 'styles/global'
import theme from 'styles/theme'
import { Footer, Header, AuthConsumer } from '@stokr/components-library'
import { PageWrapper } from 'components/Grid/Grid.styles'

const Layout = ({
  title,
  progress,
  loginHandler,
  signupHandler,
  signupFlow,
  children,
  footerColor,
  noHeader,
  noFooter,
}) => {
  const [activeMenu, setActiveMenu] = React.useState(null)

  const handleOutsideMenuClick = (e) => {
    setActiveMenu(false)
  }

  const activateMenu = () => {
    setActiveMenu(null)
  }

  return (
    <ThemeProvider theme={{ ...theme }}>
      <PageWrapper>
        <GlobalStyle />
        {!noHeader && (
          <AuthConsumer>
            {({ user, avatar, logoutUser }) => (
              <Header
                //isSidebarExpanded={isSidebarExpanded}
                //sidebarHandler={sidebarHandler}
                // withSidebar
                userPhoto={avatar}
                user={user}
                logoutUser={logoutUser}
              />
            )}
          </AuthConsumer>
        )}
        <div onClick={handleOutsideMenuClick}>{children}</div>
        {!noFooter && <Footer color={footerColor} />}
      </PageWrapper>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  title: PropTypes.string,
  signupFlow: PropTypes.bool,
  loginHandler: PropTypes.func,
  signupHandler: PropTypes.func,
  progress: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
    doneIndex: PropTypes.number,
    activeIndex: PropTypes.number,
  }),
  footerColor: PropTypes.string,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  title: '',
  progress: null,
  signupFlow: false,
  footerColor: 'red',
  loginHandler: () => {},
  signupHandler: () => {},
}

export default Layout
