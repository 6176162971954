import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

import { Container, Bar, Label } from './BarChartLegend.styles'

const gradients = {
  default: { start: '#0050ca', end: '#0050ca' },
  red: { start: '#f24f45', end: '#ee220d' },
  blue: { start: '#6a97e0', end: '#0050ca' },
}

class BarChartLegend extends PureComponent {
  componentDidMount() {
    this.createChart()
  }

  createChart = () => {
    if (!this.barEl) return

    const { id, colorTheme } = this.props

    const width = 50
    const thickness = 6
    const radius = thickness / 2

    this.svg = d3
      .select(this.barEl)
      .append('svg')
      .attr('width', width)
      .attr('height', thickness)

    this.defs = this.svg.append('defs')

    // #region gradient
    this.gradient = this.defs
      .append('linearGradient')
      .attr('id', `${id}-barChartLegend-gradient`)
      .attr('x1', '0%')
      .attr('x2', '100%')
      .attr('y1', '50%')
      .attr('y2', '50%')

    this.gradient
      .append('stop')
      .attr('class', 'start')
      .attr('offset', '0%')
      .attr('stop-color', gradients[colorTheme].start)
      .attr('stop-opacity', 1)

    this.gradient
      .append('stop')
      .attr('class', 'end')
      .attr('offset', '100%')
      .attr('stop-color', gradients[colorTheme].end)
      .attr('stop-opacity', 1)
    // #endregion gradient

    // #region bars
    this.bar = this.svg
      .append('path')
      .attr('class', 'bar')
      .style('fill', `url(#${id}-barChartLegend-gradient)`)
      .attr(
        'd',
        item => `
        M 0 0
        A ${radius} ${radius} 0 0 1 ${radius} ${-radius}
        H ${width - radius}
        A ${radius} ${radius} 0 0 1 ${width} 0
        A ${radius} ${radius} 0 0 1 ${width - radius} ${radius}
        H ${radius}
        A ${radius} ${radius} 0 0 1 0 0 
        Z
      `,
      )
      .attr('transform', `translate(0, ${radius})`)
    // #endregion bars
  }

  render() {
    const { label } = this.props

    return (
      <Container>
        <Bar
          ref={el => {
            this.barEl = el
          }}
        />
        <Label>{label}</Label>
      </Container>
    )
  }
}

BarChartLegend.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  colorTheme: PropTypes.oneOf(['default', 'red', 'blue']),
}

BarChartLegend.defaultProps = {
  colorTheme: 'default',
}

export default BarChartLegend
