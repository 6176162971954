import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  font-size: 0;

  & + & {
    margin-top: 8px;
  }
`

export const Label = styled.div`
  z-index: 1;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  padding-right: 10px;

  ${(props) =>
    props.labelWidth &&
    `
    width: ${props.labelWidth};
    margin-right: -${props.labelWidth};
  `}

  & > strong {
    font-weight: bold;
    display: block;
  }
`

export const Chart = styled.div`
  z-index: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;

  ${(props) =>
    props.labelWidth &&
    `
    padding-left: ${props.labelWidth};
  `}
`
