export const getCurrencySymbol = (tokenCurrency) => {
  switch (tokenCurrency) {
    case 'USD':
      return '$'
    case 'EUR':
      return '€'
    default:
      return ''
  }
}
