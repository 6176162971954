import React from 'react'
import PropTypes from 'prop-types'

import { Container, Date, Time } from './DateTime.styles'

const DateTime = ({ timestamp }) => {
  const day = `0${timestamp.getDate()}`.substr(-2)
  const month = `0${timestamp.getMonth() + 1}`.substr(-2)
  const year = timestamp.getFullYear()
  const date = `${day}/${month}/${year}`

  let hours = timestamp.getHours()
  const minutes = `0${timestamp.getMinutes()}`.substr(-2)
  const ampm = hours >= 12 ? 'pm' : 'am'
  hours = `0${hours % 12}`.substr(-2)
  const time = `${hours}:${minutes} ${ampm}`

  return (
    <Container>
      <Date>{date}</Date>
      <Time>{time}</Time>
    </Container>
  )
}

DateTime.propTypes = {
  timestamp: PropTypes.instanceOf(Object).isRequired,
}

export default DateTime
