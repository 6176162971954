import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  font-size: 0;
  padding: 2px 0;
`

export const Bar = styled.div`
  z-index: 1;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  margin-right: -50px;
`

export const Label = styled.div`
  z-index: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-left: 70px;
  max-width: 100%;
  color: ${props => props.theme.cGrey2};
  font-size: 11px;
  line-height: 16px;
`
