import styled from 'styled-components/macro'
import rwd from 'styles/rwd'

export const Table = styled.div`
  display: block;
  border: 1px solid ${props => props.theme.cLightGrey};
`

export const Row = styled.div`
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 2.1px;
  text-transform: uppercase;
  padding: 16px 24px;

  ${rwd.XLarge`
    padding: 16px 46px;
  `}

  & + & {
    border-top: 1px solid ${props => props.theme.cLightGrey};
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    clear: both;
  }
`

export const Label = styled.div`
  float: left;
`

export const Value = styled.div`
  float: right;
`
