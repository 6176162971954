import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Scrollbars } from 'react-custom-scrollbars-2'

import {
  Container,
  Outer,
  Inner,
  TrackH,
  ThumbH,
  TrackV,
  ThumbV,
} from './ComponentScroll.styles'

class ComponentScroll extends PureComponent {
  componentWillUnmount() {
    clearTimeout(this.timeout)
  }

  setRef = (el) => {
    if (this.scroll) return
    this.scroll = el
  }

  onMouseMove = () => {
    if (!this.debounce) {
      this.debounce = true
      this.refreshScrollBar()
    }

    clearTimeout(this.timeout)
    this.timeout = setTimeout(this.onDebounce, 300)
  }

  onDebounce = () => {
    this.debounce = false
    this.refreshScrollBar()
  }

  refreshScrollBar = () => {
    if (!this.scroll) return
    this.scroll.handleScroll()
  }

  onScroll = (e) => {
    const { handleScroll } = this.props
    handleScroll && handleScroll(e)
  }

  render() {
    const {
      children,
      noMobileScroll,
      autoHeight,
      autoHeightMax,
      autoHeightMin,
      offset,
      barPadding,
      topPadding,
      className,
    } = this.props

    return (
      <Container className={className} noMobileScroll={noMobileScroll}>
        <Outer offset={offset} barPadding={barPadding} topPadding={topPadding}>
          <Scrollbars
            ref={this.setRef}
            style={{ height: '100%' }}
            renderTrackHorizontal={(props) => <TrackH {...props} />}
            renderThumbHorizontal={(props) => <ThumbH {...props} />}
            renderTrackVertical={(props) => <TrackV {...props} />}
            renderThumbVertical={(props) => <ThumbV {...props} />}
            hideTracksWhenNotNeeded
            onMouseMove={this.onMouseMove}
            onScroll={this.onScroll}
            autoHeight={autoHeight}
            autoHeightMax={autoHeightMax}
            autoHeightMin={autoHeightMin}
            universal
          >
            <Inner offset={offset}>{children}</Inner>
          </Scrollbars>
        </Outer>
      </Container>
    )
  }
}

ComponentScroll.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noMobileScroll: PropTypes.bool,
  autoHeight: PropTypes.bool,
  autoHeightMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoHeightMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  barPadding: PropTypes.number,
  topPadding: PropTypes.number,
  offset: PropTypes.number,
  handleScroll: PropTypes.func,
}

ComponentScroll.defaultProps = {
  className: '',
  noMobileScroll: false,
  autoHeight: false,
  autoHeightMax: 0,
  autoHeightMin: 0,
  barPadding: 32,
  topPadding: 0,
  offset: 0,
  handleScroll: null,
}

export default ComponentScroll
