import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import {
  UpdateDefaultSvg,
  UpdateSoftSvg,
  UpdateHardSvg,
} from 'components/SvgIcons'

import {
  Container,
  IconWrapper,
  ContentWrapper,
  OptionsWrapper,
  DateBox,
  Title,
  Text,
  Type,
} from './UpdateItem.styles'

const UpdateItem = ({ date, title, text, options, soft, hard }) => (
  <Container hard={hard} soft={soft}>
    <IconWrapper hard={hard} soft={soft}>
      {!soft && !hard && <UpdateDefaultSvg />}
      {soft && <UpdateSoftSvg />}
      {hard && <UpdateHardSvg />}
    </IconWrapper>
    <ContentWrapper>
      <DateBox>{moment(date).format('DD MMM YYYY').toUpperCase()}</DateBox>
      {hard && <Type hard={hard}>{hard}</Type>}
      <Title>{title}</Title>
      <Text>{text}</Text>
    </ContentWrapper>
    {options && <OptionsWrapper>{options}</OptionsWrapper>}
  </Container>
)

UpdateItem.propTypes = {
  date: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  options: PropTypes.node,
  hard: PropTypes.bool,
  soft: PropTypes.bool,
}

UpdateItem.defaultProps = {
  options: null,
  hard: false,
  soft: false,
}

export default UpdateItem
