import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Formik, Field } from 'formik'
import * as Yup from 'yup'
import Input from 'components/Input/Input'
import Button from 'components/Button/Button.styles'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'

import avatarPlaceholder from 'static/images/avatar-placeholder.png'

import { FormError } from 'components/Form/Form'
import {
  Container,
  ImageWrapper,
  Avatar,
  Title,
  ErrorMessageBox,
  ErrorMessage,
  SuccessMessageBox,
  SuccessMessage,
} from './TeamMemberItemAdd.styles'

const TeamMemberItemAdd = ({
  id,
  placeholder,
  title,
  buttonText,
  addNewMember,
  projectName,
  error,
  success,
}) => (
  <Container>
    <ImageWrapper>
      <Avatar src={avatarPlaceholder} alt="avatar" />
    </ImageWrapper>
    <Title>{title}</Title>
    <Formik
      initialValues={{
        email: '',
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('Invalid email')
          .required('Email is required'),
      })}
      onSubmit={(e, { resetForm }) => {
        addNewMember({ ...e, sto_name: projectName })
        resetForm()
      }}
    >
      {({ errors, submitForm }) => (
        <Fragment>
          <ComponentWrapper noPaddingTop fluid isAddMember>
            <Field name="email">
              {({ field }) => (
                <Fragment>
                  <Input
                    id={id}
                    label={placeholder}
                    error={!!errors[field.name]}
                    {...field}
                  />
                  <FormError show={errors[field.name]}>
                    {errors[field.name]}
                  </FormError>
                </Fragment>
              )}
            </Field>
          </ComponentWrapper>
          <ErrorMessageBox>
            <ErrorMessage>
              {error && 'Oops, this email address is already in use…'}
            </ErrorMessage>
          </ErrorMessageBox>
          <SuccessMessageBox>
            <SuccessMessage>
              {success && 'Invitation successfully sent!'}
            </SuccessMessage>
          </SuccessMessageBox>
          <Button type="submit" onClick={submitForm}>
            {buttonText}
          </Button>
        </Fragment>
      )}
    </Formik>
  </Container>
)

TeamMemberItemAdd.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  addNewMember: PropTypes.func.isRequired,
}

TeamMemberItemAdd.defaultProps = {
  value: '',
  placeholder: '',
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
}

export default TeamMemberItemAdd
