import styled from 'styled-components/macro'
import rwd from 'styles/rwd'

export const Container = styled.div`
  position: relative;
  font-size: 0;

  & + & {
    margin-top: 20px;
  }
`

export const Bar = styled.div`
  z-index: 1;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 70px;
  margin-right: -70px;
  margin-top: 6px;

  ${props =>
    props.smallBar &&
    `
    width: 25px;
    margin-right: -25px;
    margin-top: 9px;
  `}
`

export const LabelBox = styled.div`
  z-index: 0;
  position: relative;
  display: inline-block;
  vertical-align: top;
  max-width: 100%;
  padding-left: 100px;

  ${props =>
    props.smallBar &&
    `
    padding-left: 45px;
  `}
`

export const Label = styled.div`
  display: block;
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;

  ${rwd.Medium`
    font-size: 16px;
    line-height: 20px;
  `}

  strong {
    display: inline-block;
    margin-right: 4px;
    font-weight: 600;
  }
`

export const Value = styled.div`
  display: block;
  font-size: 12px;
  line-height: 16px;

  ${rwd.Medium`
    font-size: 14px;
    line-height: 20px;
  `}
`

export const BasicDonutChartLegendWrapper = styled.div`
  display: block;

  ${props =>
    props.maxWidth &&
    `
    max-width: ${props.maxWidth};
  `}

  ${props =>
    props.center &&
    `
    margin: 0 auto;
  `}
`
