import React from 'react'
import { useExpanded, usePagination, useTable } from 'react-table'
import {
  StyledReactTable,
  Styles,
  TableDropdownIcon,
  TableWrap,
} from './Table.styles'
import PropTypes from 'prop-types'
import Pagination from 'components/Pagination/Pagination'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
import { Card, CardTitle } from '../Card/Card.styles'
import InputWithButton from '../InputWithButton/InputWithButton'
import Button from 'components/Button/Button.styles'

//This components is used only to memoize the values before we sent them to the react-table
const ReactTableWrapper = (props) => {
  const makeNewColumns = (data) => {
    return data?.map((column) => {
      if (column.key === 'dropdown') {
        return {
          Header: () => null,
          id: column.key,
          Cell: ({ row }) => (
            // Use Cell to render an expander for dropdown cell.
            // We can use the getToggleRowExpandedProps prop-getter
            // to build the expander. (moved to the whole row to make it easier to click)

            <span>
              <TableDropdownIcon isActive={row.isExpanded} />
            </span>
          ),
        }
      }
      return {
        Header: column.label,
        accessor: column.key,
        //totalWidth: 50, // column.width,
      }
    })
  }

  const memoizedColumns = React.useMemo(() => makeNewColumns(props.columns), [])
  const memoizedData = React.useMemo(() => props.data, [])

  const memoizedSubColumns = React.useMemo(
    () => makeNewColumns(props.subColumns),
    [],
  )

  return (
    <ReactTable
      columns={memoizedColumns}
      data={memoizedData}
      subColumns={memoizedSubColumns}
      blue={props.blue}
      withSubTable={props.withSubTable}
      calculateSubData={props.calculateSubData}
    />
  )
}

function ReactTable(props) {
  const {
    columns,
    data,
    subColumns,
    blue,
    noPagination,
    withSubTable,
    isSubTable,

    //instead of passing the subData, we pass a function that will calculate the subData
    //subData must be part of the data array (an array or whatever)
    //calculateSubData is a function that receives the row and returns the subData
    //it is used only when the row is expanded
    calculateSubData,
  } = props

  // Use the state and functions returned from useTable to build UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    visibleColumns,

    page, // page contains only the rows for the active page
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: isSubTable ? 10000 : 10,
      },
    },
    useExpanded,
    usePagination,
  )

  return (
    <Styles>
      <TableWrap>
        <StyledReactTable {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <StyledReactTable.Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <StyledReactTable.Th
                    {...column.getHeaderProps({
                      className: column.collapse ? 'collapse' : '',
                    })}
                    blue={blue}
                  >
                    {column.render('Header')}
                  </StyledReactTable.Th>
                ))}
              </StyledReactTable.Tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row)

              return (
                <>
                  <StyledReactTable.Tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <StyledReactTable.Td
                          {...cell.row.getToggleRowExpandedProps()}
                          {...cell.getCellProps({
                            className: cell.column.collapse ? 'collapse' : '',
                          })}
                          blue={blue}
                        >
                          {cell.render('Cell')}
                        </StyledReactTable.Td>
                      )
                    })}
                  </StyledReactTable.Tr>

                  {withSubTable && row.isExpanded && (
                    <StyledReactTable.Tr key={`${row.id}-${i}`}>
                      <StyledReactTable.Td
                        colSpan={visibleColumns.length}
                        subTable
                      >
                        <ReactTable
                          columns={subColumns}
                          data={calculateSubData(row)}
                          noPagination
                          isSubTable
                        />
                      </StyledReactTable.Td>
                    </StyledReactTable.Tr>
                  )}
                </>
              )
            })}
          </tbody>
        </StyledReactTable>
      </TableWrap>
      {!noPagination && (
        <>
          <ComponentWrapper
            noPaddingHorizontal
            flex
            style={{
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Pagination
              currentPage={pageIndex + 1}
              totalCount={data.length}
              pageSize={pageSize}
              onPageChange={(page) => gotoPage(page - 1)}
            />

            <Card noPadding>
              <CardTitle style={{ padding: '2px 20px' }}>Page Size:</CardTitle>
              <InputWithButton
                id="pageSize"
                name="pageSize"
                value={pageSize}
                type="number"
                placeholder={'Page size'}
                onChange={(e) => setPageSize(Number(e.target.value))}
              >
                <Button //onClick={() => setPageSize(Number(inputPageSize))}
                >
                  apply
                </Button>
              </InputWithButton>
            </Card>
          </ComponentWrapper>
        </>
      )}
    </Styles>
  )
}

ReactTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  subColumns: PropTypes.array,
  calculateSubData: PropTypes.func,
  blue: PropTypes.bool,
  noPagination: PropTypes.bool,
  withSubTable: PropTypes.bool,
  isSubTable: PropTypes.bool,
}
ReactTable.defaultProps = {
  blue: false,
  noPagination: false,
  withSubTable: false,
  isSubTable: false,
}

export default ReactTableWrapper
