import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;

  ${props =>
    props.separatorTop &&
    `
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid ${props.theme.cLightGrey};
  `}
`

export const InputWrap = styled.div`
  flex-grow: 1;
  z-index: 0;
  position: relative;
  margin-right: -40px;
`

export const ButtonWrap = styled.div`
  z-index: 1;
  position: relative;
`

export const Input = styled.input`
  display: block;
  border: 0;
  height: 40px;
  width: 100%;
  padding: 9px 23px;
  padding-right: 63px;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 20px;
  border: 1px solid ${props => props.theme.cLightGrey};
  border-radius: 20px;
  outline: 0;
  transition: border-color 0.2s;

  &:focus,
  &:hover {
    border: 1px solid ${props => props.theme.cPrimary};
  }

  &::placeholder {
    color: ${props => props.theme.cGrey2};
    opacity: 1;
  }
`
