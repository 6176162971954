import styled from 'styled-components/macro'
// import { Link } from 'react-router-dom'
import Icon, { iconsMap } from 'components/Icon/Icon.style'

export const PersonBox = styled.div`
  position: relative;
  font-size: 0;
`

export const Avatar = styled.div`
  z-index: 1;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 50px;
  height: 50px;
  margin-right: -50px;
  border-radius: 25px;
  overflow: hidden;
  background-color: ${(props) => props.theme.cGrey};
  transition: opacity 0.2s;
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
`

export const AvatarImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`

export const Name = styled.div`
  z-index: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  padding-left: 70px;
  transition: color 0.2s;
  word-break: break-word;
`

export const CommentsBox = styled.div`
  position: relative;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  padding-left: 24px;
  min-width: 54px;
`

export const CommentIcon = styled(Icon).attrs({
  icon: iconsMap.comments,
})`
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -8px;
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  margin-right: 10px;
  color: ${(props) => props.theme.cPrimary};
`

//export const Container = styled(Link)`
export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 20px 0;
  /* cursor: pointer; */
  outline: none;

  & + & {
    border-top: 1px solid ${(props) => props.theme.cLightGrey};
  }

  /*
  &:focus,
  &:hover {
    ${Avatar} {
      opacity: 0.8;
    }
    ${Name} {
      color: ${(props) => props.theme.cPrimary};
    }
    
  }
  */
`
