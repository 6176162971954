import styled from 'styled-components/macro'
import rwd from 'styles/rwd'
import Icon, { iconsMap } from 'components/Icon/Icon.style'

export const Container = styled.div`
  position: relative;
`

export const Head = styled.div`
  position: relative;
  margin-bottom: 5px;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    clear: both;
  }
`

export const Body = styled.div`
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    clear: both;
  }
`

export const Title = styled.div`
  float: left;
  font-weight: bold;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding-right: 20px;
`

export const Status = styled.span`
  display: inline-block;
  vertical-align: top;
  padding: 0px 16px;
  background-color: ${(props) => props.theme.cBlack};
  color: ${(props) => props.theme.cWhite};
  font-weight: 600;
  font-size: 8px;
  letter-spacing: 1.78px;
  line-height: 16px;
  border-radius: 8px;
  margin-left: 12px;
  margin-top: 4px;
`

export const TitleSide = styled.div`
  display: block;
  float: right;
  margin: 4px 0;
  width: 40%;
`

export const AddressWrap = styled.div`
  display: inline-block;
`

export const Address = styled.span`
  display: block;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: ${(props) => props.theme.cBlack};
  transition: color 0.2s;
  cursor: pointer;

  &:before {
    content: '\\f3d1';
    font-family: 'Ionicons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    line-height: 8px;
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 6px;
  }

  &:hover {
    color: ${(props) => props.theme.cPrimary};
  }
`

export const TableAddress = styled.span`
  line-height: 16px;
  cursor: pointer;

  &:hover {
    color: ${(props) => props.theme.cPrimary};
  }
`

export const DataBox = styled.div`
  float: left;
`

export const InfoBox = styled.div`
  float: right;
  width: 40%;
`

export const Value = styled.div`
  position: relative;
  font-weight: 300;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.4px;

  strong {
    font-weight: normal;

    &:after {
      content: ' ';
    }
  }
`

export const Equal = styled.div`
  position: relative;
  font-size: 9px;
  font-style: italic;
  line-height: 16px;
  letter-spacing: 0.4px;
  margin-top: 8px;
`

export const DataWrapper = styled.div`
  position: relative;
  padding-right: 24px;

  ${rwd.Medium`
    ${(props) =>
      props.withOptions &&
      `
      padding-right: 100px;

      ${TitleSide} {
        width: 50%;
      }

      ${InfoBox} {
        width: 50%;
      }
    `}
  `}
`

export const CheckMark = styled.div`
  position: absolute;
  display: block;
  width: 14px;
  height: 14px;
  top: 50%;
  right: 0;
  margin-top: -7px;
  border: 1px solid ${(props) => props.theme.cLightGrey};
  border-radius: 7px;
  background-color: transparent;
  transition: border-color 0.2s, background-color 0.2s;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: scale(0);
    transition: opacity 0.2s, transform 0.4s cubic-bezier(0.8, -0.5, 0.6, 0.6);
  }

  &:after {
    width: 4px;
    height: 4px;
    margin-top: -2px;
    margin-left: -2px;
    border-radius: 2px;
    background-color: ${(props) => props.theme.cPrimary};
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
  }
`

export const Radio = styled.div`
  & > input {
    ${(props) => props.hide && 'visibility: hidden'};
    z-index: -1;
    position: absolute;
    top: 4px;
    left: 4px;
    display: block;
    width: 1px;
    height: 0;
    opacity: 0;
    border: 0;

    & + label {
      display: block;
      position: relative;
      cursor: pointer;

      &:before {
        content: '';
        width: 6px;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background-color: transparent;
        transition: background-color 0.2s;
      }
    }

    &:disabled + label {
      &:before {
        background-color: transparent !important;
      }

      ${CheckMark} {
        border-color: ${(props) => props.theme.cLightGrey}!important;
        background-color: ${(props) => props.theme.cGrey}!important;
      }
    }

    &:checked + label {
      &:before {
        background-color: ${(props) => props.theme.cWarning}!important;
      }

      ${CheckMark} {
        &:before,
        &:after {
          opacity: 1;
          transform: scale(1);
          transition: opacity 0.2s,
            transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
      }
    }

    &:focus + label,
    &:hover + label,
    & + label:hover {
      &:before {
        background-color: ${(props) => props.theme.cLightGrey};
      }

      ${CheckMark} {
        border-color: ${(props) => props.theme.cPrimary};
      }
    }
  }
`

export const OptionsWrapper = styled.div`
  text-align: right;
  margin-right: -24px;

  ${rwd.Medium`
    margin-right: 0;
    position: absolute;
    display: block;
    width: 100px;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);  
  `}
`

export const CryptoAddressOption = styled.button`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  outline: none;
  margin-left: 10px;
  border: 1px solid ${(props) => props.theme.cLightGrey};
  border-radius: 20px;
  color: ${(props) => props.theme.cWarning};
  transition: color 0.2s;

  &:focus,
  &:hover {
    color: ${(props) => props.theme.cPrimary};
  }
`

export const CryptoAddressOptionIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  text-align: center;

  ${(props) =>
    props.large &&
    `
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 20px;
    margin-top: -10px;
    margin-left: -10px;
  `}
`

export const CryptoAddressToggle = styled.button`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  outline: none;
  margin-left: 10px;
  border-radius: 20px;
  color: ${(props) => props.theme.cBlack};
  transition: color 0.2s;
  transform: translateZ(0);

  &:focus,
  &:hover {
    color: ${(props) => props.theme.cPrimary};
  }

  &:before {
    content: '${iconsMap.arrowDown}';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 16px;
    height: 16px;
    font-size: 16px;
    line-height: 16px;
    margin-top: -8px;
    margin-left: -8px;
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    transition: transform 0.2s;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  ${(props) =>
    props.isActive &&
    `
    &:before {
      transform: rotate(180deg);
    }
  `}
`
