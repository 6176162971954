// import AdminIndex from 'pages/admin-dashboard'
import AdminAnalytics from 'pages/admin-dashboard/analytics'
import AdminComments from 'pages/admin-dashboard/comments'
import AdminOverview from 'pages/admin-dashboard/overview'
import AdminRequestUpdate from 'pages/admin-dashboard/request-update'
import AdminTeam from 'pages/admin-dashboard/team'
import AdminTokenHolder from 'pages/admin-dashboard/token-holder'
import AdminUpdates from 'pages/admin-dashboard/updates'
import AdminSettings from 'pages/admin-dashboard/settings'
import AdminPrivateInvestor from 'pages/admin-dashboard/private-investor'
import UiPage from 'pages/admin-dashboard/ui'
import OopsPage from 'pages/oops'

const AdminDashboardRoutes = [
  {
    path: '/',
    //component: AdminIndex,
    component: <AdminOverview />,
  },
  {
    path: '/analytics',
    component: <AdminAnalytics />,
  },
  {
    path: '/comments',
    component: <AdminComments />,
  },
  {
    path: '/overview',
    component: <AdminOverview />,
  },
  {
    path: '/request-update',
    component: <AdminRequestUpdate />,
  },
  {
    path: '/team',
    component: <AdminTeam />,
  },
  {
    path: '/token-holder',
    component: <AdminTokenHolder />,
  },
  {
    path: '/private-investor',
    component: <AdminPrivateInvestor />,
  },
  {
    path: '/updates',
    component: <AdminUpdates />,
  },
  {
    path: '/ui',
    component: <UiPage />,
  },
  {
    path: '/oops',
    component: <OopsPage />,
  },
  {
    path: '/settings',
    component: <AdminSettings />,
  },
]

export default AdminDashboardRoutes
