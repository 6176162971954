import { css } from 'styled-components/macro'

const reactTippyStyle = css`
  .tippy-popper {
    .tippy-tooltip {
      border-radius: 10px;
      font-size: 11px;
      line-height: 14px;
      letter-spacing: 0.4px;
      padding: 12px;
    }

    .tippy-tooltip.light-theme {
      box-shadow: 0 0 12px 8px rgba(0, 0, 0, 0.03),
        0 0 6px 3px rgba(0, 0, 0, 0.02);
    }
    .tippy-tooltip {
      overflow-wrap: anywhere;
    }

    &[x-placement^='top'] [x-arrow] {
      border-top-color: ${(props) => props.theme.cPrimary}!important;
    }

    &[x-placement^='bottom'] [x-arrow] {
      border-bottom-color: ${(props) => props.theme.cPrimary}!important;
    }

    &[x-placement^='left'] [x-arrow] {
      border-left-color: ${(props) => props.theme.cPrimary}!important;
    }

    &[x-placement^='right'] [x-arrow] {
      border-right-color: ${(props) => props.theme.cPrimary}!important;
    }

    .tippy-tooltip.transparent-theme {
      box-shadow: 0 0 12px 8px rgba(0, 0, 0, 0.03),
        0 0 6px 3px rgba(0, 0, 0, 0.02);
      color: #26323d;
      background-color: white;
    }

    &[x-placement^='top'] .tippy-tooltip.transparent-theme [x-arrow] {
      border-top: 7px solid #ee220d;
      border-top-color: #ee220d !important;
    }
  }
`

export default reactTippyStyle
