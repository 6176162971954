import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import { Tooltip } from 'react-tippy'

import { Chart, TooltipWrapper } from './LineChart.styles'
import { ChartTitle } from 'components/BarChart/BarChart.styles'

class LineChart extends PureComponent {
  transitionDuration = 800

  transitionDatumDelay = 100

  state = {
    tooltipOpen: false,
    dataIndex: 0,
  }

  componentDidMount() {
    this.createChart()
    window.addEventListener('resize', this.onResize)
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('scroll', this.onScroll)
  }

  onResize = () => {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(this.onResizeTimeout, 200)
  }

  onResizeTimeout = () => {
    this.updateChart(true)
  }

  onScroll = (e) => {
    if (this.drawn) return
    if (this.chartEl.getBoundingClientRect().top < window.innerHeight) {
      this.initialDrawChart()
    }
  }

  initialDrawChart = () => {
    this.updateChart()
    this.drawn = true
  }

  setVariables = (wrapperWidth, data) => {
    // eslint-disable-line
    const width = wrapperWidth
    const height = width * 0.43
    return {
      width,
      height,
      paddingRigth: 55,
      paddingBottom: height * 0.15,
      paddingTop: height * 0.15,
      paddingLeft: 10,
      max: data.length,
    }
  }

  createChart = () => {
    if (!this.chartEl) return

    const { id, data, fill, stroke, months } = this.props
    const initData = data.map((datum) => ({
      ...datum,
      value: 0,
    }))
    const { dataIndex } = this.state
    const tooltip = !!data.length > 0 && data[dataIndex].tooltip

    const wrapperWidth = this.chartEl.offsetWidth
    const wrapperHeight = wrapperWidth * 0.43
    const margin = {
      top: 30,
      left: 0,
      right: 55,
      bottom: 50,
    }
    const width = wrapperWidth - (margin.left + margin.right)
    const height = wrapperHeight - (margin.top + margin.bottom)
    const dotRadius = 6
    this.dotRadius = dotRadius

    // #region x scale
    const xScale = this.getXScale(data, width)
    // #endregion x scale

    // #region y scale
    const yScale = this.getYScale(data, height)
    // #endregion y scale

    // #region line
    const line = this.getLine(xScale, yScale)
    // #endregion line

    // #region area
    const area = this.getArea(xScale, yScale)
    // #endregion area

    const euroFormat = (d) => `€${d3.format('~s')(d)}`
    const bottomFormat = (d) => months[d3.format(',.0f')(d)]

    this.svg = d3
      .select(this.chartEl)
      .append('svg')
      .attr('width', wrapperWidth)
      .attr('height', wrapperHeight)

    this.chart = this.svg
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)

    const defs = this.svg.append('defs')

    // #region gradient
    const gradient = defs
      .append('linearGradient')
      .attr('id', `${id}-lineChart-gradient`)
      .attr('x1', 0)
      .attr('x2', 0)
      .attr('y1', 0)
      .attr('y2', 1)

    gradient.append('stop').attr('stop-color', fill).attr('offset', '0')

    gradient
      .append('stop')
      .attr('stop-color', '#ffffff')
      .attr('stop-opacity', 0)
      .attr('offset', '1')
    // #endregion gradient

    // #region grid y axis
    this.yGrid = this.chart
      .append('g')
      .attr('class', 'gridY')
      .attr('transform', `translate(${width}, 0)`)
      .call(
        d3
          .axisRight()
          .scale(yScale)
          .tickSize(-width, 0, 0)
          .ticks(5)
          .tickFormat(''),
      )
    // #endregion grid y axis

    // #region line
    this.line = this.chart
      .append('path')
      .datum(data)
      .attr('class', 'chartLine')
      .attr('fill', 'none')
      .attr('stroke', stroke)
      .attr('stroke-width', 2)
      .attr('d', line)
    // #endregion line

    // #region area
    this.area = this.chart
      .append('path')
      .datum(initData)
      .attr('class', 'chartArea')
      .attr('fill', `url(#${id}-lineChart-gradient)`)
      .attr('stroke', 'none')
      .attr('d', area)
    // #endregion area

    const tickValues =
      xScale.domain().length > 20
        ? xScale.domain().filter((datum, index) => !(index % 2))
        : xScale.domain()

    // #region x axis
    this.xAxis = this.chart
      .append('g')
      .attr('class', 'axisX')
      .attr('transform', `translate(0, ${height + 20})`)
      .call(
        d3
          .axisBottom()
          .scale(xScale)
          .tickValues(tickValues)
          .tickFormat((value) => value.split(' ')[0]),
      )
    // #endregion x axis

    // #region y axis
    this.yAxis = this.chart
      .append('g')
      .attr('class', 'axisY')
      .attr('transform', `translate(${width}, 0)`)
      .call(d3.axisRight().scale(yScale).ticks(5)) //.tickFormat(euroFormat))
    // #endregion y axis

    // #region dots
    const chartContext = this
    this.chart
      .selectAll()
      .data(data)
      .enter()
      .append('circle')
      .attr('class', 'dot')
      .attr('fill', stroke)
      .attr('stroke', '#ffffff')
      .attr('stroke-width', 2)
      .attr('cx', (d) => xScale(d.date || d.name))
      .attr('cy', (d) => yScale(d.value))
      .attr('r', 0)
      .each(function bindContext(d, i) {
        d3.select(this).node().chartContext = chartContext
      })
      .on('mouseenter', tooltip ? this.onMouseEnter : () => {})
      .on('mouseleave', tooltip ? this.onMouseLeave : () => {})
    // #endregion dots
  }

  updateChart = (resizeUpdate) => {
    if (!this.chartEl) return

    const { data, months, splitName } = this.props

    const durration = this.transitionDuration
    const delay = this.transitionDatumDelay

    const wrapperWidth = this.chartEl.offsetWidth
    const wrapperHeight = wrapperWidth * 0.43
    const margin = {
      top: 30,
      left: 10,
      right: 55,
      bottom: 50,
    }
    const width = wrapperWidth - (margin.left + margin.right)
    const height = wrapperHeight - (margin.top + margin.bottom)
    const dotRadius = 6
    this.dotRadius = dotRadius

    // #region x scale
    const xScale = this.getXScale(data, width, splitName)
    // #endregion x scale

    // #region y scale
    const yScale = this.getYScale(data, height)
    // #endregion y scale

    // #region line
    const line = this.getLine(xScale, yScale)
    // #endregion line

    // #region area
    const area = this.getArea(xScale, yScale)
    // #endregion area

    const pathTransition = (path) => {
      path
        .transition()
        .duration(durration * 4)
        .attrTween('stroke-dasharray', tweenDash(path.node()))
    }

    const tweenDash = (node) => {
      const l = node.getTotalLength()
      const i = () => d3.interpolateString(`0,${l}`, `${l},${l}`)
      return (t) => i(t)
    }

    const euroFormat = (d) => `€${d3.format('~s')(d)}`
    const bottomFormat = (d) => months[d3.format(',.0f')(d)]

    this.svg
      .transition()
      .duration(durration)
      .attr('width', wrapperWidth)
      .attr('height', wrapperHeight)

    // #region grid y axis
    this.yGrid
      .transition()
      .duration(durration)
      .attr('transform', `translate(${width}, 0)`)
      .call(
        d3
          .axisRight()
          .scale(yScale)
          .tickSize(-width, 0, 0)
          .ticks(5)
          .tickFormat(''),
      )
    // #emdregion grid y axis

    // #region line
    this.line.datum(data).transition().duration(durration).attr('d', line)

    !resizeUpdate && this.line.datum(data).attr('d', line).call(pathTransition)
    // #endregion line

    // #region area
    this.area.datum(data).transition().duration(durration).attr('d', area)
    // #endregion area

    const tickValues =
      xScale.domain().length > 20
        ? xScale.domain().filter((datum, index) => !(index % 2))
        : xScale.domain()

    // #region x axis
    this.xAxis
      .transition()
      .duration(durration)
      .attr('transform', `translate(0, ${height + 20})`)
      .call(
        d3
          .axisBottom()
          .scale(xScale)
          .tickValues(tickValues)
          .tickFormat((value) => value.split(' ')[0]),
      )
    // #endregion x axis

    // #region y axis
    this.yAxis
      .transition()
      .duration(durration)
      .attr('transform', `translate(${width}, 0)`)
      .call(d3.axisRight().scale(yScale).ticks(5)) //.tickFormat(euroFormat))
    // #endregion y axis

    // #region dots
    const dots = this.chart.selectAll('.dot')
    dots
      .data(data)
      .transition()
      .duration(durration)
      .attr('cx', (d) => xScale(d.date || d.name))
      .attr('cy', (d) => yScale(d.value))

    !resizeUpdate &&
      dots
        .data(data)
        .attr('cx', (d) => xScale(d.date || d.name))
        .attr('cy', (d) => yScale(d.value))
        .transition()
        .delay((_, index) => (resizeUpdate ? 0 : index * delay))
        .duration(durration)
        .attr('r', dotRadius)
    // #endregion dots
  }

  getLine = (xScale, yScale) =>
    d3
      .area()
      .x((datum) => xScale(datum.date || datum.name))
      .y((datum) => yScale(datum.value))

  getArea = (xScale, yScale) =>
    d3
      .area()
      .x((datum) => xScale(datum.date || datum.name))
      .y0(yScale(0) + 2)
      .y1((datum) => yScale(datum.value))

  getXScale = (data, width, splitName) =>
    d3
      .scaleBand()
      .range([0, width])
      .domain(
        data.map((datum) => {
          return datum.date || datum.name
        }),
      )
      .paddingInner(1)
      .paddingOuter(0.5)

  getYScale = (data, height) =>
    d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, d3.max(data, (datum) => datum.value)])

  setTooltip = (opened, dataIndex) => {
    const newState = {
      tooltipOpen: opened,
    }

    if (dataIndex !== undefined) newState.dataIndex = dataIndex

    this.setState(newState)
  }

  onMouseEnter = function onMouseEnter(d, i) {
    const chartRect = this.chartContext.chartEl.getBoundingClientRect()
    const dotRect = this.getBoundingClientRect()
    const y = dotRect.y - chartRect.y
    const x = dotRect.x - chartRect.x + this.chartContext.dotRadius
    this.chartContext.tooltipEl.style.left = `${x}px`
    this.chartContext.tooltipEl.style.top = `${y}px`
    this.chartContext.setTooltip(true, i)
  }

  onMouseLeave = function onMouseLeave(d, i) {
    this.chartContext.setTooltip(false)
  }

  render() {
    const { tooltipOpen, dataIndex } = this.state
    const { data, title } = this.props

    return (
      <>
        {title && <ChartTitle>{title}</ChartTitle>}
        <Chart
          ref={(el) => {
            this.chartEl = el
          }}
        >
          {data.length > 0 && data[dataIndex].tooltip && (
            <TooltipWrapper
              ref={(el) => {
                this.tooltipEl = el
              }}
            >
              <Tooltip
                open={tooltipOpen}
                position="top"
                animation="fade"
                html={data[dataIndex].tooltip}
                theme="light"
                arrow
                duration={200}
              />
            </TooltipWrapper>
          )}
        </Chart>
      </>
    )
  }
}

LineChart.propTypes = {
  id: PropTypes.string.isRequired,
  fill: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
  months: PropTypes.arrayOf(String).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.number,
      value: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  ).isRequired,
}

export default LineChart
