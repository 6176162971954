import styled from 'styled-components/macro'

export const StyledSidebarItem = styled.div`
  display: flex;
  align-items: center;
  padding: 27px 41px;
  transition: background-color 0.3s;
  color: ${props => props.theme.cWhite};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.cWarningDark};
  }

  ${props =>
    props.isActive &&
    `
    background-color: #e3210d;
  `}
`

export const IconWrapper = styled.div`
  padding-right: 42px;
  flex-shrink: 0;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
`

export const IconContainer = styled.div`
  width: 18px;
  height: 18px;
`

export const Title = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  white-space: nowrap;
`
