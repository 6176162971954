import React, { PureComponent, Fragment } from 'react'
import Layout from 'components/AdminDashboard/Layout/Layout'
// import SEO from 'components/SEO/SEO'
import {
  Row,
  Column,
  ContentBox,
} from 'components/AdminDashboard/Grid/Grid.styles'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
import {
  Card,
  CardTitle,
  CardHeader,
} from 'components/AdminDashboard/Card/Card.styles'
import Text from 'components/Text/Text.styles'
import Button from 'components/Button/Button.styles'
import CategoryButton from 'components/AdminDashboard/CategoryButton/CategoryButton'
import ContributorItem from 'components/AdminDashboard/ContributorItem/ContributorItem'
import CommentItem from 'components/AdminDashboard/CommentItem/CommentItem'
import ItemOptions from 'components/AdminDashboard/ItemOptions/ItemOptions'
import TextEditor from 'components/AdminDashboard/TextEditor/TextEditor'
import InputWithButton from 'components/AdminDashboard/InputWithButton/InputWithButton'
import CommentReplyModal from 'components/AdminDashboard/CommentReplyModal/CommentReplyModal'
import Select from 'components/Input/Select'
import { SelectWrapper } from 'components/Input/Select.styles'
import AlertModal from 'components/AdminDashboard/AlertModal/AlertModal'

import { AuthContext, AuthConsumer } from '@stokr/components-library'
import {
  CommentsProvider,
  CommentsConsumer,
} from 'context/CommentsContext/CommentsContext'

import CommentPinModal from 'components/AdminDashboard/CommentPinModal/CommentPinModal'
import { ProjectContext } from 'context/ProjectContext/ProjectContext'
import Project from 'context/ProjectContext/Project'
import getAuthHeader from 'utils/getAuthHeader'
import axios from 'axios'

const baseURLPublic = process.env.REACT_APP_BASE_URL_PUBLIC

class AdminComments extends PureComponent {
  state = {
    category: 'Unread',
    message: '',
    replyFor: null,
    toRemove: null,
    toPin: null,
    toUnpin: null,
    // filter: null,
    filter: 'user-name',
    filterPhrase: '',
    filterInput: '',
    project: undefined,
    threadId: undefined,
  }

  static contextType = ProjectContext

  async componentDidMount() {
    const user = this.context.user
    const project = await Project.getProject(user)

    if (project) {
      this.setState(
        {
          project,
        },
        () => this.setThreadId(),
      )
    }
  }

  componentDidUpdate() {
    const project = this.state.project

    if (project) {
      this.setState(
        {
          project: project,
        },
        () => this.setThreadId(),
      )
    }
  }

  setThreadId = async () => {
    const { channels } = this.state.project
    const channelId = channels[0]

    const response = await axios.post(
      `${baseURLPublic}/channel/public/get`,
      { channelId },
      { headers: getAuthHeader() },
    )
    const { channel } = response?.data
    const { threads } = channel
    const threadId = threads.length > 0 && threads[0]._id
    if (threadId) {
      this.setState({ threadId })
    }
  }

  changeCategory = (category) => {
    this.setState({
      category,
    })
  }

  updateMessage = (e) => {
    this.setState({
      message: e.target.value,
    })
  }

  commentReply = (commentId) => {
    this.setState({
      replyFor: commentId,
    })
  }

  commentRemove = (toRemove) => {
    this.setState({
      toRemove,
    })
  }

  commentPin = (toPin) => {
    this.setState({
      toPin,
    })
  }

  commentUnpin = (toUnpin) => {
    this.setState({
      toUnpin,
    })
  }

  renderCommentOptions = (commentId, subComment, pinComment) => {
    const itemOptions = [
      {
        name: 'remove',
        icon: 'trash',
        onClick: () => this.commentRemove({ commentId, subComment }),
      },
    ]

    if (!subComment) {
      itemOptions.push({
        name: 'pin',
        icon: 'pin',
        onClick: () => pinComment(commentId),
      })
    }

    return <ItemOptions options={itemOptions} />
  }

  onFilterChange = (e) => {
    this.setState({
      filter: e.value,
    })
  }

  onFilterInputChange = (e) => {
    this.setState({ filterInput: e.target.value })
  }
  handleFilter = (e) => {
    this.setState({ filterPhrase: this.state.filterInput })
  }

  render() {
    const {
      category,
      message,
      replyFor,
      filter,
      filterPhrase,
      toRemove,
      toPin,
      toUnpin,
      project,
      threadId,
      filterInput,
    } = this.state

    const filterLabel = {
      'user-name': 'Enter name',
      'text-phrase': 'Enter phrase',
    }

    return (
      <CommentsProvider
        projectId={project && project._id}
        channelId={project && project.channels[0]}
        threadId={threadId}
      >
        <CommentsConsumer>
          {({
            commentsCount,
            likesCount,
            topFiveContributors,
            hasMoreComments,
            getFilteredComments,
            removeComment,
            lockComment,
            pinComment,
            unpinComment,
            onLoadMoreClick,
            toggleLike,
            addNewComment,
            addNewReply,
          }) => {
            const comments = getFilteredComments(filter, filterPhrase)

            const commentPin = (commentId) => {
              if (
                comments.find((comment) => comment._id === commentId).isPinned
              ) {
                this.commentUnpin(commentId)
                return
              }
              if (comments.some((comment) => comment.isPinned)) {
                this.commentPin(commentId)
                return
              }
              this.commentPin(commentId)
            }

            return (
              <Layout currentPage="comments">
                {/* <SEO
                  title="Admin Dashboard - comments"
                  keywords={['gatsby', 'application', 'react']}
                /> */}
                <Row mainDashboard>
                  <Column>
                    <ContentBox>
                      <Row>
                        <Column partLg={11} isComments>
                          <Row>
                            <Column>
                              <Card>
                                <Text>
                                  {project && (
                                    <h3>{`${project.name} - COMMENTS (${commentsCount})`}</h3>
                                  )}
                                </Text>
                              </Card>
                            </Column>

                            <Column isComments>
                              <Card>
                                <ComponentWrapper
                                  noPaddingTop
                                  noPaddingHorizontal
                                >
                                  <CardTitle>Add comment</CardTitle>
                                </ComponentWrapper>
                                <ComponentWrapper noPadding>
                                  <TextEditor
                                    isUpdates
                                    id="new-comment"
                                    name="new-comment"
                                    placeholder="Write comment or question…"
                                    info="Write comment"
                                    value={message}
                                    onChange={this.updateMessage}
                                    limit={280}
                                    minHeight={110}
                                    maxHeight={110}
                                    done={{
                                      label: 'Post',
                                      onClick: () => {
                                        this.setState({ message: '' })
                                        addNewComment(message)
                                      },
                                    }}
                                    cancel={{
                                      label: 'Cancel',
                                      onClick: () => {
                                        this.setState({ message: '' })
                                      },
                                    }}
                                  />
                                </ComponentWrapper>
                              </Card>
                            </Column>

                            <Column isComments>
                              <Card>
                                <AuthConsumer>
                                  {({ user }) =>
                                    comments.map((comment) => (
                                      <CommentItem
                                        project={project}
                                        key={comment._id}
                                        userID={comment.author._id}
                                        name={
                                          comment.author.username ||
                                          comment.author.name ||
                                          'Anonymous'
                                        }
                                        time={comment.creation_dt}
                                        message={comment.text}
                                        likes={comment.likes.length}
                                        userLike={comment.likes.some(
                                          (like) => like.user_id === user._id,
                                        )}
                                        superAdmin={
                                          comment.author.user_type ===
                                          'company_sa'
                                        }
                                        isPinned={comment.isPinned}
                                        options={this.renderCommentOptions(
                                          comment._id,
                                          null,
                                          commentPin,
                                        )}
                                        handleLikeClick={() => {
                                          toggleLike(user._id, comment._id)
                                        }}
                                        handleReplyClick={() => {
                                          this.commentReply(comment._id)
                                        }}
                                        replies={
                                          comment.comments && (
                                            <Fragment>
                                              {comment.comments.map((reply) => (
                                                <CommentItem
                                                  project={project}
                                                  key={reply._id}
                                                  userID={reply.author._id}
                                                  name={
                                                    reply.author.username ||
                                                    reply.author.name ||
                                                    'Anonymous'
                                                  }
                                                  time={reply.creation_dt}
                                                  message={reply.text}
                                                  likes={reply.likes.length}
                                                  userLike={reply.likes.some(
                                                    (like) =>
                                                      like.user_id === user._id,
                                                  )}
                                                  superAdmin={
                                                    reply.author.user_type ===
                                                    'company_sa'
                                                  }
                                                  options={this.renderCommentOptions(
                                                    comment._id,
                                                    reply._id,
                                                  )}
                                                  handleLikeClick={() => {
                                                    toggleLike(
                                                      user._id,
                                                      comment._id,
                                                      reply._id,
                                                    )
                                                  }}
                                                  allowReply={false}
                                                />
                                              ))}
                                            </Fragment>
                                          )
                                        }
                                      />
                                    ))
                                  }
                                </AuthConsumer>
                                {filterPhrase === '' && hasMoreComments && (
                                  <ComponentWrapper
                                    noPaddingBottom
                                    noPaddingHorizontal
                                    center
                                  >
                                    <Button onClick={onLoadMoreClick}>
                                      Load more
                                    </Button>
                                  </ComponentWrapper>
                                )}
                              </Card>
                            </Column>
                          </Row>
                        </Column>

                        <Column partLg={5} isComments>
                          <Row>
                            <Column>
                              <Card lessPadding>
                                <ComponentWrapper
                                  noPaddingTop
                                  noPaddingHorizontal
                                  borderBottom
                                >
                                  <CardTitle>Navigation</CardTitle>
                                </ComponentWrapper>
                                <ComponentWrapper noPadding>
                                  {/*
                                  <CategoryButton
                                    icon="unread"
                                    name="Unread"
                                    color="#0050ca"
                                    counter={17}
                                    handleClick={() => {
                                      this.changeCategory('Unread')
                                    }}
                                    isActive={category === 'Unread'}
                                    side
                                    notClickable
                                  />
                                  */}

                                  <CategoryButton
                                    icon="heart"
                                    name="Likes"
                                    color="#ee220d"
                                    counter={likesCount}
                                    handleClick={() => {
                                      this.changeCategory('Likes')
                                    }}
                                    isActive={category === 'Likes'}
                                    side
                                    notClickable
                                  />
                                  <CategoryButton
                                    icon="star"
                                    name="Total comments"
                                    color="#2f2f2f"
                                    counter={commentsCount}
                                    handleClick={() => {
                                      this.changeCategory('Total comments')
                                    }}
                                    isActive={category === 'Total comments'}
                                    side
                                    notClickable
                                  />
                                  {/*
                                  <CategoryButton
                                    icon="user"
                                    name="Total user’s engagement"
                                    color="#69bd53"
                                    counter={7}
                                    handleClick={() => {
                                      this.changeCategory(
                                        'Total user’s engagement',
                                      )
                                    }}
                                    isActive={
                                      category === 'Total user’s engagement'
                                    }
                                    side
                                    notClickable
                                  />
                                  */}
                                </ComponentWrapper>
                              </Card>
                            </Column>

                            <Column isComments>
                              <Card lessPadding>
                                <CardHeader>
                                  <CardTitle>Filter by</CardTitle>
                                  <SelectWrapper minWidth="110px">
                                    <Select
                                      id="filter-by"
                                      name="filter-by"
                                      value={filter}
                                      options={[
                                        {
                                          key: 'user-name',
                                          value: 'user-name',
                                          label: 'User name',
                                        },
                                        {
                                          key: 'text-phrase',
                                          value: 'text-phrase',
                                          label: 'Text phrase',
                                        },
                                      ]}
                                      onChange={this.onFilterChange}
                                    />
                                  </SelectWrapper>
                                </CardHeader>
                                <InputWithButton
                                  id="filter"
                                  name="filter"
                                  value={filterInput}
                                  placeholder={filterLabel[filter]}
                                  separatorTop
                                  onChange={this.onFilterInputChange}
                                >
                                  <Button onClick={this.handleFilter}>
                                    Filter
                                  </Button>
                                </InputWithButton>
                              </Card>
                            </Column>

                            <Column isComments>
                              <Card lessPadding>
                                <ComponentWrapper
                                  noPaddingTop
                                  noPaddingHorizontal
                                  borderBottom
                                >
                                  <CardTitle>Top 5 Contributors</CardTitle>
                                </ComponentWrapper>
                                <ComponentWrapper noPadding>
                                  {topFiveContributors.map((contributor) => {
                                    return (
                                      <ContributorItem
                                        key={contributor._id}
                                        userID={contributor._id}
                                        name={
                                          contributor.username ||
                                          contributor.name ||
                                          'Anonymous'
                                        }
                                        comments={contributor.count}
                                        to="/"
                                      />
                                    )
                                  })}
                                </ComponentWrapper>
                              </Card>
                            </Column>
                          </Row>
                        </Column>
                      </Row>
                    </ContentBox>
                  </Column>
                </Row>

                <CommentReplyModal
                  replyFor={replyFor}
                  onModalClose={() => {
                    this.commentReply(null)
                  }}
                  addNewReply={addNewReply}
                  comments={comments}
                  toggleLike={toggleLike}
                  project={project}
                />

                <AlertModal
                  isOpen={toRemove !== null}
                  onModalClose={() => {
                    this.commentRemove(null)
                  }}
                  onButtonClick={() => {
                    removeComment(toRemove.commentId, toRemove.subComment)
                    this.commentRemove(null)
                  }}
                  warningText="Are you sure you want to remove this comment?"
                  buttonText="Remove"
                />

                <CommentPinModal
                  isOpen={toPin !== null}
                  pinnedComments={comments.filter(
                    (comment) => comment.isPinned,
                  )}
                  onModalClose={() => {
                    this.commentPin(null)
                  }}
                  onButtonClick={(priority) => {
                    pinComment(toPin, priority)
                    this.commentPin(null)
                  }}
                />

                <AlertModal
                  isOpen={toUnpin !== null}
                  onModalClose={() => {
                    this.commentUnpin(null)
                  }}
                  onButtonClick={() => {
                    unpinComment(toUnpin)
                    this.commentUnpin(null)
                  }}
                  warningText="Are you sure you want to unpin comment?"
                  buttonText="Unpin"
                />
              </Layout>
            )
          }}
        </CommentsConsumer>
      </CommentsProvider>
    )
  }
}

export default AdminComments

AdminComments.contextType = AuthContext
