import React from 'react'
import PropTypes from 'prop-types'
import Text from 'components/Text/Text.styles'
import avatarCog from 'static/images/avatar-cog.png'
import { Container, UserAvatar } from './NotificationItem.styles'

const NotificationItem = ({ id, type, title, text, avatar }) => (
  <Container>
    <UserAvatar src={type === 'PROJECT_USER' ? avatar : avatarCog} />
    <Text>
      <p>
        <strong>
          {title}
          {type === 'PROJECT_USER' ? ': ' : ' '}
        </strong>
        {text}
      </p>
    </Text>
  </Container>
)

NotificationItem.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  text: PropTypes.string,
}

NotificationItem.defaultProps = {
  text: '',
  avatar: '',
}

export default NotificationItem
