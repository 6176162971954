import styled from 'styled-components/macro'
import rwd from 'styles/rwd'

export const StyledTabs = styled.div`
  display: inline-block;
  max-width: 100%;
  position: relative;
  background-color: ${props => props.theme.cGrey};
  border: 1px solid ${props => props.theme.cLightGrey};
  border-radius: 3px;
  margin-top: ${({ marginTop }) => marginTop && '32px'};
`

export const Scroll = styled.div`
  position: relative;
  overflow: hidden;
  overflow-x: auto;
`

export const TabsWrapper = styled.div`
  position: relative;
  white-space: nowrap;
  background-color: ${props => props.theme.cGrey};
`

export const LinkText = styled.span`
  display: block;
  position: relative;
  font-family: 'Open Sans';
  font-size: 11px;
  letter-spacing: 2px;
  line-height: 20px;
  color: ${props => props.theme.cBlack};
  opacity: 0.3;
  transition: opacity 0.2s;
`

export const StyledTab = styled.button`
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  padding: 12px;
  transition: background-color 0.2s;
  border-left: 1px solid ${props => props.theme.cLightGrey};
  text-transform: uppercase;

  &:first-of-type {
    border-left: none;
  }

  ${rwd.Medium`
    padding: 12px 24px;
  `}

  ${rwd.Large`
    padding: 12px 32px;
  `}

  &:focus ,
  &:hover {
    ${LinkText} {
      opacity: 1;
    }
  }

  ${props =>
    props.isActive &&
    `
    background-color: ${props.theme.cWhite};

    ${LinkText} {
      opacity: 1;
      font-weight: bold;
    }
  `}
`
