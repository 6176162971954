import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

import { Chart } from './BasicLineChart.styles'

class BasicLineChart extends PureComponent {
  transitionDuration = 600

  componentDidMount() {
    this.createChart()
    window.addEventListener('resize', this.onResize)
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('scroll', this.onScroll)
  }

  onResize = () => {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(this.onResizeTimeout, 200)
  }

  onResizeTimeout = () => {
    this.updateChart(true)
  }

  onScroll = e => {
    if (this.drawn) return
    if (this.chartEl.getBoundingClientRect().top < window.innerHeight) {
      this.initialDrawChart()
    }
  }

  initialDrawChart = () => {
    this.updateChart()
    this.drawn = true
  }

  createChart = () => {
    if (!this.chartEl) return

    const { data, fill, stroke } = this.props
    const initData = data.map(datum => ({
      ...datum,
      value: 0,
    }))

    const wrapperWidth = this.chartEl.offsetWidth
    const wrapperHeight = wrapperWidth * 0.2
    const margin = {
      top: 2,
      left: 0,
      right: 0,
      bottom: 2,
    }
    const width = wrapperWidth - (margin.left + margin.right)
    const height = wrapperHeight - (margin.top + margin.bottom)

    // #region x scale
    const xScale = this.getXScale(data, width)
    // #endregion x scale

    // #region y scale
    const yScale = this.getYScale(data, height)
    // #endregion y scale

    // #region line
    const line = this.getLine(xScale, yScale)
    // #endregion line

    // #region area
    const area = this.getArea(xScale, yScale)
    // #endregion area

    this.svg = d3
      .select(this.chartEl)
      .append('svg')
      .attr('width', wrapperWidth)
      .attr('height', wrapperHeight)

    this.chart = this.svg
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)

    // #region line
    this.line = this.chart
      .append('path')
      .datum(data)
      .attr('class', 'chartLine')
      .attr('fill', 'none')
      .attr('stroke', stroke)
      .attr('stroke-width', 3)
      .attr('d', line)
    // #endregion line

    // #region area
    this.area = this.chart
      .append('path')
      .datum(initData)
      .attr('class', 'chartArea')
      .attr('fill', fill)
      .attr('stroke', 'none')
      .attr('d', area)
    // #endregion area
  }

  updateChart = resizeUpdate => {
    if (!this.chartEl) return

    const { data } = this.props

    const durration = this.transitionDuration

    const wrapperWidth = this.chartEl.offsetWidth
    const wrapperHeight = wrapperWidth * 0.2
    const margin = {
      top: 2,
      left: 0,
      right: 0,
      bottom: 2,
    }
    const width = wrapperWidth - (margin.left + margin.right)
    const height = wrapperHeight - (margin.top + margin.bottom)

    // #region x scale
    const xScale = this.getXScale(data, width)
    // #endregion x scale

    // #region y scale
    const yScale = this.getYScale(data, height)
    // #endregion y scale

    // #region line
    const line = this.getLine(xScale, yScale)
    // #endregion line

    // #region area
    const area = this.getArea(xScale, yScale)
    // #endregion area

    const pathTransition = path => {
      path
        .transition()
        .duration(durration * 4)
        .attrTween('stroke-dasharray', tweenDash(path.node()))
    }

    const tweenDash = node => {
      const l = node.getTotalLength()
      const i = () => d3.interpolateString(`0,${l}`, `${l},${l}`)
      return t => i(t)
    }

    this.svg
      .transition()
      .duration(durration)
      .attr('width', wrapperWidth)
      .attr('height', wrapperHeight)

    // #region line
    this.line
      .datum(data)
      .transition()
      .duration(durration)
      .attr('d', line)

    !resizeUpdate &&
      this.line
        .datum(data)
        .attr('d', line)
        .call(pathTransition)
    // #endregion line

    // #region area
    this.area
      .datum(data)
      .transition()
      .duration(durration)
      .attr('d', area)
    // #endregion area
  }

  getLine = (xScale, yScale) =>
    d3
      .area()
      .x(datum => xScale(datum.date))
      .y(datum => yScale(datum.value))

  getArea = (xScale, yScale) =>
    d3
      .area()
      .x(datum => xScale(datum.date))
      .y0(yScale(0) + 3)
      .y1(datum => yScale(datum.value))

  getXScale = (data, width) =>
    d3
      .scaleTime()
      .range([0, width])
      .domain(d3.extent(data, datum => datum.date))

  getYScale = (data, height) =>
    d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, d3.max(data, datum => datum.value)])

  render() {
    return (
      <Chart
        ref={el => {
          this.chartEl = el
        }}
      />
    )
  }
}

BasicLineChart.propTypes = {
  fill: PropTypes.string.isRequired,
  stroke: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.number.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
}

export default BasicLineChart
