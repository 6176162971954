import styled from 'styled-components/macro'

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 980;
  margin: 0 auto;
`

export default Wrapper

export const TooltipWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`

export const CountriesMapTooltip = styled.div`
  min-width: 40px;

  & > h3 {
    font-weight: bold;
    font-family: 'Open Sans';
    font-size: 16px;
    margin: 0;
    padding: 0;
  }

  & > h4 {
    font-weight: 400;
    font-family: 'Open Sans';
    font-size: 16px;
    margin: 0;
    padding: 0;
  }

  & > p {
    /* color: ${props => props.theme.cGrey2}; */
    color: #9b9b9b;
    font-family: 'Open Sans';
    font-size: 14px;
    line-height: 20px;
  }
`
