import styled, { css } from 'styled-components/macro'
import { Row } from 'components/AdminDashboard/Grid/Grid.styles'
import rwd from 'styles/rwd'

// eslint-disable-next-line import/prefer-default-export
export const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  > div > ${Row} {
    flex-grow: 1;
    transition: 0.3s padding-left;
    padding-left: 0;
  }

  ${rwd.MLarge`
    padding-left: 100px;
    transition: .3s padding;

    ${(props) =>
      props.isSidebarExpanded &&
      css`
        > div > ${Row} {
          padding-left: 200px;
        }
      `}
  `}
`
