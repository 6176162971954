import styled from 'styled-components/macro'
import Text from 'components/Text/Text.styles'

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 22px 0;

  & + & {
    border-top: 1px solid ${props => props.theme.cLightGrey};
  }

  ${Text} {
    p {
      font-size: 14px;
    }
  }
`

export const UserAvatar = styled.img`
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 18px;
`
