import styled from 'styled-components/macro'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const ContentRow = styled.div`
  display: flex;
  align-items: center;
`

export const ContentCol = styled.div``

export const BottomSlot = styled.div`
  width: 100%;
  flex-shrink: 0;
  padding-top: 6px;
`

export const RightSlot = styled.div`
  flex-shrink: 0;
  padding-left: 4px;

  ${props =>
    props.rightAlign &&
    `
    margin-left: auto;
  `}
`

export const Title = styled.div`
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
`

export const Content = styled.div`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.6px;
  font-weight: 600;
`

export const RightSlotText = styled.div`
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.6px;
  font-weight: 600;
`

export const ContentCenter = styled.div`
  display: flex;
  margin-top: 4px;
`

export const ColorBoxWrapper = styled.div`
  margin-left: 12px;
`
