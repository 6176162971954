import styled from 'styled-components/macro'
import Text from 'components/Text/Text.styles'

// eslint-disable-next-line import/prefer-default-export
export const ContainerWithLine = styled(Text)`
  position: relative;
  padding-left: 48px;

  &::before {
    content: '';
    width: 6px;
    height: 72px;
    background: ${(props) => props.theme.cPrimary};
    position: absolute;
    left: 0;
    top: 6px;
  }
`
export const RedBar = styled.div`
  background: ${(props) => props.theme.cWarning};
  bottom: 0;
  height: 30%;
  position: absolute;
  right: 0;
  width: 50%;
  display: flex;
  align-items: center;

  @media (max-width: 550px) {
    width: 100%;
    bottom: -30%;
  }
`
