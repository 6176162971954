import NotFoundPage from 'pages/404'
import AdminDashboardRoutes from './AdminDashboard'

const routes = [
  ...AdminDashboardRoutes,
  {
    path: '*',
    component: <NotFoundPage />,
  },
]

export default routes
