import React, { PureComponent } from 'react'
import Layout from 'components/AdminDashboard/Layout/Layout'
// import SEO from 'components/SEO/SEO'
import {
  Row,
  Column,
  ContentBox,
} from 'components/AdminDashboard/Grid/Grid.styles'
import { Modal, ModalInner, ModalInnerWithBorder } from 'components/Modal/Modal'
import Button from 'components/Button/Button.styles'
import Text from 'components/Text/Text.styles'

class AdminRequestUpdate extends PureComponent {
  state = {
    isModalWithTitleOpen: true,
  }

  onModalWithTitleOpen = () => {
    this.setState({
      isModalWithTitleOpen: true,
    })
  }

  onModalWithTitleClose = () => {
    this.setState({
      isModalWithTitleOpen: false,
    })
  }

  render() {
    const { isModalWithTitleOpen } = this.state

    return (
      <Layout currentPage="updates">
        {/* <SEO
          title="Admin Dashboard - request update"
          keywords={['gatsby', 'application', 'react']}
        /> */}
        <Row mainDashboard>
          <Column>
            <ContentBox>
              <Button onClick={this.onModalWithTitleOpen}>POPUP</Button>
              <Modal
                isOpen={isModalWithTitleOpen}
                onClose={this.onModalWithTitleClose}
                tiny
              >
                <ModalInner center>
                  <Text>
                    <h3>New update requested</h3>
                    <ModalInnerWithBorder>
                      <p>
                        {
                          'You have requested new update and STOKR admin will review it soon. You will receive a notification when the status of your update changes.'
                        }
                      </p>
                    </ModalInnerWithBorder>
                  </Text>
                </ModalInner>
              </Modal>
            </ContentBox>
          </Column>
        </Row>
      </Layout>
    )
  }
}

export default AdminRequestUpdate
