import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
`

export const HeadWrapper = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  background-color: ${(props) => props.theme.cWhite};
`

export const TableDropdownIcon = styled.i.attrs({
  className: 'ion ion-ios-arrow-down',
})`
  width: 14px;
  height: 20px;
  font-size: 20px;
  margin-top: -10px;
  transform: rotate(0);
  transition: 0.3s transform;
  cursor: pointer;
  ${(props) =>
    props.isActive &&
    `
      transform: rotate(180deg);
  `}
`

export const TableWrapper = styled.div`
  z-index: 0;
  position: relative;
  display: block;
`

export const Column = styled.div`
  display: table-cell;
  text-align: ${(props) => props.align};
  font-family: 'Open Sans';

  ${({ tokenHolder }) =>
    tokenHolder &&
    `
    padding: 10px 0 !important;
  `}

  ${(props) =>
    props.colWidth &&
    `
    width: ${props.colWidth}
    min-width: ${props.colWidth}
  `};

  ${(props) =>
    props.minWidth &&
    `
    min-width: ${props.minWidth}
  `};

  ${(props) =>
    props.maxWidth &&
    `
    width: ${props.maxWidth}
    max-width: ${props.maxWidth}
  `};

  ${(props) =>
    props.first &&
    `
    padding-left: 60px !important;
  `};
`

export const Row = styled.div`
  display: table-row;
  ${(props) =>
    props.isActive &&
    `
    ${TableDropdownIcon} {
      transform: rotate(180deg);
    }
  `}
`

export const Head = styled.div`
  display: table-header-group;

  ${(props) =>
    props.hidden &&
    `
    visibility: hidden;
  `}

  ${Row} {
    ${Column}:first-of-type {
      padding-left: 0;
    }

    ${Column}:last-of-type {
      padding-right: 0;
    }

    ${Column} {
      border-bottom: 1px solid ${(props) => props.theme.cLightGrey};
    }
  }

  ${Column} {
    color: ${(props) => props.theme.cProgress};
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1.98px;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
    padding: 10px 10px;
  }
`

export const Body = styled.div`
  display: table-row-group;

  ${Row} {
    ${Column}:first-of-type {
      padding-left: 0;
    }

    ${Column}:last-of-type {
      padding-right: 0;
    }

    & + ${Row} {
      ${Column} {
        border-top: 1px solid ${(props) => props.theme.cLightGrey};
      }
    }

    /* &:hover {
      ${Column} {
        background-color: ${(props) => props.theme.cGrey};
      }
    } */
  }

  ${Column} {
    color: ${(props) => props.theme.cBlack};
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    vertical-align: middle;
    padding: 26px 10px;
  }
`
export const SubRow = styled.div`
  display: table-row;

  ${Column} {
    border-bottom: 1px solid ${(props) => props.theme.cLightGrey};
  }

  ${(props) =>
    props.head &&
    `
   ${Column} {
    color: ${(props) => props.theme.cProgress};
    font-weight: 600 !important;
    font-size: 11px !important;
    line-height: 20px;
    letter-spacing: 1.98px;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap !important;
    padding: 10px 10px ;
  
  }
  
  `}
`
export const StyledTable = styled.div`
  display: table;
  min-width: 100%;
  & :nth-child {
    border-spacing: 20px 0;
  }
`

// TODO: move to separate file
export const TableCellType = styled.div`
  display: inline-block;
  background-color: ${(props) => props.color};
  color: ${(props) => props.theme.cWhite};
  font-weight: 600;
  min-width: 40px;
  line-height: 24px;
  border-radius: 3px;
  text-align: center;
`

export const Styles = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;

  table {
    th,
    td {
      /* The secret sauce */
      /* Each cell should grow equally */
      // width: 1%;
      /* But "collapsed" cells should be as small as possible */
      &.collapse {
        width: 0.0000000001%;
      }
    }
  }
`

export const TableWrap = styled.div`
  display: block;
  max-width: 100%;
  overflow-x: auto; //scroll;
  overflow-y: hidden;
`

export const StyledTh = styled.th`
  text-transform: uppercase;
  padding: 10px;
  font-size: 11px;
  letter-spacing: 1.6px;
  font-weight: 600;
  background-color: white;
  color: #000000de;
  border-bottom: 1px solid #e1e1e1;
  line-height: 20px;

  ${(props) =>
    props.blue &&
    `
      background-color: #0050ca;
      color: #ffffff;
      border:none;
  `}
`
export const StyledTd = styled.td`
  padding: 26px 10px;
  border-bottom: 1px solid #e1e1e1;
  font-size: 14px;
  ${(props) =>
    props.blue &&
    `
      background-color: #004bb7;
    color: #ffffff;
  `}

  ${(props) =>
    props.subTable &&
    `
     padding: 0;
      &:last-child {
        border-bottom: 0;
      }
  `}
`
export const StyledTr = styled.tr`
  // ${StyledTd}:first-of-type {
  //   padding-left: 0;
  // }

  ${StyledTd}:last-of-type {
    padding-right: 0;
  }
`

export const StyledReactTable = styled.table`
  width: 100%;
  border-spacing: 0;
`

StyledReactTable.Tr = StyledTr
StyledReactTable.Th = StyledTh
StyledReactTable.Td = StyledTd
