import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
`

export const Items = styled.div`
  text-align: center;
  font-size: 0;
  margin: -4px -16px;
`

export const Item = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding: 4px 16px;
  font-size: 0;
`

export const Bar = styled.div`
  z-index: 1;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  margin-right: -24px;
  height: 2px;
  border-radius: 1px;
  background-color: ${props => props.theme.cBlack};

  ${props =>
    props.barColor &&
    `
    background-color: ${props.barColor};
  `}
`

export const Label = styled.div`
  z-index: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-left: 44px;
  max-width: 100%;
  color: ${props => props.theme.cBlack};
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
`

export const Percent = styled.span`
  display: inline-block;
  font-weight: 300;
  margin-left: 4px;

  &:before {
    content: '(';
  }

  &:after {
    content: ')';
  }
`
