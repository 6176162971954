import React from 'react'
import PropTypes from 'prop-types'

import { StyledTabs, Scroll, TabsWrapper } from './ButtonTabs.styles'

import ButtonTab from './ButtonTab'

export { ButtonTab }

const ButtonTabs = ({ children, activeTab, marginTop }) => (
  <StyledTabs marginTop={marginTop}>
    <Scroll>
      <TabsWrapper>
        {React.Children.map(children, child => {
          if (!React.isValidElement(child)) return null
          return React.cloneElement(child, {
            ...child.props,
            isActive: child.props.tabId === activeTab,
          })
        })}
      </TabsWrapper>
    </Scroll>
  </StyledTabs>
)

ButtonTabs.propTypes = {
  children: PropTypes.node.isRequired,
  activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

ButtonTabs.defaultProps = {
  activeTab: 0,
}

export default ButtonTabs
