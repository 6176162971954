import styled from 'styled-components/macro'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'

const colorTheme = props => ({
  blue: {
    primary: props.theme.cPrimary,
    dark: props.theme.cPrimaryDark,
  },
  red: {
    primary: props.theme.cWarning,
    dark: props.theme.cWarningDark,
  },
})

const ChartContainer = styled(ComponentWrapper)`
  .slick-arrow {
    width: 64px;
    height: 48px;
    background: ${props => colorTheme(props)[props.colorTheme].primary};
    z-index: 10;
    transition: 0.3s ease background;
    margin-top: 10px;
    &:hover,
    &:focus {
      background: ${props => colorTheme(props)[props.colorTheme].dark};

      &::before {
        color: ${props => props.theme.cWhite} !important;
      }
    }
  }

  .slick-arrow.slick-prev {
    top: auto;
    left: auto;
    bottom: 0;
    right: 64px;
    transform: translateY(100%);
  }

  .slick-arrow.slick-next {
    top: auto;
    left: auto;
    bottom: 0;
    right: 0;
    transform: translateY(100%);
    border-left: 1px solid ${props => colorTheme(props)[props.colorTheme].dark};
  }
`

export default ChartContainer
