import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 0;
  border-top: 1px solid ${props => props.theme.cGrey};
`

export const StatusDot = styled.div`
  position: relative;
  width: 12px;
  height: 12px;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.2;
    background: ${props => props.theme.cSuccess};
    border-radius: 50%;
  }

  &::after {
    content: '';
    width: 6px;
    height: 6px;
    position: absolute;
    top: 3px;
    left: 3px;
    background: ${props => props.theme.cSuccess};
    border-radius: 50%;
  }
`

export const RedStatusDot = styled.div`
  position: relative;
  width: 12px;
  height: 12px;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: 0.2;
    background: ${props => props.theme.cWarning};
    border-radius: 50%;
  }

  &::after {
    content: '';
    width: 6px;
    height: 6px;
    position: absolute;
    top: 3px;
    left: 3px;
    background: ${props => props.theme.cWarning};
    border-radius: 50%;
  }
`

export const Message = styled.div`
  font-size: 14px;
  line-height: 19px;
  margin-left: 10px;
`

export const TooltipContainer = styled.div`
  margin-left: auto;
`

export const TooltipIcon = styled.i.attrs({
  className: 'ion ion-md-alert',
})`
  font-size: 18px;
  opacity: 0.2;
`
