import styled from 'styled-components/macro'

const NotificationCounter = styled.span`
  display: inline-block;
  vertical-align: middle;
  color: ${props => props.theme.cWhite};
  background-color: ${props => props.theme.cWarning};
  border-radius: 8px;
  min-width: 16px;
  height: 16px;
  font-size: 9px;
  line-height: 11px;
  padding: 2px;
  text-align: center;

  ${props =>
    props.avatar &&
    `
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  `}
`

export default NotificationCounter
