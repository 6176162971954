const currencyFormatOrder = {
  NumberFirst: 0,
  SymbolFirst: 1,
}

const currencyFormatInfos = {
  tether: ['USDT', 2, currencyFormatOrder.NumberFirst],
  gemini: ['GUSD', 2, currencyFormatOrder.NumberFirst],
  ether: ['ETH', 4, currencyFormatOrder.NumberFirst],
  artid: ['ARTID', 2, currencyFormatOrder.NumberFirst],
  bitcoin: ['BTC', 6, currencyFormatOrder.NumberFirst],
  lbtc: ['LBTC', 8, currencyFormatOrder.NumberFirst],
  'lusdt-bfx': ['USDT', 2, currencyFormatOrder.NumberFirst],
  'lbtc-bfx': ['LBTC', 6, currencyFormatOrder.NumberFirst],
  'btc-bfx': ['BTC', 6, currencyFormatOrder.NumberFirst],
  euro: ['€', 2, currencyFormatOrder.SymbolFirst],
  lusdt: ['USDT', 2, currencyFormatOrder.NumberFirst],
  'bitcoin-private': ['€', 2, currencyFormatOrder.SymbolFirst],
  'lbtc-private': ['LBTC', 6, currencyFormatOrder.NumberFirst],
  'btc-private': ['BTC', 6, currencyFormatOrder.NumberFirst],
  bmn1: ['BMN1', 2, currencyFormatOrder.SymbolFirst],
}

export const formatCurrencyValue = (currency, value) => {
  const [symbol, decimals, order] = currencyFormatInfos[currency] || [
    currency,
    2,
    currencyFormatOrder.NumberFirst,
  ]

  return order === currencyFormatOrder.SymbolFirst
    ? `${symbol} ${parseFloat(value).toFixed(decimals)}`
    : `${parseFloat(value).toFixed(decimals)} ${symbol}`
}

export const getSymbol = (currency) => currencyFormatInfos[currency][0]

export default formatCurrencyValue
