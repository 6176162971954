import React from 'react'

const UpdateDefaultSvg = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76"
    width="76"
    height="76"
  >
    <g transform="translate(-266 -956)">
      <path d="m330.62 1020.5c-2.893 2.894-6.13 5.245-9.621 6.988a1.537 1.537 0 1 1-1.374-2.75c3.194-1.595 6.162-3.752 8.822-6.412 13.527-13.527 13.527-35.537 0-49.064-13.528-13.527-35.537-13.527-49.064 0-13.528 13.527-13.528 35.537 0 49.064 6.552 6.553 15.265 10.162 24.531 10.162a1.537 1.537 0 0 1 0 3.074c-10.088 0-19.572-3.928-26.706-11.062-14.725-14.726-14.725-38.686 0-53.412 7.363-7.363 17.035-11.044 26.706-11.044 9.672 0 19.343 3.681 26.706 11.044 14.726 14.726 14.726 38.686 0 53.412z" />
      <path d="m303.92 1018.2a1.537 1.537 0 0 1-1.537-1.537v-45.821a1.537 1.537 0 0 1 3.075 0v45.822c0 0.85-0.689 1.537-1.538 1.537z" />
      <path d="m310.07 992.21h16.762a1.537 1.537 0 0 1 0 3.074h-16.762a1.537 1.537 0 0 1 0-3.074z" />
      <path d="m281.01 995.29a1.537 1.537 0 0 1 0-3.074h16.25a1.537 1.537 0 0 1 0 3.074z" />
    </g>
  </svg>
)

export default UpdateDefaultSvg
