import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Button from 'components/Button/Button.styles'

import {
  Container,
  Row,
  Column,
  Avatar,
  AvatarImg,
  Name,
  StatusBox,
  ProfessionalInvCircle,
} from './InvestorsTable.styles'
import Icon, { iconsMap } from 'components/Icon/Icon.style'
import moment from 'moment'
import InvestorsDetails from 'components/InvestorsDetails/InvestorsDeatils'
import { Collapse } from 'react-collapse'
import { Tooltip } from 'react-tippy'
import { capitalize } from 'utils/capitalize'

const InvestorsTable = ({ data, sort }) => {
  //we store the item id of the dropdown menu open
  const [dropdownOpen, setDropdown] = useState('')

  return (
    <Container>
      <Row head>
        <Column width="30%" onClick={() => sort('email')} clickable>
          Email <i className="fa-solid fa-sort" ml={2}></i>
        </Column>
        <Column width="18%" onClick={() => sort('name')} clickable>
          Name <i className="fa-solid fa-sort" ml={2}></i>
        </Column>
        <Column width="18%" onClick={() => sort('country')} clickable>
          Country <i className="fa-solid fa-sort" ml={2}></i>
        </Column>
        <Column width="14%" onClick={() => sort('createdAt')} clickable>
          Created at <i className="fa-solid fa-sort"></i>
        </Column>
        <Column width="12%" onClick={() => sort('status')} clickable>
          Status <i className="fa-solid fa-sort"></i>
        </Column>

        <Column width="8%" />
      </Row>
      {data.map((item) => (
        <div key={item._id}>
          <Row key={item.id} borderBottom>
            <Column nameBox width="30%">
              <Tooltip
                position="top"
                title={capitalize(
                  item.professional_investor_status ?? 'not classified',
                )}
                theme="transparent"
                arrow
                duration={200}
              >
                <ProfessionalInvCircle
                  type={item.professional_investor_status ?? 'not classified'}
                />
              </Tooltip>

              <Name>{item.email}</Name>
            </Column>

            <Column marginTop width="18%">
              <Name small noMargin>
                {item.name}
              </Name>
            </Column>

            <Column marginTop width="18%">
              <Name small noMargin>
                {item?.country ?? 'Country Unknown'}
              </Name>
            </Column>

            <Column marginTop balanceText width="14%">
              {moment(item.createdAt).format('DD MMM YYYY').toUpperCase()}
            </Column>

            <Column width="12%" marginTop>
              <StatusBox state={item.status}>{item.status}</StatusBox>
            </Column>

            <Column width="8%" icon marginTop>
              <Button
                outline
                onClick={() => item.onEditClick(item)}
                style={{ padding: 10 }}
              >
                <Icon icon={iconsMap.edit} style={{ fontSize: 22 }} />
              </Button>
              <Icon
                onClick={() => {
                  setDropdown(dropdownOpen === item._id ? '' : item._id)
                }}
                icon={
                  dropdownOpen === item._id
                    ? iconsMap.arrowUp
                    : iconsMap.arrowDown
                }
                style={{ fontSize: 15, cursor: 'pointer', padding: 8 }}
              />
            </Column>
          </Row>
          <Collapse isOpened={dropdownOpen === item._id}>
            <InvestorsDetails
              title="STOKR Account"
              states={item.states}
              address={item.wallets ? item.wallets[0] : ''}
              data={{
                value0: 'Wallet',
                value1: 'KYC/AML',
                value2: 'Risk Quest',
                value3: 'Tax ID',
                notes: item.notes,
              }}
              borderTop
              showTaxId={item.requiresTaxId}
            />
          </Collapse>
        </div>
      ))}
    </Container>
  )
}

InvestorsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      avatar: PropTypes.string,
      email: PropTypes.string,
      createdAt: PropTypes.string,
      status: PropTypes.string,
      onEditClick: PropTypes.func.isRequired,
    }),
  ).isRequired,
  sort: PropTypes.func.isRequired,
}

export default InvestorsTable
