import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'

import {
  Container,
  Column,
  Value,
  Icon,
  ValueContainer,
} from './InvestorsDetails.styles'
import { InvestorStatusIcons } from 'pages/admin-dashboard/private-investor'
import { Address } from 'components/CryptoAddress/CryptoAddress.styles'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'

const getShortAddress = (address) =>
  `${address.slice(0, 6)}[...]${address.slice(-4)}`

const InvestorsDetails = ({
  title,
  address,
  data,
  borderTop,
  borderBottom,
  states,
  showTaxId,
}) => (
  <Container>
    <ComponentWrapper borderTop={borderTop} borderBottom={borderBottom}>
      <Column>
        <ValueContainer>
          <Value>{title}</Value>
          <Icon
            icon={InvestorStatusIcons[states.account]}
            state={states.account}
          />
        </ValueContainer>

        <ValueContainer>
          <Value>{data.value0}</Value>
          <Icon
            icon={InvestorStatusIcons[states.wallet]}
            state={states.wallet}
          />
        </ValueContainer>
      </Column>

      <Column marginTop>
        <ValueContainer>
          <Value>{data.value1}</Value>
          <Icon icon={InvestorStatusIcons[states.kyc]} state={states.kyc} />
        </ValueContainer>

        <ValueContainer>
          <Value>{data.value2}</Value>
          <Icon
            icon={InvestorStatusIcons[states.risk_quest]}
            state={states.risk_quest}
          />
        </ValueContainer>
      </Column>

      <Column marginTop>
        {/* <ValueContainer>
          <Value>{data.value3}</Value>
        </ValueContainer> */}

        {showTaxId && (
          <ValueContainer>
            <Value>{data.value3}</Value>
            <Icon
              icon={InvestorStatusIcons[states.taxId]}
              state={states.taxId}
            />
          </ValueContainer>
        )}
      </Column>

      {data.notes && (
        <Column marginTop>
          <Value notes>Notes: {data.notes}</Value>
        </Column>
      )}
    </ComponentWrapper>
  </Container>
)

const dataShape = PropTypes.shape({
  value1: PropTypes.string.isRequired,
  value2: PropTypes.string.isRequired,
  notes: PropTypes.string,
})

InvestorsDetails.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string,
  borderTop: PropTypes.bool,
  borderBottom: PropTypes.bool,
  data: dataShape,
  //states:
}

InvestorsDetails.defaultProps = {
  borderTop: false,
  borderBottom: false,
  states: {},
  address: '',
  data: {
    value1: '',
    value2: '',
    notes: '',
    value0: '',
  },
}

export default InvestorsDetails
