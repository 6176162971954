import styled from 'styled-components/macro'
import rwd from 'styles/rwd'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 0;

  ${props => {
    if (!props.mobileRatio) return ''
    const ratio = props.mobileRatio.split(':')
    const percent = (100 * ratio[1]) / ratio[0]

    return `
      &:after {
        z-index: 0;
        content: '';
        position: relative;
        display: inline-block;
        vertical-align: top;
        width: 100%;
        height: 100%;
        padding-top: ${percent}%;
        margin-left: -100%;
      }
    `
  }}

  &:after {
    ${rwd.Medium`
      display: none;
    `}
  }
`

export const Wrap = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  & > div {
    height: 100%;
  }
`

export const Img = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`

export const Video = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
`

export const Image = styled.img`
  /* Make video to at least 100% wide and tall */
  min-width: 100%;
  min-height: 100%;

  /* Setting width & height to auto prevents the browser from stretching or squishing the video */
  width: auto;
  height: auto;

  /* Center the video */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const Content = styled.div`
  z-index: 2;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 100%;
  font-size: 10px;
`
