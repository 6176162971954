import React from 'react'
import PropTypes from 'prop-types'

import { Modal, ModalInner } from 'components/Modal/Modal'
import Text from 'components/Text/Text.styles'
import DoubleButtons from 'components/DoubleButton/DoubleButton.styles'
import Button from 'components/Button/Button.styles'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'

const AlertModal = ({
  isOpen,
  onModalClose,
  onButtonClick,
  warningText,
  buttonText,
}) => (
  <Modal thin isOpen={isOpen} onClose={onModalClose}>
    <ModalInner>
      <Text>
        <h5>{warningText}</h5>
      </Text>
      <ComponentWrapper noPaddingHorizontal noPaddingBottom>
        <DoubleButtons>
          <Button secondary onClick={onModalClose}>
            Cancel
          </Button>
          <Button primary onClick={onButtonClick}>
            {buttonText}
          </Button>
        </DoubleButtons>
      </ComponentWrapper>
    </ModalInner>
  </Modal>
)

AlertModal.propTypes = {
  isOpen: PropTypes.bool,
  onModalClose: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  warningText: PropTypes.string,
  buttonText: PropTypes.string,
}

AlertModal.defaultProps = {
  isOpen: false,
  warningText: '',
  buttonText: '',
}

export default AlertModal
