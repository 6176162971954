import React, { PureComponent } from 'react'
import { ProjectContext } from 'context/ProjectContext/ProjectContext'
import Layout from 'components/AdminDashboard/Layout/Layout'
// import SEO from 'components/SEO/SEO'
import {
  Row,
  Column,
  ContentBox,
  RowWrapper,
} from 'components/AdminDashboard/Grid/Grid.styles'
import {
  Card,
  CardHeader,
  CardTitle,
} from 'components/AdminDashboard/Card/Card.styles'
import ButtonTabs, {
  ButtonTab,
} from 'components/AdminDashboard/ButtonTabs/ButtonTabs'
import Text from 'components/Text/Text.styles'
//import LineChart from 'components/AdminDashboard/LineChart/LineChart'
import BasicLineChart from 'components/AdminDashboard/BasicLineChart/BasicLineChart'
import BasicLineChartInfo from 'components/AdminDashboard/BasicLineChartInfo/BasicLineChartInfo'
import { BasicLineChartWrapper } from 'components/AdminDashboard/BasicLineChart/BasicLineChart.styles'
/*
import {
  LineChartWraper,
  LineChartTooltip,
} from 'components/AdminDashboard/LineChart/LineChart.styles'
*/
//import OverviewItem from 'components/AdminDashboard/OverviewItem/OverviewItem'
import Table from 'components/AdminDashboard/Table/Table'
//import { TableCellType } from 'components/AdminDashboard/Table/Table.styles'
import Tabs, { Tab } from 'components/Tabs/Tabs'
import Country from 'components/AdminDashboard/Country/Country'
import Heatmap from 'components/AdminDashboard/Heatmap/Heatmap'
import Select from 'components/Input/Select'
import { SelectWrapper } from 'components/Input/Select.styles'
//import SingleProgressBar from 'components/AdminDashboard/SingleProgressBar/SingleProgressBar'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
import BarChart from 'components/BarChart/BarChart'
import { BarChartTooltip } from 'components/BarChart/BarChart.styles'
import BasicDonutChart from 'components/AdminDashboard/BasicDonutChart/BasicDonutChart'
import BasicDonutChartLegend from 'components/AdminDashboard/BasicDonutChartLegend/BasicDonutChartLegend'
import BasicTable from 'components/BasicTable/BasicTable'
import {
  TableHeaderWithIcon,
  TableHeaderWithIconIco,
} from 'components/AdminDashboard/TableHeaderWithIcon/TableHeaderWithIcon.styles'
import { WrapButton } from 'components/AdminDashboard/WrapButton/WrapButton.styles'
import Analytic from 'components/AnalyticGraphs/Analytic'
import Slider from 'components/Slider/Slider'
import ChartContainer from 'components/AnalyticGraphs/Analytic.styles'
import OverviewItem from 'components/AdminDashboard/OverviewItem/OverviewItem'
import {
  LineChartTooltip,
  LineChartWraper,
} from 'components/AdminDashboard/LineChart/LineChart.styles'
import LineChart from 'components/AdminDashboard/LineChart/LineChart'
import { Modal, ModalInner } from 'components/Modal/Modal'

const heatmapDaysLabel = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN']

const heatmapHoursLabel = [
  '12AM',
  '1AM',
  '2AM',
  '3AM',
  '4AM',
  '5AM',
  '6AM',
  '7AM',
  '8AM',
  '9AM',
  '10AM',
  '11AM',
  '12PM',
  '1PM',
  '2PM',
  '3PM',
  '4PM',
  '5PM',
  '6PM',
  '7PM',
  '8PM',
  '9PM',
  '10PM',
  '11PM',
]

const heatmapRange = {
  colors: ['#EFEFEF', '#0050CA'],
  steps: [0, 100],
}

const monthsLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

const tableColumnsCountry = [
  {
    key: 'country',
    label: 'Country',
    minWidth: '180px',
    maxWidth: '240px',
  },
  {
    key: 'visits',
    label: 'Visits',
  },
  {
    key: 'unique_visitors',
    label: 'Unique visitors',
  },
  {
    key: 'bounce_rate',
    label: 'Bounce rate',
    width: '200px',
  },
]

/*
const tableColumnsDownload = [
  {
    key: 'document_name',
    label: 'Document',
  },
  {
    key: 'type',
    label: 'Type',
    width: '80px',
    align: 'center',
  },
  {
    key: 'downloads',
    label: 'Downloads',
    align: 'right',
  },
]

const tableDataDownload = [
  {
    id: '01',
    document_name: 'Financial raport',
    type: 'PDF',
    color: '#EE220D',
    downloads: '4182',
  },
  {
    id: '02',
    document_name: 'Sales analysis',
    type: 'XLS',
    color: '#2D7343',
    downloads: '3182',
  },
  {
    id: '03',
    document_name: 'White paper',
    type: 'DOC',
    color: '#35569E',
    downloads: '3182',
  },
]

const tableColumnsSocialMedia = [
  {
    key: 'network',
    label: 'Network',
    width: '100px',
  },
  {
    key: 'visitors',
    label: 'Visitors',
    width: '90px',
  },
  {
    key: 'progress',
    label: '',
    minWidth: '50px',
  },
]
*/

const createMonthsBarChartTooltip = (value) => (
  <BarChartTooltip>
    <h3>{value.toLocaleString()} Unique Visitors</h3>
  </BarChartTooltip>
)

const createLineChartTooltip = (value) => (
  <LineChartTooltip>
    <h3>
      {'€ '}
      {value.toLocaleString()}
    </h3>
    <h4>Revenue</h4>
  </LineChartTooltip>
)

class AdminAnalytics extends PureComponent {
  static contextType = ProjectContext

  analytics = null
  state = {
    tokenSalesHistoryPeriod: 'all',
    deviceTab: 'android',
    basicStats: [],
    countryData: [],
    socialData: [],
    donutChart: [],
    deviceData: [],
    desktopBrowsers: [],
    iosModels: [],
    androidModels: [],
    monthsBarChart: {
      months: [],
      years: [],
      weeks: [],
    },
    heatmapData: [],
    period: '1m',
    closedModalOnce: false,
    openModal: false,
    dataLoaded: false,
  }

  async componentWillMount() {
    const { project } = this.context
    this.analytics = new Analytic(project)

    await this.setBasicStatsGraphs()
    await this.setCountryGraph()
    await this.setSocialNetworksGraph()
    await this.setDevicesGraph()
    await this.setMonthSlider()
    await this.setHeatmap()
    this.setState({ dataLoaded: true })
  }

  componentDidUpdate() {
    const { countryData, desktopBrowsers, heatmapData, dataLoaded } = this.state

    //in some cases the adblockers on browser blocks the call to matomo (analytics data)
    //we want to open a modal to warn users to disable any adblockers

    //we check if the data is empty for some states (that usually are not empty)
    if (
      !countryData.length > 0 &&
      !heatmapData.length > 0 &&
      !desktopBrowsers.length > 0 &&
      dataLoaded
    ) {
      this.setState({ openModal: true })
    } else {
      this.setState({ openModal: false })
    }
  }

  setTokenSalesHistoryPeriod = (period) => {
    this.setState({
      tokenSalesHistoryPeriod: period,
    })
  }

  setDeviceTab = (tabId) => {
    this.setState({
      deviceTab: tabId,
    })
  }

  async setBasicStatsGraphs() {
    const realTimeGraph = await this.analytics.getRealTimeUsersBasicGraph()
    const basicGraphs = await this.analytics.getBasicGraphs()
    this.setState({
      basicStats: [realTimeGraph, ...basicGraphs],
    })
  }

  async setCountryGraph() {
    const countryData = await this.analytics.getCountryGraph()
    this.setState({ countryData })
  }

  async setSocialNetworksGraph() {
    const socialData = await this.analytics.getSocialGraph()
    this.setState({ socialData })
  }

  async setDevicesGraph() {
    const devicesTmpData = await this.analytics.getDeviceGraph()

    this.setState({
      donutChart: devicesTmpData.donutChart,
      deviceData: devicesTmpData.deviceData,
      desktopBrowsers: devicesTmpData.desktopBrowsers,
      androidModels: devicesTmpData.androidModels,
      iosModels: devicesTmpData.iosModels,
    })
  }

  async setMonthSlider() {
    const months = await this.analytics.getMonthSlider()
    const years = await this.analytics.getYearSlider()
    const weeks = await this.analytics.getWeekSlider()
    this.setState({
      monthsBarChart: { months, years, weeks },
    })
  }

  async setHeatmap() {
    const heatmapData = await this.analytics.getHeatmap()
    this.setState({ heatmapData })
  }

  render() {
    const {
      tokenSalesHistoryPeriod,
      deviceTab,
      basicStats,
      countryData,
      //socialData,
      donutChart,
      deviceData,
      desktopBrowsers,
      androidModels,
      iosModels,
      monthsBarChart,
      heatmapData,
      period,
      closedModalOnce,
      openModal,
    } = this.state

    // monthsBarChart && monthsBarChart.months.reverse()

    return (
      <Layout currentPage="analytics">
        {/* <SEO
          title="Admin Dashboard - token holder"
          keywords={['gatsby', 'application', 'react']}
        /> */}
        <Row mainDashboard>
          <Column>
            <ContentBox>
              <Row>
                <Column>
                  <Card>
                    <CardHeader>
                      <Text>
                        <h3>ANALYTICS</h3>
                      </Text>
                      <ButtonTabs activeTab={tokenSalesHistoryPeriod}>
                        {/* <ButtonTab
                          tabId="all"
                          onClick={() => {
                            this.setTokenSalesHistoryPeriod('all')
                          }}
                        >
                          All time
                        </ButtonTab>

                        <ButtonTab
                          tabId="lw"
                          onClick={() => {
                            this.setTokenSalesHistoryPeriod('lw')
                          }}
                        >
                          Last week
                        </ButtonTab>
                        <ButtonTab
                          tabId="lm"
                          onClick={() => {
                            this.setTokenSalesHistoryPeriod('lm')
                          }}
                        >
                          Last month
                        </ButtonTab> */}
                      </ButtonTabs>
                    </CardHeader>
                  </Card>
                </Column>

                {/* <Column>
                  <Card>
                    <Row>
                      <Column auto>
                        <OverviewItem
                          title="Total revenue"
                          content="€498.000,076"
                          boxColor="#69C858"
                          boxText="+91%"
                        />
                      </Column>

                      <Column auto>
                        <OverviewItem
                          title="Active investors"
                          content="3762"
                          boxColor="#EE220D"
                          boxText="-9.1%"
                        />
                      </Column>
                    </Row>
                    <LineChartWraper>
                      <LineChart
                        data={[
                          { date: 0, value: 15400 },
                          { date: 1, value: 33000 },
                          { date: 2, value: 24300 },
                          { date: 3, value: 21300 },
                          { date: 4, value: 32400 },
                          { date: 5, value: 11500 },
                          { date: 6, value: 39800 },
                          { date: 7, value: 33400 },
                          { date: 8, value: 21600 },
                          { date: 9, value: 19700 },
                          { date: 10, value: 31500 },
                          { date: 11, value: 12300 },
                        ].map(datum => ({
                          ...datum,
                          tooltip: createLineChartTooltip(datum.value),
                        }))}
                        months={monthsLabels}
                        id="LineChart2"
                        fill="#ceddf5"
                        stroke="#0050CA"
                      />
                    </LineChartWraper>
                  </Card>
                </Column> */}

                {basicStats.map((item) => (
                  <Column key={item.id} partMd={8} partLg={4}>
                    <Card chartPadding>
                      <BasicLineChartInfo
                        title={item.title}
                        value={item.value}
                        percent={item.percent}
                      />
                      <BasicLineChart
                        data={item.chart.data}
                        padding={40}
                        id={item.chart.id}
                        fill={item.chart.fill}
                        stroke={item.chart.stroke}
                      />
                    </Card>
                  </Column>
                ))}

                <Column>
                  <Row>
                    <Column partLg={16}>
                      <Card>
                        <CardHeader>
                          <ComponentWrapper noPaddingTop noPaddingHorizontal>
                            <CardTitle>Visited by country</CardTitle>
                          </ComponentWrapper>
                        </CardHeader>
                        <Table
                          columns={tableColumnsCountry}
                          data={countryData.map((row) => ({
                            ...row,
                            country: (
                              <Country
                                key={row.country}
                                countryAbbreviation={row.country}
                              >
                                {row.country_name}
                              </Country>
                            ),
                            bounce_rate: (
                              <RowWrapper>
                                <Row spaceBetween middle>
                                  <Column auto>{row.bounce_rate}</Column>
                                  <Column auto>
                                    <BasicLineChartWrapper>
                                      <BasicLineChart
                                        data={row.chart.data}
                                        padding={0}
                                        id={row.chart.id}
                                        fill={row.chart.fill}
                                        stroke={row.chart.stroke}
                                      />
                                    </BasicLineChartWrapper>
                                  </Column>
                                </Row>
                              </RowWrapper>
                            ),
                          }))}
                        />
                      </Card>
                    </Column>
                    {/*
                    <Column partLg={5}>
                      <Card lessPaddingHorizontal>
                        <CardHeader>
                          <ComponentWrapper noPaddingTop noPaddingHorizontal>
                            <CardTitle>Visitors from Social Media</CardTitle>
                          </ComponentWrapper>
                        </CardHeader>
                        <Table
                          columns={tableColumnsSocialMedia}
                          data={socialData.map(row => ({
                            ...row,
                            progress: (
                              <SingleProgressBar progress={row.progress} />
                            ),
                          }))}
                        />
                      </Card>
                    </Column>
                        */}
                  </Row>
                </Column>
                <Column>
                  <Row>
                    <Column>
                      <Card>
                        <CardHeader>
                          <CardTitle>{'Daily visitors '}</CardTitle>
                          <ButtonTabs activeTab={period}>
                            <ButtonTab
                              tabId="1y"
                              onClick={() => {
                                this.setState({ period: '1y' })
                              }}
                            >
                              1y
                            </ButtonTab>

                            <ButtonTab
                              tabId="1m"
                              onClick={() => {
                                this.setState({ period: '1m' })
                              }}
                            >
                              1m
                            </ButtonTab>
                            <ButtonTab
                              tabId="1w"
                              onClick={() => {
                                this.setState({ period: '1w' })
                              }}
                            >
                              1w
                            </ButtonTab>
                          </ButtonTabs>
                          <CardHeader />
                        </CardHeader>
                        <ChartContainer noPaddingHorizontal colorTheme="blue">
                          <Tabs activeTab={period}>
                            <Tab tabId="1y">
                              <Slider infinite={false} arrows>
                                {monthsBarChart.years.map((month, index) => (
                                  <BarChart
                                    id={`${index}BarChartYear`}
                                    colorTheme="blue"
                                    title={month.title}
                                    key={month.title}
                                    data={month.values.map((datum) => ({
                                      ...datum,
                                      tooltip: createMonthsBarChartTooltip(
                                        datum.value,
                                      ),
                                    }))}
                                  />
                                ))}
                              </Slider>
                            </Tab>
                            <Tab tabId="1m">
                              <Slider infinite={false} arrows>
                                {monthsBarChart.months.map((month, index) => (
                                  <BarChart
                                    id={`${index}BarChartMonth`}
                                    colorTheme="blue"
                                    title={month.title}
                                    key={month.title}
                                    data={month.values.map((datum) => ({
                                      ...datum,
                                      tooltip: createMonthsBarChartTooltip(
                                        datum.value,
                                      ),
                                    }))}
                                  />
                                ))}
                              </Slider>
                            </Tab>
                            <Tab tabId="1w">
                              <Slider infinite={false} arrows>
                                {monthsBarChart.weeks.map((month, index) => (
                                  <BarChart
                                    id={`${index}BarChartWeek`}
                                    colorTheme="blue"
                                    title={month.title}
                                    key={month.title}
                                    data={month.values.map((datum) => ({
                                      ...datum,
                                      tooltip: createMonthsBarChartTooltip(
                                        datum.value,
                                      ),
                                    }))}
                                  />
                                ))}
                              </Slider>
                            </Tab>
                          </Tabs>
                        </ChartContainer>
                      </Card>
                    </Column>
                  </Row>
                </Column>

                <Column>
                  <Row>
                    <Column partLg={11}>
                      <Row>
                        <Column>
                          <Card>
                            <CardHeader>
                              <ComponentWrapper
                                noPaddingTop
                                noPaddingHorizontal
                              >
                                <CardTitle>Sessions by devices</CardTitle>
                              </ComponentWrapper>
                            </CardHeader>
                            <Row>
                              {/* {donutChart && (
                                <Column partMd={3} fullMd={10}>
                                  <BasicDonutChart data={donutChart} />
                                </Column>
                              )} */}

                              <Column partMd={10} fullMd={10}>
                                <ComponentWrapper noPaddingHorizontal>
                                  <Row>
                                    <Column partSm={8}>
                                      <WrapButton
                                        onClick={() => {
                                          this.setDeviceTab('android')
                                        }}
                                      >
                                        <BasicDonutChartLegend
                                          color="#0050CA"
                                          label="Android"
                                          percent={`(${
                                            deviceData.Android
                                              ? deviceData.Android.percent
                                              : 0
                                          }%)`}
                                          value={`${
                                            deviceData.Android
                                              ? deviceData.Android.value
                                              : 0
                                          }`}
                                          smallBar
                                        />
                                      </WrapButton>
                                    </Column>

                                    <Column partSm={8}>
                                      <WrapButton
                                        onClick={() => {
                                          this.setDeviceTab('other')
                                        }}
                                      >
                                        <BasicDonutChartLegend
                                          color="#69BD53"
                                          label="Other"
                                          percent={`(${
                                            deviceData.Other
                                              ? deviceData.Other.percent
                                              : 0
                                          }%)`}
                                          value={`${
                                            deviceData.Other
                                              ? deviceData.Other.value
                                              : 0
                                          }`}
                                          smallBar
                                        />
                                      </WrapButton>
                                    </Column>

                                    <Column partSm={8}>
                                      <WrapButton
                                        onClick={() => {
                                          this.setDeviceTab('ios')
                                        }}
                                      >
                                        <BasicDonutChartLegend
                                          color="#CEDDF5"
                                          label="IOS"
                                          percent={`(${
                                            deviceData.iOS
                                              ? deviceData.iOS.percent
                                              : 0
                                          }%)`}
                                          value={`${
                                            deviceData.iOS
                                              ? deviceData.iOS.value
                                              : 0
                                          }`}
                                          smallBar
                                        />
                                      </WrapButton>
                                    </Column>

                                    <Column partSm={8}>
                                      <WrapButton
                                        onClick={() => {
                                          this.setDeviceTab('desktop')
                                        }}
                                      >
                                        <BasicDonutChartLegend
                                          color="#EE220D"
                                          label="Desktop"
                                          percent={`(${
                                            deviceData.Desktop
                                              ? deviceData.Desktop.percent
                                              : 0
                                          }%)`}
                                          value={`${
                                            deviceData.Desktop
                                              ? deviceData.Desktop.value
                                              : 0
                                          }`}
                                          smallBar
                                        />
                                      </WrapButton>
                                    </Column>
                                  </Row>
                                </ComponentWrapper>
                                <Tabs activeTab={deviceTab}>
                                  <Tab tabId="android">
                                    <TableHeaderWithIcon>
                                      <TableHeaderWithIconIco
                                        color="#aac148"
                                        className="ion ion-logo-android"
                                      />
                                      Android
                                    </TableHeaderWithIcon>
                                    <BasicTable
                                      data={
                                        androidModels.length > 0
                                          ? androidModels
                                          : []
                                      }
                                    />
                                  </Tab>

                                  <Tab tabId="other">
                                    <TableHeaderWithIcon>
                                      <TableHeaderWithIconIco
                                        color="#353535"
                                        className="ion ion-md-tablet-portrait"
                                      />
                                      Other
                                    </TableHeaderWithIcon>
                                  </Tab>

                                  <Tab tabId="ios">
                                    <TableHeaderWithIcon>
                                      <TableHeaderWithIconIco
                                        color="#000000"
                                        className="ion ion-logo-apple"
                                      />
                                      IOS
                                    </TableHeaderWithIcon>
                                    <BasicTable
                                      data={
                                        iosModels.length > 0 ? iosModels : []
                                      }
                                    />
                                  </Tab>

                                  <Tab tabId="desktop">
                                    <TableHeaderWithIcon>
                                      <TableHeaderWithIconIco
                                        color="#353535"
                                        className="ion ion-md-desktop"
                                      />
                                      Desktop
                                    </TableHeaderWithIcon>
                                    <BasicTable
                                      data={
                                        desktopBrowsers.length > 0
                                          ? desktopBrowsers
                                          : []
                                      }
                                    />
                                  </Tab>
                                </Tabs>
                              </Column>
                            </Row>
                          </Card>
                        </Column>
                        {/*
                        <Column>
                          <Card>
                            <CardHeader>
                              <ComponentWrapper
                                noPaddingTop
                                noPaddingHorizontal
                              >
                                <CardTitle>Track download</CardTitle>
                              </ComponentWrapper>
                            </CardHeader>
                            <Table
                              columns={tableColumnsDownload}
                              data={tableDataDownload.map(row => ({
                                ...row,
                                type: (
                                  <TableCellType color={row.color}>
                                    {row.type}
                                  </TableCellType>
                                ),
                              }))}
                            />
                          </Card>
                        </Column>
*/}
                      </Row>
                    </Column>

                    <Column partLg={5}>
                      <Card lessPaddingHorizontal>
                        <ComponentWrapper
                          noPaddingTop
                          noPaddingHorizontal
                          borderBottom
                        >
                          <CardTitle>Users by Time of Day</CardTitle>
                        </ComponentWrapper>
                        <ComponentWrapper
                          noPaddingHorizontal
                          paddingVeticalHalf
                        >
                          <SelectWrapper minWidth="120px" noMargin>
                            <Select
                              id="notification-type"
                              name="notificationType"
                              value="last90"
                              options={[
                                {
                                  key: 'last90',
                                  value: 'last90',
                                  label: 'All time',
                                },
                                /*
                                {
                                  key: 'last30',
                                  value: 'last30',
                                  label: 'Last 30 days',
                                },
                                */
                              ]}
                              onChange={() => {}}
                              onBlur={() => {}}
                            />
                          </SelectWrapper>
                        </ComponentWrapper>
                        <ComponentWrapper noPadding>
                          {heatmapData.length ? (
                            <Heatmap
                              hoursLabels={heatmapHoursLabel}
                              daysLabels={heatmapDaysLabel}
                              data={heatmapData}
                              range={heatmapRange}
                              width={236}
                              height={525}
                              padding={50}
                            />
                          ) : null}
                        </ComponentWrapper>
                      </Card>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </ContentBox>
          </Column>
        </Row>

        {!closedModalOnce && (
          <Modal
            thin
            isOpen={openModal}
            onClose={() =>
              this.setState({ openModal: false, closedModalOnce: true })
            }
          >
            <ModalInner>
              <Text>
                <h5>No data?</h5>
              </Text>
              <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                <Text>
                  <p>
                    If you don't see any analytics data, please disable any
                    adblocker on your browser.
                  </p>
                </Text>
              </ComponentWrapper>
            </ModalInner>
          </Modal>
        )}
      </Layout>
    )
  }
}

export default AdminAnalytics
