import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import ComponentScroll from 'components/ComponentScroll/ComponentScroll'

import {
  Container,
  HeadWrapper,
  TableWrapper,
  StyledTable,
  Head,
  Body,
  Row,
  Column,
  SubRow,
} from './Table.styles'

const OptionalScroll = ({ children, maxHeight, noScroll, onScroll }) => {
  if (noScroll) {
    return <Fragment>{children}</Fragment>
  }
  return (
    <ComponentScroll
      autoHeight={maxHeight !== 0}
      autoHeightMax={maxHeight || undefined}
      offset={4}
      barPadding={8}
      topPadding={48}
      handleScroll={onScroll}
    >
      {children}
    </ComponentScroll>
  )
}

OptionalScroll.propTypes = {
  children: PropTypes.node.isRequired,
  maxHeight: PropTypes.number.isRequired,
  noScroll: PropTypes.bool.isRequired,
  onScroll: PropTypes.func.isRequired,
}

class Table extends PureComponent {
  state = { activeIndex: null }
  componentDidMount() {
    this.resizeTimeout = setTimeout(this.setColumnsWidth, 200)
    window.addEventListener('resize', this.onResize)
  }

  componentWillUnmount() {
    clearTimeout(this.resizeTimeout)
    window.removeEventListener('resize', this.onResize)
  }

  onResize = () => {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(this.setColumnsWidth, 200)
  }

  setColumnsWidth = () => {
    const headColumns = this.headTableEl.children[0].children[0].children
    const bodyColumns = this.bodyTableEl.children[0].children[0].children

    Array.prototype.forEach.call(bodyColumns, (column, columnKey) => {
      bodyColumns[columnKey].style.width = ''
    })
    this.headTableEl.style.width = ''

    Array.prototype.forEach.call(bodyColumns, (column, columnKey) => {
      headColumns[columnKey].style.width = `${column.offsetWidth}px`
      bodyColumns[columnKey].style.width = `${column.offsetWidth}px`
    })
    this.headTableEl.style.width = `${this.bodyTableEl.offsetWidth}px`
  }

  onScroll = (e) => {
    if (!e) return
    this.headTableWrapperEl.scrollLeft = e.target.scrollLeft
  }

  render() {
    const {
      columns,
      data = [],
      maxHeight,
      noScroll,
      tokenHolder,
      onRowClick = () => {},
      subTable,
      subTableColumns,
    } = this.props
    const { activeIndex } = this.state

    const columnsProps = {}
    columns.map((column) => {
      columnsProps[column.key] = {
        width: column.width,
        align: column.align,
        marginLeft: column.marginLeft,
      }
      return column
    })
    const columnsPropsSubTable = {}
    subTableColumns &&
      subTableColumns.map((column) => {
        columnsPropsSubTable[column.key] = {
          width: column.width,
          align: column.align,
          marginLeft: column.marginLeft,
        }
        return column
      })

    const filterByInvestor = (dataToFilter, filterBy) => {
      return dataToFilter.filter(
        (x) => x.recipient === filterBy || x.sender === filterBy,
      )
    }

    return (
      <Container>
        <HeadWrapper
          ref={(el) => {
            this.headTableWrapperEl = el
          }}
        >
          <StyledTable
            ref={(el) => {
              this.headTableEl = el
            }}
          >
            <Head>
              <Row>
                {columns.map((column) => (
                  <Column
                    tokenHolder={tokenHolder}
                    key={column.key}
                    align={column.align}
                  >
                    {column.label}
                  </Column>
                ))}
              </Row>
            </Head>
          </StyledTable>
        </HeadWrapper>
        <TableWrapper>
          <OptionalScroll
            maxHeight={maxHeight}
            noScroll={noScroll}
            onScroll={this.onScroll}
          >
            <StyledTable
              ref={(el) => {
                this.bodyTableEl = el
              }}
            >
              <Head hidden>
                <Row>
                  {columns.map((column) => (
                    <Column
                      key={column.key}
                      colWidth={column.width}
                      minWidth={column.minWidth}
                      maxWidth={column.maxWidth}
                      align={column.align}
                    >
                      {column.label}
                    </Column>
                  ))}
                </Row>
              </Head>
              <Body>
                {data.map((row, index) => (
                  <>
                    <Row
                      key={row.id}
                      onClick={(e) => {
                        this.setState({
                          activeIndex: activeIndex === index ? null : index,
                        })
                        onRowClick(e)
                      }}
                      isActive={activeIndex === index}
                    >
                      {columns.map((column) => (
                        <>
                          <Column key={column.key} align={columns.align}>
                            {row[column.key]}
                          </Column>
                        </>
                      ))}
                    </Row>
                    {subTable && activeIndex === index && (
                      <>
                        <SubRow key={row.id} head>
                          {subTableColumns &&
                            subTableColumns.map((column, index) => (
                              <Column
                                key={column.key}
                                colWidth={column.width}
                                minWidth={column.minWidth}
                                maxWidth={column.maxWidth}
                                align={column.align}
                                //first={index === 0}
                              >
                                {column.label}
                              </Column>
                            ))}
                        </SubRow>
                        {filterByInvestor(subTable, row.account)
                          .sort((a, b) => new Date(b.time) - new Date(a.time))
                          .map((row, index) => (
                            <SubRow key={row.id}>
                              {Object.keys(row, index).map(
                                (columnKey) =>
                                  columnsPropsSubTable[columnKey] && (
                                    <>
                                      <Column
                                        //first={index === 0}
                                        key={columnKey}
                                        align={
                                          columnsPropsSubTable[columnKey].align
                                        }
                                      >
                                        {row[columnKey]}
                                      </Column>
                                    </>
                                  ),
                              )}
                            </SubRow>
                          ))}
                      </>
                    )}
                  </>
                ))}
              </Body>
            </StyledTable>
          </OptionalScroll>
        </TableWrapper>
      </Container>
    )
  }
}

Table.propTypes = {
  columns: PropTypes.arrayOf(Object).isRequired,
  data: PropTypes.arrayOf(Object).isRequired,
  maxHeight: PropTypes.number,
  noScroll: PropTypes.bool,
}

Table.defaultProps = {
  maxHeight: 500,
  noScroll: false,
}

export default Table
