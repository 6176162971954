/* eslint-disable no-unreachable */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'
import getAuthHeader from 'utils/getAuthHeader'

const CommentsContext = React.createContext()

const baseURL = process.env.REACT_APP_API_URL
const baseURLPublic = process.env.REACT_APP_BASE_URL_PUBLIC

export class CommentsProvider extends Component {
  state = {
    title: '',
    comments: [],
    currentComments: 10,
    take: 10,
    hasMoreComments: false,
    commentsCount: 0,
    likesCount: 0,
    topFiveContributors: [],
  }

  componentDidMount() {
    const { channelId } = this.props
    if (channelId) {
      this.getCommentsForThread()
    }
  }

  componentDidUpdate(prevProps) {
    const { channelId, threadId } = this.props
    if (channelId && threadId && prevProps.threadId !== threadId) {
      this.getCommentsForThread()
    }
  }

  getCommentsForThread = async () => {
    const { channelId, threadId } = this.props
    const response = await axios.post(
      `${baseURLPublic}/channel/public/getThread`,
      { channelId, threadId },
      { headers: getAuthHeader() },
    )
    this.onGetCommentsForThreadSuccess(response.data)
  }

  onGetCommentsForThreadSuccess = (data) => {
    const { currentComments } = this.state

    const commentsCount = this.getCommentsCount(data.thread.comments)
    const likesCount = this.getLikesCount(data.thread.comments)
    const topFiveContributors = this.getTopFiveContributors(
      data.thread.comments,
    )

    const sortedComments = data.thread.comments
      .slice()
      .map((comment) => {
        comment.comments.reverse()
        return comment
      })
      .reverse()

    this.setState({
      title: data.thread.text,
      comments: sortedComments,
      hasMoreComments: currentComments < data.thread.comments.length,
      commentsCount,
      likesCount,
      topFiveContributors,
    })
  }

  getFilteredComments = (searchBy, keyword) => {
    const { comments, currentComments } = this.state

    const pinnedComments = comments
      .filter((comment) => comment.isPinned)
      .sort((comment1, comment2) => comment1.priority > comment2.priority)

    const newComments = comments.filter((comment) => !comment.isPinned)

    newComments.unshift(...pinnedComments)

    const filteredComments = newComments.filter((comment) => {
      if (searchBy === 'user-name' && keyword.length !== 0) {
        // const isAuthor =
        //   !!comment.author.name &&
        //   !!comment.author.name.toLowerCase().match(keyword.toLowerCase()) ||

        let isAuthor = false
        if (
          !!comment.author.name &&
          !!comment.author.name.toLowerCase().match(keyword.toLowerCase())
        ) {
          isAuthor = true
        } else if (
          !!comment.author.username &&
          !!comment.author.username.toLowerCase().match(keyword.toLowerCase())
        ) {
          isAuthor = true
        }

        if (!isAuthor && comment.comments && comment.comments.length > 0) {
          return comment.comments.some(
            (subComment) =>
              !!subComment.author.name &&
              !!subComment.author.name
                .toLowerCase()
                .match(keyword.toLowerCase()),
          )
        }

        return isAuthor
      }

      if (searchBy === 'text-phrase') {
        const isText = !!comment.text.toLowerCase().match(keyword.toLowerCase())

        if (!isText && comment.comments && comment.comments.length > 0) {
          return comment.comments.some(
            (subComment) =>
              !!subComment.text.toLowerCase().match(keyword.toLowerCase()),
          )
        }

        return isText
      }

      return true
    })

    if (keyword.length) {
      return filteredComments
    }

    return filteredComments.slice(0, currentComments)
  }

  getCommentsCount = (comments) =>
    comments.reduce((prevValue, comment) => {
      let sum = 1

      if (comment.comments) {
        sum += comment.comments.reduce((previous) => previous + 1, 0) // comment.comments.length ?
      }

      return prevValue + sum
    }, 0)

  getLikesCount = (comments) =>
    comments.reduce((prevValue, comment) => {
      let sum = comment.likes.length

      if (comment.comments) {
        sum += comment.comments.reduce(
          (previous, comment) => previous + comment.likes.length,
          0,
        )
      }

      return prevValue + sum
    }, 0)

  getTopFiveContributors = (comments) => {
    const contributors = []

    comments.forEach((comment) => {
      const contributor = contributors.find(
        (contributorItem) => contributorItem._id === comment.author._id,
      )

      if (!contributor) {
        const { _id, name, username } = comment.author
        contributors.push({ _id, name, username, count: 1 })
      } else {
        contributor.count += 1
      }

      comment.comments.forEach((subComment) => {
        const subCommentContributor = contributors.find(
          (contributorItem) => contributorItem._id === subComment.author._id,
        )

        if (!subCommentContributor) {
          const { _id, name, username } = subComment.author
          contributors.push({ _id, name, username, count: 1 })
        } else {
          subCommentContributor.count += 1
        }
      })
    })

    contributors.sort((first, second) => second.count - first.count)

    return contributors.slice(0, 4)
  }

  deleteComment = async (commentId) => {
    const { channelId, threadId } = this.props
    await axios.post(
      `${baseURL}/channel/deleteComment`,
      { channelId, threadId, commentId },
      { headers: getAuthHeader() },
    )
  }

  removeComment = (commentId, subCommentId) => {
    const { comments } = this.state
    const newState = {}

    if (!subCommentId) {
      this.deleteComment(commentId)
      newState.comments = comments.filter(({ _id }) => _id !== commentId)
    } else {
      this.deleteComment(subCommentId)

      newState.comments = comments.map((comment) =>
        comment._id !== commentId
          ? comment
          : {
              ...comment,
              comments: comment.comments.filter(
                (reply) => reply._id !== subCommentId,
              ),
            },
      )
    }

    const commentsCount = this.getCommentsCount(newState.comments)
    const likesCount = this.getLikesCount(newState.comments)
    const topFiveContributors = this.getTopFiveContributors(newState.comments)

    this.setState({
      ...newState,
      commentsCount,
      likesCount,
      topFiveContributors,
    })
  }

  lockComment = (commentId, subCommentId) => {}

  pinComment = (threadId, priority) => {
    // we cannot pin the comment object
    return
    const { channelId } = this.props
    const { comments } = this.state

    axios.post({
      url: `${baseURL}/channel/updateThread`,
      data: {
        channelId,
        threadId,
        isPinned: true,
        priority,
      },
    })

    const newComments = comments.map((comment) => {
      if (comment._id === threadId) {
        return { ...comment, isPinned: true, priority }
      }

      return comment
    })

    this.setState({
      comments: newComments,
    })
  }

  unpinComment = (threadId) => {
    // we cannot unpin the comment object
    return
    const { channelId } = this.props
    const { comments } = this.state

    axios.post({
      url: `${baseURL}'/channel/updateThread`,
      data: {
        channelId,
        threadId,
        isPinned: false,
        priority: 0,
      },
    })

    this.setState({
      comments: comments.map((comment) =>
        comment._id === threadId
          ? { ...comment, isPinned: false, priority: 0 }
          : comment,
      ),
    })
  }

  onLoadMoreClick = () => {
    const { comments, take, currentComments } = this.state

    const nextCommentsCount = currentComments + take

    this.setState({
      currentComments: nextCommentsCount,
      hasMoreComments: nextCommentsCount < comments.length,
    })
  }

  setLike = (commentId, like) => {
    const { channelId, threadId } = this.props
    axios.post(
      `${baseURL}/channel/setLike`,
      { channelId, threadId, commentId, like },
      { headers: getAuthHeader() },
    )
  }

  toggleLike = (userId, commentId, subCommentId) => {
    const { comments } = this.state
    const newComments = comments.map((comment) => {
      if (comment._id !== commentId) {
        return comment
      }

      if (subCommentId === undefined) {
        const userLike = comment.likes.some((like) => like.user_id === userId)
        if (userLike) {
          this.setLike(commentId, 0)
          return {
            ...comment,
            likes: comment.likes.filter((like) => like.user_id !== userId),
          }
        }
        this.setLike(commentId, 1)
        return {
          ...comment,
          likes: [...comment.likes, { like: 1, user_id: userId }],
        }
      }

      return {
        ...comment,
        comments: comment.comments.map((reply) => {
          if (reply._id !== subCommentId) {
            return reply
          }
          const userLike = reply.likes.some((like) => like.user_id === userId)
          if (userLike) {
            this.setLike(subCommentId, 0)

            return {
              ...reply,
              likes: reply.likes.filter((like) => like.user_id !== userId),
            }
          }
          this.setLike(subCommentId, 1)

          return {
            ...reply,
            likes: [...reply.likes, { like: 1, user_id: userId }],
          }
        }),
      }
    })

    this.setState({ comments: newComments })
  }

  addNewComment = async (text) => {
    const { channelId, threadId } = this.props
    await axios.post(
      `${baseURL}/channel/createComment`,
      { channelId, threadId, text },
      { headers: getAuthHeader() },
    )
    this.getCommentsForThread()
  }

  addNewReply = async (parentId, text) => {
    const { channelId, threadId } = this.props
    await axios.post(
      `${baseURL}/channel/createComment`,
      { channelId, threadId, commentId: parentId, text },
      { headers: getAuthHeader() },
    )
    this.getCommentsForThread()
  }

  render() {
    const { children } = this.props
    const {
      title,
      comments,
      currentComments,
      hasMoreComments,
      commentsCount,
      likesCount,
      topFiveContributors,
    } = this.state

    return (
      <CommentsContext.Provider
        value={{
          title,
          comments: comments.slice(0, currentComments),
          hasMoreComments,
          commentsCount,
          likesCount,
          topFiveContributors,
          removeComment: this.removeComment,
          lockComment: this.lockComment,
          pinComment: this.pinComment,
          unpinComment: this.unpinComment,
          onLoadMoreClick: this.onLoadMoreClick,
          toggleLike: this.toggleLike,
          addNewComment: this.addNewComment,
          addNewReply: this.addNewReply,
          getFilteredComments: this.getFilteredComments,
        }}
      >
        {children}
      </CommentsContext.Provider>
    )
  }
}

CommentsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  channelId: PropTypes.string.isRequired,
}

export const CommentsConsumer = CommentsContext.Consumer
