import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'
import {
  Container,
  StatusDot,
  RedStatusDot,
  Message,
  TooltipIcon,
  TooltipContainer,
} from './StatusItem.styles'

const StatusItem = ({ message, tooltip, isDone }) => (
  <Container>
    {isDone ? <StatusDot /> : <RedStatusDot />}
    <Message>{message}</Message>
    {tooltip && (
      <TooltipContainer>
        <Tooltip
          position="top"
          title={tooltip}
          theme="light"
          arrow
          duration={200}
        >
          <TooltipIcon />
        </Tooltip>
      </TooltipContainer>
    )}
  </Container>
)

StatusItem.propTypes = {
  message: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
}

StatusItem.defaultProps = {
  tooltip: '',
}

export default StatusItem
