import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

import { Chart, Content } from './BasicDonutChart.styles'

class BasicDonutChart extends PureComponent {
  componentDidMount() {
    this.createChart()
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  }

  createChart = () => {
    if (!this.chartEl) return

    const { data } = this.props

    const width = 170
    const height = 170
    const pad = 0
    const thickness = 8

    const outerRadius = width / 2
    const innerRadius = outerRadius - thickness
    const padAngle = pad / (outerRadius - thickness / 2)

    const customColors = data.map(item => item.color)
    const color = customColors[0]
      ? d3.scaleOrdinal(customColors)
      : d3.scaleOrdinal(d3.schemeCategory10)

    const svg = d3
      .select(this.chartEl)
      .append('svg')
      .attr('viewBox', `0 0 ${width} ${height}`)

    const chart = svg
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`)

    const pie = d3
      .pie()
      .value(d => d.value)
      .sort(null)
      .padAngle(padAngle)

    const arc = d3
      .arc()
      .outerRadius(outerRadius)
      .innerRadius(innerRadius)

    function arcTween(a) {
      const interp = d3.interpolate(this._current, a) // eslint-disable-line
      this._current = interp(1) // eslint-disable-line
      return time => arc(interp(time))
    }

    function setD(d) {
      this._current = d // eslint-disable-line
    }

    this.drawDate = dataSet => {
      // Join new data
      const path = chart.selectAll('path').data(pie(dataSet))

      // Update existing arcs
      path
        .transition()
        .ease(d3.easeQuadOut)
        .duration(1000)
        .attrTween('d', arcTween)

      // Enter new arcs
      path
        .enter()
        .append('path')
        .attr('fill', (d, i) => color(i))
        .attr('d', arc)
        .each(setD) // eslint-disable-line
    }

    // set init data
    this.drawDate(
      data.map(datum => ({
        ...datum,
        value: 0,
      })),
    )
  }

  drawChart = () => {
    const { data } = this.props

    this.drawDate(data)
    this.drawn = true
  }

  onScroll = e => {
    if (this.drawn) return
    if (this.chartEl.getBoundingClientRect().top < window.innerHeight) {
      this.drawChart()
    }
  }

  render() {
    const { children, center } = this.props
    return (
      <Chart
        ref={el => {
          this.chartEl = el
        }}
        center={center}
      >
        <Content>{children}</Content>
      </Chart>
    )
  }
}

BasicDonutChart.propTypes = {
  children: PropTypes.node,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      name: PropTypes.string,
      color: PropTypes.string,
    }),
  ).isRequired,
  center: PropTypes.bool,
}

BasicDonutChart.defaultProps = {
  children: null,
  center: false,
}

export default BasicDonutChart
