import React from 'react'
import PropTypes from 'prop-types'

import SingleProgressBar from 'components/AdminDashboard/SingleProgressBar/SingleProgressBar'
import { Container, Label, Chart } from './InvestmentRatio.styles'

import { fixDecimals } from 'utils'

const InvestmentRatio = ({ currency, percent = 0, labelWidth, colorTheme }) => (
  <Container>
    <Label labelWidth={labelWidth}>
      <strong>{currency}</strong>
      {` (${fixDecimals(percent, 2)}%)`}
    </Label>
    <Chart labelWidth={labelWidth}>
      <SingleProgressBar colorTheme={colorTheme} progress={percent} />
    </Chart>
  </Container>
)

InvestmentRatio.propTypes = {
  currency: PropTypes.string.isRequired,
  percent: PropTypes.number.isRequired,
  labelWidth: PropTypes.string,
  colorTheme: PropTypes.oneOf(['blue', 'red']),
}

InvestmentRatio.defaultProps = {
  labelWidth: '110px',
  colorTheme: 'blue',
}

export default InvestmentRatio
