import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import { withTheme } from 'styled-components/macro'
import { Container } from './CircleProgress.styles'

class CircleProgress extends PureComponent {
  state = {
    hasRendered: false,
  }

  componentDidMount() {
    const { progress } = this.props
    const { hasRendered } = this.state

    if (typeof progress === 'number' && !hasRendered) {
      this.createProgress()
      this.setState({ hasRendered: true })
    }
  }

  componentDidUpdate() {
    const { progress } = this.props
    const { hasRendered } = this.state

    if (typeof progress === 'number' && !hasRendered) {
      this.createProgress()
      this.setState({ hasRendered: true })
    }
  }

  createProgress = () => {
    if (!this.progressEl) return

    const { progress, theme, id } = this.props
    const w = 32
    const h = 32
    const thickness = 3
    const outerRadius = w * 0.45
    const endAngle = (Math.PI * progress) / 50

    const svg = d3
      .select(this.progressEl)
      .append('svg')
      .attr('width', w)
      .attr('height', h)
      .append('g')

    const defs = svg.append('defs')

    const gradient = defs
      .append('linearGradient')
      .attr('id', `${id}-circleProgressGradient`)
      .attr('x1', '0%')
      .attr('x2', '100%')
      .attr('y1', '0%')
      .attr('y2', '100%')

    gradient
      .append('stop')
      .attr('class', 'start')
      .attr('offset', '0%')
      .attr('stop-color', theme.cPrimaryLight)
      .attr('stop-opacity', 1)

    gradient
      .append('stop')
      .attr('class', 'end')
      .attr('offset', '100%')
      .attr('stop-color', theme.cPrimary)
      .attr('stop-opacity', 1)

    const mainArc = d3
      .arc()
      .startAngle(0)
      .endAngle(Math.PI * 2)
      .innerRadius(outerRadius - thickness)
      .outerRadius(outerRadius)

    svg
      .append('path')
      .attr('fill', theme.cGrey)
      .attr('transform', `translate(${w / 2},${h / 2})`)
      .attr('d', mainArc())

    svg
      .append('path')
      .attr('fill', `url(#${id}-circleProgressGradient)`)
      .attr('transform', `translate(${w / 2},${h / 2})`)
      .attr('d', () => {
        mainArc.endAngle(endAngle)
        return mainArc()
      })
  }

  render() {
    return (
      <Container
        ref={el => {
          this.progressEl = el
        }}
      />
    )
  }
}

CircleProgress.propTypes = {
  id: PropTypes.string.isRequired,
  progress: PropTypes.number,
  theme: PropTypes.instanceOf(Object).isRequired,
}

CircleProgress.defaultProps = {
  progress: undefined,
}

export default withTheme(CircleProgress)
