import styled from 'styled-components/macro'

export const ColorBoxWithTitle = styled.div`
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${props => props.theme.cWhite};
  background-color: ${props => props.theme.cBlack};
  min-width: 50px;
  padding: 4px;
  border-radius: 3px;

  ${props =>
    props.color &&
    `
    color: ${props.color};
  `};

  ${props =>
    props.background &&
    `
    background-color: ${props.background};
  `};
`

export default ColorBoxWithTitle
