import styled from 'styled-components/macro'
import rwd from 'styles/rwd'
import Button from 'components/Button/Button.styles'

const DoubleButtons = styled.div`
  margin: -12px;

  ${Button} {
    width: 100%;
    margin: 12px;
  }

  ${rwd.Medium`
    display: flex;
    flex-direction: row;
    
    ${Button} {
      width: auto;
      flex: 1;
    }
  `}
`

export default DoubleButtons
