import React from 'react'
import PropTypes from 'prop-types'
import { TransitionGroup, Transition } from 'react-transition-group'

import { StyledTabs, Tab } from './Tabs.styles'

export { Tab }

const timeout = 300
const getTransitionStyles = {
  entering: {
    position: 'absolute',
    opacity: 0,
  },
  entered: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `opacity ${timeout}ms ease-in-out`,
    opacity: 0,
  },
}

const Tabs = ({ children, activeTab }) => (
  <TransitionGroup component={StyledTabs}>
    {React.Children.map(children, child => {
      if (child.props.tabId !== activeTab || !React.isValidElement(child))
        return null
      return (
        <Transition
          key={child.props.tabId}
          timeout={{
            enter: timeout,
            exit: timeout,
          }}
        >
          {status => (
            <div
              style={{
                height: '100%',
                ...getTransitionStyles[status],
              }}
            >
              {child}
            </div>
          )}
        </Transition>
      )
    })}
  </TransitionGroup>
)

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Tabs.defaultProps = {
  activeTab: 0,
}

export default Tabs
