import styled, { css } from 'styled-components/macro'
import Icon from 'components/Icon/Icon.style'
import { hex2Rgba } from 'styles/utils'

export const Info = styled.div`
  position: relative;
  font-size: 0;
`

export const IconWrap = styled.div`
  z-index: 1;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 38px;
  height: 38px;
  margin-right: -38px;
  color: ${props => props.color};
  background-color: ${props => props.color && hex2Rgba(props.color, 0.1)};
`

export const Name = styled.div`
  z-index: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  padding-left: 58px;
  transition: color 0.2s;
`

export const Counter = styled.div`
  position: relative;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  min-width: 40px;
`

export const StyledIcon = styled(Icon)`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -8px;
  margin-left: -8px;
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
`

export const Container = styled.button`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  padding: 15px 0;
  cursor: pointer;
  outline: none;

  & + & {
    border-top: 1px solid ${props => props.theme.cLightGrey};
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 3px;
    background-color: transparent;
    transition: background-color 0.2s;
  }

  ${({ notClickable }) =>
    notClickable &&
    css`
      pointer-events: none;
    `}

  ${props =>
    props.side &&
    `
    &:before {
      right: -22px;
    }
  `}

  &:focus ,
  &:hover {
    &:before {
      background-color: ${props => props.theme.cGrey};
    }

    ${Name} {
      color: ${props => props.theme.cPrimary};
    }
  }

  ${props =>
    props.isActive &&
    `
    & ,
    &:focus ,
    &:hover {
      &:before {
        background-color: ${props.color};
      }
    }
  `}
`
