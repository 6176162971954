import React from 'react'
import PropTypes from 'prop-types'
import { Popup } from 'semantic-ui-react'
import Icon from 'components/Icon/Icon.style'
import {
  StyledSidebarItem,
  IconContainer,
  Title,
  IconWrapper,
} from './SidebarItem.styles'

const SidebarItem = ({ icon, title, handleClick, isActive, isExpanded }) => (
  <Popup
    content={title}
    trigger={
      <StyledSidebarItem onClick={handleClick} isActive={isActive}>
        <IconWrapper>
          <IconContainer>
            <Icon icon={icon} />
          </IconContainer>
        </IconWrapper>
        <Title>{title}</Title>
      </StyledSidebarItem>
    }
    position="right center"
    disabled={isExpanded}
  />
)

SidebarItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
  isActive: PropTypes.bool,
  isExpanded: PropTypes.bool,
}

SidebarItem.defaultProps = {
  handleClick: () => {},
  isActive: false,
  isExpanded: false,
}

export default SidebarItem
