import styled from 'styled-components/macro'
import Icon, { iconsMap } from 'components/Icon/Icon.style'

export const Container = styled.div`
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
`

export const EmojiIcon = styled(Icon).attrs({
  icon: iconsMap.emoji,
})`
  display: block;
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: ${props => props.theme.cPrimary};
  transition: color 0.2s;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.cPrimaryDark};
  }
`
