import styled from 'styled-components/macro'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Title = styled.div`
  font-size: 14px;
  line-height: 19px;
`

export const CapContent = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
  min-height: 46px;
`

export const Days = styled.div`
  font-size: 34px;
  line-height: 46px;
  margin-right: 6px;
`

export const CapContentText = styled.div`
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.theme.cGrey2};
  margin-top: 16px;
`
