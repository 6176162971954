import styled from 'styled-components/macro'
import Icon from 'components/Icon/Icon.style'

export const Container = styled.div`
  text-align: left;
`

export const Head = styled.div`
  position: relative;
  padding: 10px 0;
`

export const Body = styled.div`
  position: relative;
`

export const Foot = styled.div`
  position: relative;
  padding: 10px 0;
`

export const Replies = styled.div`
  position: relative;
  padding-left: 70px;
`

export const Message = styled.div`
  position: relative;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.53px;
  padding: 20px;
  border-radius: 0 10px 10px 10px;
  background-color: ${(props) => props.theme.cGrey3};

  ${(props) =>
    props.isPinned && `background-color: ${props.theme.cYellowLight};`}
  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -15px;
    left: 0;
    border-left: 18px solid ${(props) => props.theme.cGrey3};
    border-top: 16px solid transparent;
  }
`

export const AvatarBox = styled.div`
  z-index: 1;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 56px;
  height: 56px;
  margin-right: -56px;
`

export const Avatar = styled.div`
  display: block;
  width: 56px;
  height: 56px;
  border-radius: 28px;
  overflow: hidden;
  background-color: ${(props) => props.theme.cGrey};
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
`

export const AvatarImg = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`

export const BadgeImg = styled.img`
  display: block;
  position: absolute;
  right: -8px;
  bottom: -4px;
`

export const Info = styled.div`
  z-index: 0;
  display: inline-block;
  vertical-align: middle;
  padding-left: 70px;
`

export const Name = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
`

export const Time = styled.div`
  font-size: 12px;
  line-height: 18px;
  margin-top: 4px;
`

export const Options = styled.div`
  margin: 0 10px;
`

export const OptionIcon = styled(Icon)`
  display: inline-block;
  vertical-align: middle;
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  margin-right: 10px;
  color: ${(props) => props.theme.cWarning};
  transition: color 0.2s;
`

export const Option = styled.button`
  display: inline-block;
  vertical-align: middle;
  font-size: 0;
  margin: 0 10px;
  cursor: pointer;
  outline: 0;

  span {
    display: inline-block;
    vertical-align: middle;
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1.98px;
    text-transform: uppercase;
  }

  &:focus,
  &:hover {
    ${OptionIcon} {
      color: ${(props) => props.theme.cPrimary};
    }
  }
`

export const HeadOptions = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
`
