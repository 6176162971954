import styled from 'styled-components/macro'
import Icon from 'components/Icon/Icon.style'

const itemWidth = 40
const itemHalfWidth = itemWidth / 2
const paddingLeft = 6

export const Container = styled.div`
  z-index: 1;
  position: relative;
  width: ${itemWidth}px;
  height: ${itemWidth}px;
`

export const OptionsWrapper = styled.div`
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: ${itemWidth}px;
  height: ${itemWidth}px;
  border: 1px solid ${props => props.theme.cLightGrey};
  border-radius: ${itemHalfWidth}px;
  transition: width 0.2s ease-out;
  background-color: ${props => props.theme.cWhite};
  padding-left: ${paddingLeft}px;
  overflow: hidden;

  ${props =>
    props.isOpened &&
    `
    width: ${itemWidth * (props.items + 1) + paddingLeft}px;
  `};
`

export const Options = styled.div`
  position: relative;
  top: 0;
  left: 0;
  font-size: 0;

  ${props =>
    props.items &&
    `
    width: ${itemWidth * props.items}px;
  `}
`

export const Option = styled.button`
  display: inline-block;
  position: relative;
  width: ${itemWidth}px;
  height: ${itemWidth}px;
  outline: none;
  border-right: 1px solid ${props => props.theme.cLightGrey};
  color: ${props => props.theme.cWarning};
  transition: color 0.2s;

  &:last-of-type {
    border-right-color: transparent;
  }

  &:focus,
  &:hover {
    color: ${props => props.theme.cPrimary};
  }
`

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  margin-top: -8px;
  margin-left: -8px;
  text-align: center;

  ${props =>
    props.large &&
    `
    width: 20px;
    height: 20px;
    font-size: 20px;
    line-height: 20px;
    margin-top: -10px;
    margin-left: -10px;
  `}
`

export const Toggler = styled.button`
  z-index: 1;
  position: relative;
  display: block;
  width: ${itemWidth}px;
  height: ${itemWidth}px;
  border: 1px solid ${props => props.theme.cLightGrey};
  border-radius: ${itemHalfWidth}px;
  background-color: ${props => props.theme.cWhite};
  color: ${props => props.theme.cWarning};
  outline: none;
  transition: border-color 0.2s, background-color 0.2s, color 0.2s;

  ${props =>
    props.isActive &&
    `
    border-color: ${props.theme.cWarning};
    background-color: ${props.theme.cWarning};
    color: ${props.theme.cWhite};
  `}
`
