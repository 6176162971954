import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import getProfilePicture from 'utils/getProfilePicture'

import {
  Container,
  PersonBox,
  Avatar,
  AvatarImg,
  Name,
  CommentsBox,
  CommentIcon,
} from './ContributorItem.styles'
import avatarPlaceholder from 'static/images/avatar-placeholder.png'

const ContributorItem = ({ name, avatar, comments, to, userID }) => {
  const [profilePicture, setProfilePicture] = useState(avatarPlaceholder)

  useEffect(() => {
    const setPicture = async () =>
      setProfilePicture(await getProfilePicture(userID))

    setPicture()
  })

  return (
    <Container to={to}>
      <PersonBox>
        <Avatar>
          <AvatarImg src={profilePicture} alt="avatar" />
        </Avatar>
        <Name>{name}</Name>
      </PersonBox>
      <CommentsBox>
        <CommentIcon />
        {comments}
      </CommentsBox>
    </Container>
  )
}

ContributorItem.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string.isRequired,
  comments: PropTypes.number,
  to: PropTypes.string.isRequired,
}

ContributorItem.defaultProps = {
  comments: 0,
}

export default ContributorItem
