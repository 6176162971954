import { orderStates } from 'pages/admin-dashboard/private-investor'

export default function sortListBy(sortBy, originalArray, order) {
  const sorted = [...originalArray]

  sorted.sort((a, b) => {
    const valueA = a[sortBy] ? a[sortBy].toUpperCase() : 'Not set'
    const valueB = b[sortBy] ? b[sortBy].toUpperCase() : 'Not set'

    if (isDate(a[sortBy])) {
      return new Date(a[sortBy]) - new Date(b[sortBy])
    } else {
      if (valueA < valueB) return -1
      if (valueA > valueB) return 1
      return 0
    }
  })
  if (order === orderStates.DESC) {
    return sorted.reverse()
  }
  return sorted
}

var isDate = function (date) {
  return new Date(date) !== 'Invalid Date' && !isNaN(new Date(date))
}
