import styled from 'styled-components/macro'

export const Chart = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;

  .gridY {
    .domain {
      display: none;
    }

    line {
      stroke: #e0e6f1;
    }
  }

  .axisX,
  .axisY {
    path {
      display: none;
    }

    .tick {
      line {
        display: none;
      }

      text {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: ${(props) => props.theme.cGrey2};
      }
    }
  }
`

export const LineChartContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  padding: 20px;
`

export const TooltipWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`

export const LineChartTooltip = styled.div`
  font-size: 10px;
  min-width: 40px;

  & > h3 {
    font-weight: 600;
    font-family: 'Open Sans';
    font-size: 16px;
    margin: 0;
    padding: 0;
  }

  & > h4 {
    font-weight: 400;
    font-family: 'Open Sans';
    font-size: 16px;
    margin: 0;
    padding: 0;
  }
  & > p {
    /* color: ${(props) => props.theme.cProgressDone}; */
    color: #79ca65;
    font-family: 'Open Sans';
    font-size: 11px;
    text-transform: uppercase;
  }
`

export const LineChartWraper = styled.div`
  width: 100%;
  display: flex;
`
