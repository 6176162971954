/* Libraries */
import Cookies from 'js-cookie'
import axios from 'axios'
const baseURL = process.env.REACT_APP_API_URL

export const getUnsubscription = async () =>
  new Promise(async (resolve, reject) => {
    const accessToken = await Cookies.get('STOKR_ACCESS_TOKEN')

    if (!accessToken) {
      console.log('no access token')
    }

    try {
      const result = await axios.post(
        `${baseURL}/emails/getunsubscriptions`,
        {},
        { headers: { Authorization: `Bearer ${accessToken}` } },
      )

      // console.log(result);

      resolve(result.data)
    } catch (error) {
      console.log(`Error: ${error}`)

      reject(error)
    }
  })

export const setUnsubscription = async (data) =>
  new Promise(async (resolve, reject) => {
    const accessToken = await Cookies.get('STOKR_ACCESS_TOKEN')

    if (!accessToken) {
      console.log('no access token')
    }

    try {
      const result = await axios.post(
        `${baseURL}/emails/setunsubscriptions`,
        data,
        { headers: { Authorization: `Bearer ${accessToken}` } },
      )

      // console.log(result);

      resolve(result.data)
    } catch (error) {
      console.log(`Error: ${error}`)

      reject(error)
    }
  })
