import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 0;
  padding-left: 16px;
  white-space: nowrap;

  &:before {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 3px;
    background-color: ${props => props.theme.cSuccess};
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -3px;
  }
`

export const Date = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
`

export const Time = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid ${props => props.theme.cLightGrey};
`
