import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ReactSelect, { components } from 'react-select'
import { Wrapper, Label } from './Input.styles'

import {
  SelectControl,
  SelectMenu,
  SelectMenuList,
  SelectOption,
  SelectContainer,
  SelectValueContainer,
  SelectIcon,
} from './Select.styles'

const DropdownIndicator = (props) => {
  const { selectProps } = props

  return (
    <components.DropdownIndicator {...props}>
      <SelectIcon isMenuOpen={selectProps.menuIsOpen} />
    </components.DropdownIndicator>
  )
}

DropdownIndicator.propTypes = {
  selectProps: PropTypes.instanceOf(Object).isRequired,
}

const SelectMenuListWithScroll = (props) => {
  const { children } = props

  return (
    <SelectMenuList autoHeight autoHeightMax={300}>
      {children}
    </SelectMenuList>
  )
}

SelectMenuListWithScroll.propTypes = {
  children: PropTypes.node.isRequired,
}

class Select extends PureComponent {
  state = {
    labelUp: false,
    hasFocus: false,
  }

  componentDidMount() {
    this.checkLabel(false)
  }

  onFocus = (e) => {
    const { onFocus, name, value, search } = this.props
    onFocus && onFocus({ name, value })

    if (search) {
      this.checkLabel(true)
    }
  }

  onBlur = (e) => {
    const { onBlur, name, value } = this.props
    onBlur && onBlur({ name, value })

    setTimeout(() => {
      this.checkLabel(false)
    }, 1)
  }

  onChange = (selectedOption) => {
    const { onChange, name } = this.props

    onChange && onChange({ name, value: selectedOption.value })

    setTimeout(() => {
      this.checkLabel(true)
    }, 1)
  }

  checkLabel = (focus) => {
    const { value } = this.props

    this.setState({
      labelUp: focus || !!value,
      hasFocus: focus,
    })
  }

  render() {
    const { labelUp, hasFocus } = this.state
    const {
      id,
      name,
      label,
      options,
      search,
      error,
      touched,
      value,
      fontSize,
      fontWeight,
    } = this.props

    return (
      <Wrapper>
        {label && (
          <Label
            isUp={labelUp}
            active={hasFocus}
            error={error && touched}
            htmlFor={id}
          >
            {label}
          </Label>
        )}
        <ReactSelect
          id={id}
          name={name}
          options={options}
          onChange={this.onChange}
          onBlur={this.onBlur}
          onFocus={this.onFocus}
          value={options.find((option) => option.value === value)}
          placeholder=""
          isSearchable={search}
          styles={{
            control: (base, state) => ({
              ...base,
              fontSize: fontSize,
              fontWeight: fontWeight,
            }),
          }}
          components={{
            Control: SelectControl,
            DropdownIndicator,
            IndicatorSeparator: null,
            Menu: SelectMenu,
            MenuList: SelectMenuListWithScroll,
            Option: SelectOption,
            SelectContainer,
            ValueContainer: SelectValueContainer,
          }}
        />
      </Wrapper>
    )
  }
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  value: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    }),
  ).isRequired,
  search: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

Select.defaultProps = {
  label: '',
  value: '',
  search: false,
  error: false,
  touched: false,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
}

export default Select
