import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import { Modal } from 'components/Modal/Modal'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
import ComponentScroll from 'components/ComponentScroll/ComponentScroll'
import CommentItem from 'components/AdminDashboard/CommentItem/CommentItem'
import TextEditor from 'components/AdminDashboard/TextEditor/TextEditor'

import { AuthConsumer } from '@stokr/components-library'
import { ReplyRows, CommentsRow, ReplyRow } from './CommentReplyModal.styles'

class CommentReplyModal extends PureComponent {
  state = {
    message: '',
  }

  updateMessage = (e) => {
    this.setState({
      message: e.target.value,
    })
  }

  addNewReply = (parentId) => {
    const { message } = this.state
    const { replyFor, onModalClose, addNewReply } = this.props

    addNewReply && addNewReply(replyFor, message)
    onModalClose && onModalClose()

    this.setState({
      message: '',
    })
  }

  render() {
    const { message } = this.state

    const { replyFor, onModalClose, toggleLike, comments, project } = this.props

    return (
      <Modal fullscreen thin isOpen={replyFor !== null} onClose={onModalClose}>
        <ReplyRows>
          <CommentsRow
            as={ComponentWrapper}
            noPaddingBottom
            noPaddingHorizontal
          >
            <ComponentScroll>
              <ComponentWrapper noPaddingVertical>
                <AuthConsumer>
                  {({ user }) =>
                    comments
                      .filter((comment) => comment._id === replyFor)
                      .map((comment) => (
                        <CommentItem
                          key={comment._id}
                          project={project}
                          // avatar={`${
                          //   process.env.REACT_APP_PHOTO_API_URL
                          // }/media/picture/view/${comment.author._id}`}
                          userID={comment.author._id}
                          name={comment.author.name || 'anonymous'}
                          time={comment.creation_dt}
                          message={comment.text}
                          likes={comment.likes.length}
                          userLike={comment.likes.some(
                            (like) => like.user_id === user._id,
                          )}
                          superAdmin={comment.author.user_type === 'company_sa'}
                          isPinned={comment.isPinned}
                          handleLikeClick={() => {
                            toggleLike(user._id, comment._id)
                          }}
                          allowReply={false}
                          replies={
                            comment.comments && (
                              <Fragment>
                                {comment.comments.map((reply) => (
                                  <CommentItem
                                    project={project}
                                    key={reply._id}
                                    // avatar={`${
                                    //   process.env.REACT_APP_PHOTO_API_URL
                                    // }/media/picture/view/${reply.author._id}`}
                                    userID={reply.author._id}
                                    name={reply.author.name || 'anonymous'}
                                    time={reply.creation_dt}
                                    message={reply.text}
                                    likes={reply.likes.length}
                                    userLike={reply.likes.some(
                                      (like) => like.user_id === user._id,
                                    )}
                                    superAdmin={
                                      reply.author.user_type === 'company_sa'
                                    }
                                    handleLikeClick={() => {
                                      toggleLike(
                                        user._id,
                                        comment._id,
                                        reply._id,
                                      )
                                    }}
                                    allowReply={false}
                                  />
                                ))}
                              </Fragment>
                            )
                          }
                        />
                      ))
                  }
                </AuthConsumer>
              </ComponentWrapper>
            </ComponentScroll>
          </CommentsRow>
          <ReplyRow as={ComponentWrapper}>
            <TextEditor
              isUpdates
              id="reply-comment"
              name="reply-comment"
              placeholder="Write reply message…"
              value={message}
              limit={280}
              info="Write reply"
              onChange={this.updateMessage}
              minHeight={110}
              maxHeight={110}
              done={{
                label: 'Post',
                onClick: this.addNewReply,
              }}
              cancel={{
                label: 'Cancel',
                onClick: onModalClose,
              }}
            />
          </ReplyRow>
        </ReplyRows>
      </Modal>
    )
  }
}

CommentReplyModal.propTypes = {
  replyFor: PropTypes.string,
  onModalClose: PropTypes.func.isRequired,
  toggleLike: PropTypes.func.isRequired,
  addNewReply: PropTypes.func.isRequired,
  comments: PropTypes.arrayOf(Object).isRequired,
}

CommentReplyModal.defaultProps = {
  replyFor: null,
}

export default CommentReplyModal
