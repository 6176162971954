import styled from 'styled-components/macro'

const SectionTitle = styled.h2`
  position: relative;
  font-family: 'Open Sans';
  font-weight: normal;
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 0;
  border: 0;
  margin: 0;

  span {
    display: inline-block;
  }

  strong {
    display: inline-block;
    font-weight: bold;
  }
`

export default SectionTitle
