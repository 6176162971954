import React from 'react'
import PropTypes from 'prop-types'

import { ColorBoxWithTitle } from 'components/AdminDashboard/ColorBoxWithTitle/ColorBoxWithTitle.styles'

import { Container, Title, Value, Percent } from './BasicLineChartInfo.styles'

const BasicLineChartInfo = ({ title, value, percent }) => {
  const percentText = `${percent > 0 ? '+' : ''}${percent}%`

  let percentBackground
  if (percent !== 0) {
    percentBackground = `${percent >= 0 ? '#79ca65' : '#ee220d'}`
  }

  return (
    <Container>
      <Title>{title}</Title>
      <Value>{value}</Value>
      <Percent>
        <ColorBoxWithTitle background={percentBackground}>
          {percentText}
        </ColorBoxWithTitle>
      </Percent>
    </Container>
  )
}

BasicLineChartInfo.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  percent: PropTypes.number,
}

BasicLineChartInfo.defaultProps = {
  percent: 0,
}

export default BasicLineChartInfo
