import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'react-tippy'

import { Container, Icon } from './InfoIcon.styles'

const InfoIcon = ({
  title,
  html,
  position,
  noMargin,
  noMarginLeft,
  noMarginRight,
}) => (
  <Container
    noMargin={noMargin}
    noMarginLeft={noMarginLeft}
    noMarginRight={noMarginRight}
  >
    <Tooltip
      position={position}
      title={title}
      html={html}
      theme="light"
      arrow
      duration={200}
    >
      <Icon />
    </Tooltip>
  </Container>
)

InfoIcon.propTypes = {
  title: PropTypes.string,
  html: PropTypes.node,
  position: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
  noMargin: PropTypes.bool,
  noMarginLeft: PropTypes.bool,
  noMarginRight: PropTypes.bool,
}

InfoIcon.defaultProps = {
  title: null,
  html: null,
  position: 'bottom',
  noMargin: false,
  noMarginLeft: false,
  noMarginRight: false,
}

export default InfoIcon
