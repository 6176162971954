import React from 'react'

const CapsLockSvg = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="6.843"
    height="8.904"
    viewBox="0 0 6.843 8.904"
    style={{
      width: '7px',
      height: '9px',
    }}
  >
    <g transform="translate(-.081 -.087)">
      <path
        transform="translate(1.778 7.699)"
        d="M3.159,0H.286A.288.288,0,0,0,0,.276v.74a.289.289,0,0,0,.286.276H3.159a.288.288,0,0,0,.286-.276V.276A.288.288,0,0,0,3.159,0Z"
      />
      <path
        transform="translate(.081 .087)"
        d="M6.79,3.549,3.649.1a.315.315,0,0,0-.461,0L.048,3.549a.221.221,0,0,0,.175.356H1.7V6.222a.289.289,0,0,0,.286.276H4.855a.288.288,0,0,0,.286-.276V3.905H6.615A.216.216,0,0,0,6.79,3.549Z"
      />
    </g>
  </svg>
)

export default CapsLockSvg
