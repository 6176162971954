import React from 'react'
import PropTypes from 'prop-types'
import { Bar, ProgressBar, Container, Label } from './SingleProgressBar.styles'

const SingleProgressBar = ({ progress, colorTheme, label, labelSuccess }) => (
  <Container>
    <Bar colorTheme={colorTheme}>
      <ProgressBar progress={progress} colorTheme={colorTheme} />
    </Bar>
    {label && <Label labelSuccess={labelSuccess}>{label}</Label>}
  </Container>
)

SingleProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  colorTheme: PropTypes.oneOf(['blue', 'red', 'redInverted', 'green']),
  label: PropTypes.string,
  labelSuccess: PropTypes.bool,
}

SingleProgressBar.defaultProps = {
  colorTheme: 'blue',
  label: '',
  labelSuccess: false,
}

export default SingleProgressBar
