import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Title,
  CapContent,
  Days,
  CapContentText,
} from './CapItem.styles'
import OverviewItem from '../OverviewItem/OverviewItem'

const CapItem = ({ title, days, text, overviewItemProps, isTokenSales }) => (
  <Container>
    <Title>{title}</Title>
    <CapContent isTokenSalen={isTokenSales}>
      {days && <Days>{days}</Days>}
      <CapContentText>{text}</CapContentText>
    </CapContent>
    <OverviewItem {...overviewItemProps} />
  </Container>
)

CapItem.propTypes = {
  title: PropTypes.string.isRequired,
  days: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  text: PropTypes.string,
  overviewItemProps: PropTypes.shape(OverviewItem.propTypes).isRequired,
}

CapItem.defaultProps = {
  days: null,
  text: '',
}

export default CapItem
