import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledTabs,
  TabsWrapper,
  ActionsWrapper,
} from './DashboardTabsNav.styles'

import DashboardTabNav from './DashboardTabNav'

export { DashboardTabNav }

const DashboardTabsNav = ({ children, actions, activeTab }) => (
  <StyledTabs>
    <TabsWrapper>
      {React.Children.map(children, child => {
        if (!React.isValidElement(child)) return null
        return React.cloneElement(child, {
          ...child.props,
          isActive: child.props.tabId === activeTab,
        })
      })}
    </TabsWrapper>
    {actions && <ActionsWrapper>{actions}</ActionsWrapper>}
  </StyledTabs>
)

DashboardTabsNav.propTypes = {
  children: PropTypes.node.isRequired,
  actions: PropTypes.node,
  activeTab: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

DashboardTabsNav.defaultProps = {
  activeTab: 0,
  actions: null,
}

export default DashboardTabsNav
