import styled from 'styled-components/macro'

export const TableHeaderWithIcon = styled.div`
  color: ${props => props.theme.cBlack};
  font-family: 'Open Sans';
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  line-height: 62px;
  border-top: 1px solid ${props => props.theme.cLightGrey};
  position: relative;

  &:after {
    position: absolute;
    width: 16px;
    height: 16px;
    content: '';
    background-color: ${props => props.theme.cWhite};
    border-right: 1px solid ${props => props.theme.cLightGrey};
    border-bottom: 1px solid ${props => props.theme.cLightGrey};
    transform: rotate(45deg) translateY(-50%) translateX(-50%);
    top: 3px;
    left: 50%;
  }
`

export const TableHeaderWithIconIco = styled.div`
  color: ${props => props.color};
  font-size: 30px;
  position: relative;
  bottom: -5px;
  margin-right: 10px;
`
