import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import {
  ComposableMap,
  ZoomableGroup,
  Geographies,
  Geography,
} from 'react-simple-maps'
import { Tooltip } from 'react-tippy'

import worldMap from 'static/world-50m.json'

import { Wrapper, TooltipWrapper } from './CountriesMap.styles'

class CountriesMap extends PureComponent {
  state = {
    tooltipOpen: false,
    tooltipContent: null,
  }

  setTooltip = (opened, newTooltipContent) => {
    const { tooltipContent } = this.state
    const newState = {
      tooltipOpen: opened,
    }

    if (newTooltipContent !== tooltipContent) {
      newState.tooltipContent = newTooltipContent || null
    }

    this.setState(newState)
  }

  updateTooltipPosition = (e) => {
    const wrapperRect = this.wrapperEl.getBoundingClientRect()
    const x = e.clientX - wrapperRect.x
    const y = e.clientY - wrapperRect.y
    this.tooltipEl.style.left = `${x}px`
    this.tooltipEl.style.top = `${y}px`
    // this.tippyEl.updateTippy();
  }

  onMouseMove = (geo, e) => {
    this.updateTooltipPosition(e)
    this.setTooltip(true, geo.properties.tooltip)
  }

  onMouseLeave = (geo, e) => {
    this.setTooltip(false)
  }

  render() {
    const { zoom, categories, countryMapping, handleClick, tooltip } =
      this.props
    const { tooltipOpen, tooltipContent } = this.state

    return (
      <Wrapper
        ref={(el) => {
          this.wrapperEl = el
        }}
      >
        {tooltip && (
          <TooltipWrapper
            ref={(el) => {
              this.tooltipEl = el
            }}
          >
            <Tooltip
              ref={(el) => {
                this.tippyEl = el
              }}
              open={tooltipOpen}
              position="top"
              animation="fade"
              html={<p>{tooltipContent}</p>}
              theme="light"
              arrow
              duration={200}
            />
          </TooltipWrapper>
        )}
        <ComposableMap
          projectionConfig={{
            scale: 205,
            rotation: [-11, 0, 0],
          }}
          width={980}
          height={550}
          style={{
            width: '100%',
            height: 'auto',
          }}
        >
          <ZoomableGroup center={[0, 20]} zoom={zoom}>
            <Geographies geography={worldMap}>
              {({ geographies }) =>
                geographies.map(countryMapping).map((geography) => {
                  const { fill, stroke } =
                    categories[geography.properties.category || 'default']

                  return (
                    <Geography
                      key={geography.rsmKey}
                      geography={geography}
                      onClick={handleClick}
                      onMouseMove={(e) => {
                        tooltip && this.onMouseMove(geography, e)
                      }}
                      onMouseLeave={tooltip ? this.onMouseLeave : () => {}}
                      style={{
                        default: {
                          fill,
                          stroke,
                          strokeWidth: 0.75,
                          outline: 'none',
                          opacity: 1,
                          transition: 'opacity .2s',
                        },
                        hover: {
                          fill,
                          stroke,
                          strokeWidth: 0.75,
                          outline: 'none',
                          opacity: 0.8,
                        },
                        pressed: {
                          fill,
                          stroke,
                          strokeWidth: 0.75,
                          outline: 'none',
                        },
                      }}
                    />
                  )
                })
              }
            </Geographies>
          </ZoomableGroup>
        </ComposableMap>
      </Wrapper>
    )
  }
}

CountriesMap.propTypes = {
  categories: PropTypes.instanceOf(Object).isRequired,
  zoom: PropTypes.number,
  countryMapping: PropTypes.func,
  handleClick: PropTypes.func,
  tooltip: PropTypes.bool,
}

CountriesMap.defaultProps = {
  zoom: 1,
  countryMapping: (country) => country,
  handleClick: () => {},
  tooltip: false,
}

export default CountriesMap
