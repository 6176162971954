import React, { PureComponent } from 'react'
import styled, { css } from 'styled-components'
import { Formik, Field, Form } from 'formik'
import * as Yup from 'yup'
import Layout from 'components/AdminDashboard/Layout/Layout'
import {
  Row,
  Column,
  ContentBox,
} from 'components/AdminDashboard/Grid/Grid.styles'
import { Card, CardHeader } from 'components/AdminDashboard/Card/Card.styles'
import Button from 'components/Button/Button.styles'
import InputPassword from 'components/Input/InputPassword'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
import Text from 'components/Text/Text.styles'

import {
  AvatarContainer,
  AvatarExpandButton,
  AvatarImg,
  AvatarExpanded,
  FlexCenterContainer,
  AvatarModal,
  FileInputWrapper,
  FileInput,
  AvatarUploadContainer,
} from 'components/Settings/Settings.styles'

import Icon, { iconsMap } from 'components/Icon/Icon.style'
import { FormField, FormError, FormInfo } from 'components/Form/Form'
import Input from 'components/Input/Input'
import Background from 'components/Background/Background'
import { Text as CLText } from '@stokr/components-library'

import {
  AuthConsumer,
  AuthContext,
  Main2FAFlow,
} from '@stokr/components-library'

import avatarPlaceholder from 'static/images/avatar-placeholder.png'
import loader from 'static/images/process-waiting.gif'
import Checkbox from 'components/Checkbox/Checkbox'
import { Caption } from 'components/Checkbox/Checkbox.styles'
import { getUnsubscription, setUnsubscription } from 'api/email-api'
import { withRouter } from 'utils/withRouter'
import Cookies from 'js-cookie'

const CTAContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-left: 20px;
    width: 20px;
  }
`

const ErrorMessage = styled.p`
  color: #ee220d;
`

const ChangePasswordContainer = styled.div`
  height: 0;
  opacity: 0;
  transition: all 0.6s;

  ${({ showChangePassword }) =>
    showChangePassword &&
    css`
      height: unset;
      opacity: 1;
    `}
`

const ShowChangePasswordButtonContainer = styled.div`
  ${({ showChangePassword }) =>
    showChangePassword &&
    css`
      display: none;
    `}
`

class UserAvatarComponent extends React.Component {
  state = {
    finalAvatar: undefined,
    tempAvatar: undefined,
  }

  componentDidMount() {
    const { avatar } = this.props

    this.checkUserPhoto(avatar)
  }

  componentDidUpdate(prevProps) {
    const { avatar, tempAvatar } = this.props

    if (!avatar && avatar !== prevProps.avatar) {
      this.setState({ finalAvatar: undefined })
    }

    if (avatar !== prevProps.avatar) {
      this.checkUserPhoto(avatar)
    }

    if (!tempAvatar && tempAvatar !== prevProps.tempAvatar) {
      this.setState({ tempAvatar: undefined })
    }

    if (tempAvatar && tempAvatar !== prevProps.tempAvatar) {
      this.setState({ tempAvatar, finalAvatar: undefined })
    }
  }

  checkUserPhoto = (avatar) => {
    try {
      const http = new XMLHttpRequest()
      http.open('HEAD', avatar, false)
      http.send()
      // console.log(http);

      if (http.status !== 404) {
        this.setState({
          finalAvatar: avatar,
        })
      }
    } catch (error) {
      console.log('error: ', error)
    }
  }

  render() {
    const { finalAvatar, tempAvatar } = this.state

    return <AvatarImg src={tempAvatar || finalAvatar || avatarPlaceholder} />
  }
}

class AvatarBackgroundComponent extends React.Component {
  state = {
    finalAvatar: undefined,
    tempAvatar: undefined,
  }

  componentDidMount() {
    const { avatar } = this.props

    this.checkUserPhoto(avatar)
  }

  componentDidUpdate(prevProps) {
    const { avatar, tempAvatar } = this.props

    if (!avatar && avatar !== prevProps.avatar) {
      this.setState({ finalAvatar: undefined })
    }

    if (avatar !== prevProps.avatar) {
      this.checkUserPhoto(avatar)
    }

    if (!tempAvatar && tempAvatar !== prevProps.tempAvatar) {
      this.setState({ tempAvatar: undefined })
    }

    if (tempAvatar && tempAvatar !== prevProps.tempAvatar) {
      this.setState({ tempAvatar, finalAvatar: undefined })
    }
  }

  checkUserPhoto = (avatar) => {
    try {
      if (avatar && !avatar.includes('blob:')) {
        const http = new XMLHttpRequest()
        http.open('HEAD', avatar, false)
        http.send()
        // console.log(http);

        if (http.status !== 404) {
          this.setState({
            finalAvatar: avatar,
          })
        }
      } else {
        this.setState({
          finalAvatar: avatar,
        })
      }
    } catch (error) {
      console.log('error: ', error)
    }
  }

  render() {
    const { finalAvatar, tempAvatar } = this.state

    return <Background src={tempAvatar || finalAvatar || avatarPlaceholder} />
  }
}

class AdminTokenHolder extends PureComponent {
  static contextType = AuthContext

  state = {
    isAvatarModalOpen: false,
    newPictureName: '',
    shouldRenderPage: false,
    tempAvatar: undefined,
    isLoading: false,
    isLoadingPassword: false,
    successUpdateUser: false,
    error: undefined,
    errorPassword: undefined,
    showChangePassword: false,
    openEmailModal: false,
    emailSettingsData: null,
  }

  initialValues = {
    username: '',
    name: '',
    lastname: '',
    email: '',
    country: '',
    // nameShared: 'nobody',
    // lastnameShared: 'community',
    // countryShared: 'everyone',
  }

  validationSchema = {
    username: Yup.string(),
    name: Yup.string(),
    lastname: Yup.string(),
    email: Yup.string().email().required('Email is required'),
    // country: Yup.string().required('Country is required'),
  }

  async componentDidMount() {
    const data = await getUnsubscription()
    //console.log('data: ', data)
    this.setState({ emailSettingsData: data.groups, shouldRenderPage: true })
  }

  onCheckboxChange = (id) => {
    var newList = []

    this.state.emailSettingsData.forEach((element) => {
      if (element.id === id) {
        element.suppressed = !element.suppressed
      }
      newList.push(element)
    })

    this.setState({ emailSettingsData: newList })
  }

  handleEmailSettings = async () => {
    var data = { groups: this.state.emailSettingsData }

    try {
      var result = await setUnsubscription(data)
      //console.log(result)
      if (result.success) {
        this.setState({ openEmailModal: false })
      }
    } catch (error) {
      console.log(error)
    }
  }

  toggleAvatarModal = (isAvatarModalOpen) => {
    this.setState({
      isAvatarModalOpen,
    })
  }

  onPhotoChange = (e) => {
    const file = e.target.files[0]

    this.setState({
      newPictureName: file ? file.name : '',
      tempAvatar: URL.createObjectURL(file),
    })
  }

  clearFileInput = (e) => {
    this.fileInput.value = ''

    this.setState({
      newPictureName: '',
      tempAvatar: undefined,
    })
  }

  onPasswordChange = async (values) => {
    this.setState({ isLoadingPassword: true, errorPassword: undefined })
    try {
      this.context.updateUser({ password: values.password })

      window.location.reload()
    } catch (error) {
      this.setState({
        isLoadingPassword: false,
        errorPassword: 'Something went wrong',
      })
    }
  }

  render() {
    const {
      isAvatarModalOpen,
      newPictureName,
      // shouldRenderPage,
      tempAvatar,
      isLoading,
      successUpdateUser,
      isLoadingPassword,
      // error,
      errorPassword,
      showChangePassword,
    } = this.state

    //didn't put in state because I want it to be reset on any page change
    const twoFAModalOpen = localStorage.getItem('2FA_MODAL_OPEN')
    if (twoFAModalOpen) {
      localStorage.removeItem('2FA_MODAL_OPEN')
    }

    return (
      //      <>
      //        {shouldRenderPage && (
      <Layout currentPage="settings">
        {/* <SEO
          title="Admin Dashboard - token holder"
          keywords={['gatsby', 'application', 'react']}
        /> */}
        <Row mainDashboard>
          <Column>
            <ContentBox>
              <Row>
                <Column>
                  <Card>
                    <CardHeader>
                      <Text>
                        <h3>Profile settings</h3>
                      </Text>
                    </CardHeader>

                    <Column noPadding flexEnd>
                      <AuthConsumer>
                        {({ avatar }) => (
                          <AvatarUploadContainer
                            noPaddingHorizontal
                            noPaddingBottom
                          >
                            <AvatarContainer>
                              <AvatarExpandButton
                                onClick={() => this.toggleAvatarModal(true)}
                              >
                                <Icon icon={iconsMap.expand} />
                              </AvatarExpandButton>
                              {/*<AvatarImg src={avatar} />*/}
                              <UserAvatarComponent
                                tempAvatar={tempAvatar}
                                avatar={avatar}
                              />
                            </AvatarContainer>
                            <FileInputWrapper center minWidth="188px">
                              {newPictureName || 'Upload photo'}
                              <FileInput
                                ref={(el) => {
                                  this.fileInput = el
                                }}
                                name="avatar-photo"
                                accept="image/png,image/jpeg"
                                onChange={this.onPhotoChange}
                              />
                            </FileInputWrapper>
                            {/*<Button onlyText>Delete</Button>*/}
                          </AvatarUploadContainer>
                        )}
                      </AuthConsumer>
                    </Column>
                    <Column>
                      <AuthConsumer>
                        {({ user, uploadPhoto, updateUser }) => {
                          if (!user) {
                            return false
                          }
                          return (
                            <Formik
                              initialValues={{
                                username: user.username,
                                name: user.name,
                                lastname: user.lastname,
                                email: user.email,
                                //country: user.country,
                              }}
                              // validationSchema={this.validationSchema}
                              onSubmit={async (data) => {
                                delete data.email

                                this.setState({
                                  isLoading: true,
                                  error: undefined,
                                })

                                try {
                                  if (this.fileInput.files[0]) {
                                    // console.log(auth)
                                    await uploadPhoto(this.fileInput.files[0])
                                    this.clearFileInput()
                                  }

                                  await updateUser(data)

                                  this.setState({
                                    isLoading: false,
                                    successUpdateUser: true,
                                  })
                                  setTimeout(() => {
                                    this.setState({
                                      successUpdateUser: false,
                                    })
                                  }, 3500)

                                  //TODO: fetch the new image instead of reload the page
                                  // reload after upload
                                  // window.location.reload()
                                } catch (error) {
                                  this.setState({
                                    isLoading: false,
                                    error: 'Something went wrong.',
                                  })
                                  console.log(
                                    'error while uploading new avatar',
                                    error,
                                  )
                                }
                              }}
                            >
                              {({
                                errors,
                                touched,
                                setFieldValue,
                                setFieldTouched,
                              }) => {
                                return (
                                  <Form>
                                    <Row>
                                      <Column isSettings>
                                        <ComponentWrapper
                                          noPaddingHorizontal
                                          noPaddingBottom
                                        >
                                          <Field name="username">
                                            {({ field }) => (
                                              <FormField>
                                                <Input
                                                  label="USERNAME"
                                                  id={field.name}
                                                  error={!!errors[field.name]}
                                                  touched={
                                                    !!touched[field.name]
                                                  }
                                                  {...field}
                                                />
                                                <FormError
                                                  show={
                                                    touched[field.name] &&
                                                    errors[field.name]
                                                  }
                                                >
                                                  {errors[field.name]}
                                                </FormError>
                                                <FormInfo marginTop>
                                                  Choose a username. If it’s
                                                  already taken, be creative.
                                                </FormInfo>
                                              </FormField>
                                            )}
                                          </Field>
                                        </ComponentWrapper>
                                      </Column>
                                    </Row>

                                    <Row>
                                      <Column isSettings>
                                        <ComponentWrapper
                                          noPaddingHorizontal
                                          noPaddingBottom
                                        >
                                          <Field name="name">
                                            {({ field }) => (
                                              <FormField>
                                                <Input
                                                  label="First name"
                                                  id={field.name}
                                                  error={!!errors[field.name]}
                                                  touched={
                                                    !!touched[field.name]
                                                  }
                                                  {...field}
                                                />
                                                <FormError
                                                  show={
                                                    touched[field.name] &&
                                                    errors[field.name]
                                                  }
                                                >
                                                  {errors[field.name]}
                                                </FormError>
                                              </FormField>
                                            )}
                                          </Field>
                                        </ComponentWrapper>
                                      </Column>
                                      <Column part={1} />
                                    </Row>
                                    <Row>
                                      <Column isSettings>
                                        <ComponentWrapper
                                          noPaddingHorizontal
                                          noPaddingBottom
                                        >
                                          <Field name="lastname">
                                            {({ field }) => (
                                              <FormField>
                                                <Input
                                                  label="Last name"
                                                  id={field.name}
                                                  error={!!errors[field.name]}
                                                  touched={
                                                    !!touched[field.name]
                                                  }
                                                  {...field}
                                                />
                                                <FormError
                                                  show={
                                                    touched[field.name] &&
                                                    errors[field.name]
                                                  }
                                                >
                                                  {errors[field.name]}
                                                </FormError>
                                              </FormField>
                                            )}
                                          </Field>
                                        </ComponentWrapper>
                                      </Column>
                                      <Column part={1} />
                                    </Row>
                                    <Row>
                                      <Column isSettings>
                                        <ComponentWrapper
                                          noPaddingHorizontal
                                          noPaddingBottom
                                        >
                                          <Field name="email">
                                            {({ field }) => (
                                              <FormField>
                                                <Input
                                                  id={field.name}
                                                  label="Email"
                                                  error={!!errors[field.email]}
                                                  touched={
                                                    !!touched[field.email]
                                                  }
                                                  disabled
                                                  readOnly
                                                  {...field}
                                                />
                                                <FormError
                                                  show={
                                                    touched[field.email] &&
                                                    errors[field.email]
                                                  }
                                                >
                                                  {errors[field.email]}
                                                </FormError>
                                              </FormField>
                                            )}
                                          </Field>
                                        </ComponentWrapper>
                                      </Column>
                                      <Column part={1} />
                                    </Row>
                                    <Row>
                                      <Column part={1} />
                                    </Row>
                                    <Row>
                                      <Column>
                                        <ComponentWrapper noPaddingHorizontal>
                                          <CTAContainer>
                                            <Button
                                              type="submit"
                                              minWidth="150px"
                                            >
                                              Save
                                            </Button>
                                            {isLoading && (
                                              <img src={loader} alt="" />
                                            )}
                                          </CTAContainer>
                                          <CLText
                                            small
                                            success
                                            style={{
                                              position: 'absolute',
                                              bottom: 0,
                                              left: 0,
                                            }}
                                          >
                                            <p>
                                              {successUpdateUser &&
                                                'Profile updated successfully!'}
                                            </p>
                                          </CLText>
                                        </ComponentWrapper>
                                        <ComponentWrapper />
                                      </Column>
                                    </Row>
                                  </Form>
                                )
                              }}
                            </Formik>
                          )
                        }}
                      </AuthConsumer>

                      {/* Password change */}
                      <Row>
                        <Column>
                          <FlexCenterContainer noPaddingHorizontal>
                            <Text>
                              <h5>Reset your password</h5>
                            </Text>
                          </FlexCenterContainer>
                          <Text>
                            <p>
                              Choose a strong password, so you're safe and
                              sound.
                            </p>
                          </Text>
                        </Column>
                      </Row>

                      <ChangePasswordContainer
                        showChangePassword={showChangePassword}
                      >
                        <Formik
                          initialValues={{
                            password: '',
                            confirmPassword: '',
                          }}
                          validationSchema={this.validationSchemaPassword}
                          onSubmit={(values) => {
                            this.onPasswordChange(values)
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleBlur,
                            setFieldValue,
                            setFieldTouched,
                          }) => {
                            const onChangeWithTouch = (e) => {
                              const field = e.target
                              setFieldValue(field.name, field.value, false)
                              setFieldTouched(field.name)
                            }

                            // const submitDisabled =
                            //   values.password !== values.confirmPassword ||
                            //   passwordStrengthMeter(values.password) !== 5

                            const submitDisabled =
                              values.password !== values.confirmPassword ||
                              values.password.length <= 5

                            return (
                              <Form>
                                <Row>
                                  <Column part={6}>
                                    <ComponentWrapper
                                      noPaddingHorizontal
                                      noPaddingBottom
                                    >
                                      <FormField>
                                        <InputPassword
                                          id="reset-password"
                                          name="password"
                                          type="password"
                                          label="Password"
                                          value={values.password}
                                          onChange={onChangeWithTouch}
                                          onBlur={handleBlur}
                                          error={!!errors.password}
                                          touched={!!touched.password}
                                          info="For a stronger password, try a mix of lowercase, capitals, numbers and special characters."
                                          // showStrength
                                        />
                                        <FormError
                                          show={
                                            errors.password && touched.password
                                          }
                                        >
                                          {errors.password}
                                        </FormError>
                                        <FormError
                                          // show={
                                          //   !errors.password &&
                                          //   touched.password &&
                                          //   passwordStrengthMeter(values.password) !== 5
                                          // }
                                          show={
                                            !errors.password &&
                                            touched.password &&
                                            (values.password.length <= 5 ||
                                              values.password !==
                                                values.confirmPassword)
                                          }
                                        >
                                          The passwords must match and have at
                                          least 6 characters
                                        </FormError>
                                      </FormField>
                                    </ComponentWrapper>
                                  </Column>
                                </Row>

                                <Row>
                                  <Column part={6}>
                                    <ComponentWrapper
                                      noPaddingHorizontal
                                      noPaddingBottom
                                    >
                                      <FormField>
                                        <InputPassword
                                          id="confirm-reset-password"
                                          name="confirmPassword"
                                          type="password"
                                          label="Confirm password"
                                          value={values.confirmPassword}
                                          onChange={onChangeWithTouch}
                                          onBlur={handleBlur}
                                          error={!!errors.confirmPassword}
                                          touched={!!touched.confirmPassword}
                                        />
                                        <FormError
                                          show={
                                            errors.confirmPassword &&
                                            touched.confirmPassword
                                          }
                                        >
                                          {errors.confirmPassword}
                                        </FormError>
                                      </FormField>
                                    </ComponentWrapper>
                                  </Column>
                                </Row>

                                <Row>
                                  <Column>
                                    <ComponentWrapper noPaddingHorizontal>
                                      <CTAContainer>
                                        <Button
                                          type="submit"
                                          minWidth="150px"
                                          disabled={submitDisabled}
                                        >
                                          Save
                                        </Button>
                                        {isLoadingPassword && (
                                          <img src={loader} alt="" />
                                        )}
                                      </CTAContainer>
                                    </ComponentWrapper>
                                    {errorPassword && (
                                      <ErrorMessage>
                                        {errorPassword}
                                      </ErrorMessage>
                                    )}
                                    <ComponentWrapper />
                                  </Column>
                                </Row>
                              </Form>
                            )
                          }}
                        </Formik>
                      </ChangePasswordContainer>

                      <ShowChangePasswordButtonContainer
                        showChangePassword={showChangePassword}
                      >
                        <Row>
                          <Column>
                            <ComponentWrapper noPaddingHorizontal>
                              <CTAContainer>
                                <Button
                                  onClick={() =>
                                    this.setState({
                                      showChangePassword: true,
                                    })
                                  }
                                >
                                  Change password
                                </Button>
                              </CTAContainer>
                            </ComponentWrapper>
                            <ComponentWrapper />
                          </Column>
                        </Row>
                      </ShowChangePasswordButtonContainer>

                      {/* Email settings change */}
                      <Row>
                        <Column>
                          <FlexCenterContainer noPaddingHorizontal>
                            <Text>
                              <h5>Change email settings</h5>
                            </Text>
                          </FlexCenterContainer>
                          <Text>
                            <p>
                              Modify the type of emails you receive from us.
                            </p>
                          </Text>
                        </Column>
                      </Row>

                      {this.state.openEmailModal ? (
                        <Row>
                          <Column>
                            {this.state.emailSettingsData &&
                              this.state.emailSettingsData.map((setting) => (
                                <ComponentWrapper
                                  noPaddingHorizontal
                                  noPaddingBottom
                                  key={setting.id}
                                >
                                  <Checkbox
                                    text={
                                      <>
                                        {setting.name.toUpperCase()}
                                        <Caption>{setting.description}</Caption>
                                      </>
                                    }
                                    checked={!setting.suppressed}
                                    id={setting.id.toString()}
                                    textStyle={{ fontWeight: 600 }}
                                    onChange={() =>
                                      this.onCheckboxChange(setting.id)
                                    }
                                  />
                                </ComponentWrapper>
                              ))}

                            {/* <ComponentWrapper /> */}
                            <ComponentWrapper noPaddingHorizontal>
                              <CTAContainer>
                                <Button
                                  onClick={this.handleEmailSettings}
                                  minWidth="150px"
                                >
                                  Save
                                </Button>
                              </CTAContainer>
                            </ComponentWrapper>
                            <ComponentWrapper />
                          </Column>
                        </Row>
                      ) : (
                        <Row>
                          <Column>
                            <ComponentWrapper noPaddingHorizontal>
                              <CTAContainer>
                                <Button
                                  onClick={() =>
                                    this.setState({
                                      openEmailModal: true,
                                    })
                                  }
                                >
                                  Change settings
                                </Button>
                              </CTAContainer>
                            </ComponentWrapper>
                          </Column>
                        </Row>
                      )}

                      {/* 2fa flow */}
                      <ComponentWrapper noPaddingHorizontal>
                        <Main2FAFlow
                          onLoginAgainClick={() => {
                            localStorage.setItem('2FA_MODAL_OPEN', 'true')
                            Cookies.set('STOKR_REDIRECT_URL', '/settings')
                            this.context?.logoutUser()
                            this.props.navigate('/login')
                          }}
                          open2faflow={twoFAModalOpen}
                        />
                      </ComponentWrapper>

                      <></>
                    </Column>
                  </Card>
                </Column>
              </Row>
            </ContentBox>
          </Column>
        </Row>
        <AvatarModal
          isOpen={isAvatarModalOpen}
          onClose={() => this.toggleAvatarModal(false)}
        >
          <AvatarExpanded>
            <AuthConsumer>
              {({ avatar }) => (
                // <Background src={avatar} />}
                <AvatarBackgroundComponent avatar={tempAvatar || avatar} />
              )}
            </AuthConsumer>
          </AvatarExpanded>
        </AvatarModal>
      </Layout>
      //        )}
      //      </>
    )
  }
}

export default withRouter(AdminTokenHolder)
