import styled from 'styled-components/macro'
import grid from 'styles/grid'
import rwd from 'styles/rwd'

export const Wrapper = styled.div`
  height: 100%;
`

export const RowWrapper = styled.div`
  overflow: hidden;
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  margin: -8px;

  ${rwd.Medium`
    margin: -12px;
  `}

  ${(props) =>
    props.center &&
    `
    justify-content: center;
  `}

  ${(props) =>
    props.right &&
    `
    justify-content: right;
  `}

  ${(props) =>
    props.stretch &&
    `
    height: 100%;
    align-items: stretch;
  `}

  ${(props) =>
    props.spaceBetween &&
    `
    justify-content: space-between;
  `}

  ${(props) =>
    props.grow &&
    `
    flex-grow: ${props.grow};
  `}

  ${(props) =>
    props.column &&
    `
    flex-direction: column;
  `}

  ${(props) =>
    props.middle &&
    `
    align-items: center;
  `}

  ${rwd.MLarge`
    ${(props) =>
      props.mainDashboard &&
      `
      z-index: 0;
      min-height: 100vh;
      padding-top: ${props.theme.headerHeight}px;
    `}
  `}
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;

  ${rwd.Medium`
    padding: 12px;
  `}

  ${(props) =>
    props.auto &&
    `
    width: auto;
  `}

  ${(props) =>
    props.part &&
    `
    width: ${grid(props.part, props.full)};
  `}

  ${rwd.Small`
    ${(props) =>
      props.partSm &&
      `
      width: ${grid(props.partSm, props.fullSm)};
    `}
  `}

  ${rwd.Medium`
    ${(props) =>
      props.partMd &&
      `
      width: ${grid(props.partMd, props.fullMd)};
    `}
  `}

  ${rwd.Large`
    ${(props) =>
      props.partLg &&
      `
      width: ${grid(props.partLg, props.fullLg)};
    `}
  `}

  ${rwd.XLarge`
    ${(props) =>
      props.partXl &&
      `
      width: ${grid(props.partXl, props.fullXL)};
    `}
  `}

  ${(props) =>
    props.center &&
    `
    justify-content: center;
  `}

  ${(props) =>
    props.alignCenter &&
    `
    align-items: center;
  `}

  ${(props) =>
    props.spaceBetween &&
    `
    justify-content: space-between;
  `}

  ${(props) =>
    props.grow &&
    `
    flex-grow: ${props.grow};
  `}

  ${(props) =>
    props.flexEnd &&
    `
    justify-content: flex-end;
  `}

  ${(props) =>
    props.noPadding &&
    `
    padding: 0 !important;
  `}

  ${(props) =>
    props.isComments &&
    `
    @media screen and (min-width: 1024px) and (max-width: 1500px) {
      width: 100%;
    }
  `}

  ${(props) =>
    props.isTokenSales &&
    `
    @media screen and (min-width: 768px) and (max-width: 1100px) {
      width: 100%;
    }
  `}

  ${(props) =>
    props.isSettings &&
    `
    max-width: 350px;
    // @media screen and (min-width: 1024px) and (max-width: 1500px) {
    // }
  `}
`

export const ContentBox = styled.div`
  background-color: ${(props) => props.theme.cGrey};
  padding: 16px;
  flex-grow: 1;

  ${rwd.Medium`
    padding: 32px;
  `}

  ${rwd.XLarge`
    padding-left: 74px;
    padding-right: 100px;
  `}
`

export const PageWrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  ${Row} {
    flex-grow: 1;
  }
`
