import React from 'react'
import PropTypes from 'prop-types'
import Slick from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Wrapper } from './Slider.styles'

const Slider = ({
  children,
  autoplay,
  dots,
  arrows,
  infinite,
  rtl = false,
}) => {
  const settings = {
    dots,
    arrows,
    infinite,
    speed: 600,
    autoplay: !!autoplay,
    autoplaySpeed: autoplay,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl,
  }

  return (
    <Wrapper>
      <Slick {...settings}>{children}</Slick>
    </Wrapper>
  )
}

Slider.propTypes = {
  children: PropTypes.node.isRequired,
  autoplay: PropTypes.number,
  dots: PropTypes.bool,
  arrows: PropTypes.bool,
  infinite: PropTypes.bool,
}

Slider.defaultProps = {
  autoplay: 0,
  dots: false,
  arrows: false,
  infinite: true,
}

export default Slider
