import React, { PureComponent } from 'react'
import Layout from 'components/AdminDashboard/Layout/Layout'
// import SEO from 'components/SEO/SEO'
import {
  Row,
  Column,
  ContentBox,
} from 'components/AdminDashboard/Grid/Grid.styles'
import { Card } from 'components/AdminDashboard/Card/Card.styles'
import DashboardTabsNav, {
  DashboardTabNav,
} from 'components/AdminDashboard/DashboardTabsNav/DashboardTabsNav'
import { TeamProvider, TeamConsumer } from 'context/TeamContext/TeamContext'
import Tabs, { Tab } from 'components/Tabs/Tabs'
import TeamOverview from 'components/TeamOverview/TeamOverview'
import TeamMemberItem from 'components/AdminDashboard/TeamMemberItem/TeamMemberItem'
import TeamMemberItemAdd from 'components/AdminDashboard/TeamMemberItemAdd/TeamMemberItemAdd'

import AlertModal from 'components/AdminDashboard/AlertModal/AlertModal'
import { AuthContext, AuthConsumer } from '@stokr/components-library'
import { ProjectContext } from 'context/ProjectContext/ProjectContext'
import Text from 'components/Text/Text.styles'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'

class AdminTeam extends PureComponent {
  state = {
    activeTab: 'members',
    toRemove: null,
    toBulkRemove: null,
    project: undefined,
  }

  componentDidMount() {
    const project = this.context.project

    if (project) {
      this.setState({
        project: project,
      })
    }
  }

  componentDidUpdate() {
    const project = this.context.project

    if (project) {
      this.setState({
        project: project,
      })
    }
  }

  onTabChange = (tabId) => {
    this.setState({
      activeTab: tabId,
    })
  }

  changeToRemove = (id) => {
    this.setState({
      toRemove: id,
    })
  }

  changeToBulkRemove = (users) => {
    this.setState({
      toBulkRemove: users,
    })
  }

  executeBulkAction = (action, users) => {
    switch (action) {
      case 'delete':
        this.changeToBulkRemove(users.filter((member) => member.isSelected))
        break
      default:
        break
    }
  }

  render() {
    const { activeTab, toRemove, toBulkRemove, project } = this.state

    return (
      <TeamProvider>
        <TeamConsumer>
          {({
            users,
            error,
            success,
            toggleMemberSelection,
            addNewMember,
            removeMemberItem,
            removeBulkMemberItem,
          }) => {
            const members = users.filter(
              (user) => !user.email.includes('@stokr.io') && !user.pass_reset,
            )
            //pending users are the ones who have pass_reset field non empty
            //they are all active by default on creation
            // const pending = users.filter(
            //   (user) => !user.email.includes('@stokr.io') && user.pass_reset,
            // )

            return (
              <Layout currentPage="team">
                {/* <SEO
                  title="Admin Dashboard - team"
                  keywords={['gatsby', 'application', 'react']}
                /> */}
                <Row mainDashboard>
                  <Column>
                    <ContentBox>
                      <Row>
                        <Column>
                          <Card>
                            <AuthConsumer>
                              {({ user }) => {
                                let position

                                switch (user.user_type) {
                                  case 'company_sa':
                                    position = 'Company Admin'
                                    break
                                  case 'company_pa':
                                    position = 'STO Admin'
                                    break
                                  default:
                                    position = user.user_type
                                    break
                                }

                                return (
                                  <TeamOverview
                                    name={user.name}
                                    userId={user._id.toString()}
                                    memberRole={user.user_type}
                                    position={position}
                                    email={user.email}
                                    projectName={project && project.name}
                                    stats={[
                                      {
                                        name: 'Members',
                                        count: members.length,
                                      },
                                      // {
                                      //   name: 'Pending',
                                      //   count: pending.length,
                                      // },
                                    ]}
                                  />
                                )
                              }}
                            </AuthConsumer>
                          </Card>
                        </Column>
                      </Row>

                      <Row>
                        <Column>
                          <DashboardTabsNav activeTab={activeTab}>
                            <DashboardTabNav
                              tabId="members"
                              onClick={() => this.onTabChange('members')}
                            >
                              Administrators
                            </DashboardTabNav>
                            {/* <DashboardTabNav
                              tabId="pending"
                              onClick={() => this.onTabChange('pending')}
                            >
                              {`Pending (${pending.length})`}
                            </DashboardTabNav> */}
                          </DashboardTabsNav>
                        </Column>
                      </Row>

                      <Tabs activeTab={activeTab}>
                        <Tab tabId="members">
                          <Row>
                            <AuthConsumer>
                              {({ user }) => {
                                // if user_type === company_pa, don't show invite card
                                if (user.user_type !== 'company_pa')
                                  return (
                                    <Column partMd={8} partLg={1} fullLg={3}>
                                      <TeamMemberItemAdd
                                        id="add-new-member"
                                        name="add-new-member"
                                        title="Add New Administrator"
                                        placeholder="email address"
                                        buttonText="Invite"
                                        addNewMember={addNewMember}
                                        projectName={project && project.name}
                                        error={error}
                                        success={success}
                                      />
                                    </Column>
                                  )
                                return ''
                              }}
                            </AuthConsumer>

                            {members.map((member) => (
                              <Column
                                key={member._id}
                                partMd={8}
                                partLg={1}
                                fullLg={3}
                              >
                                <AuthConsumer>
                                  {({ user }) => {
                                    return (
                                      <TeamMemberItem
                                        id={member._id}
                                        checkboxName={member._id}
                                        name={member.name}
                                        memberRole={member.user_type}
                                        memberEmail={member.email}
                                        currentUserType={user.user_type}
                                        // avatar={`${
                                        //   process.env.REACT_APP_PHOTO_API_URL
                                        // }/media/picture/view/${member._id}`}
                                        isSelected={member.isSelected}
                                        handleIsSelectedChange={
                                          toggleMemberSelection
                                        }
                                        handleRemoveClick={() =>
                                          this.changeToRemove(member._id)
                                        }
                                      />
                                    )
                                  }}
                                </AuthConsumer>
                              </Column>
                            ))}
                          </Row>
                        </Tab>

                        {/* <Tab tabId="pending">
                          <Row>
                            {pending.map((member) => (
                              <Column
                                key={member._id}
                                partMd={8}
                                partLg={1}
                                fullLg={3}
                              >
                                <AuthConsumer>
                                  {({ user }) => {
                                    return (
                                      <TeamMemberItem
                                        id={member._id}
                                        checkboxName={member._id}
                                        name={member.name}
                                        memberRole={member.user_type}
                                        memberEmail={member.email}
                                        currentUserType={user.user_type}
                                        // avatar={`${
                                        //   process.env.REACT_APP_PHOTO_API_URL
                                        // }/media/picture/view/${member._id}`}
                                        isSelected={member.isSelected}
                                        handleIsSelectedChange={
                                          toggleMemberSelection
                                        }
                                        handleRemoveClick={() =>
                                          this.changeToRemove(member._id)
                                        }
                                      />
                                    )
                                  }}
                                </AuthConsumer>
                              </Column>
                            ))}

                            {!pending.length && (
                              <Card grow={1}>
                                <ComponentWrapper noPaddingHorizontal>
                                  <Text>
                                    <p>No team members pending</p>
                                  </Text>
                                </ComponentWrapper>
                              </Card>
                            )}
                          </Row>
                        </Tab> */}
                      </Tabs>
                    </ContentBox>
                  </Column>
                </Row>
                <AlertModal
                  isOpen={toRemove !== null}
                  onModalClose={() => {
                    this.changeToRemove(null)
                  }}
                  onButtonClick={() => {
                    removeMemberItem(toRemove)
                    this.changeToRemove(null)
                  }}
                  warningText="Are you sure you want to remove this team member?"
                  buttonText="Remove"
                />
                <AlertModal
                  isOpen={toBulkRemove !== null}
                  onModalClose={() => {
                    this.changeToBulkRemove(null)
                  }}
                  onButtonClick={() => {
                    removeBulkMemberItem(toBulkRemove)
                    this.changeToBulkRemove(null)
                  }}
                  warningText="Are you sure you want to remove selected team members?"
                  buttonText="Remove"
                />
              </Layout>
            )
          }}
        </TeamConsumer>
      </TeamProvider>
    )
  }
}

export default AdminTeam

AdminTeam.contextType = ProjectContext
