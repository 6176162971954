import styled from 'styled-components/macro'

export const Trend = styled.span`
  display: inline-block;
  font-weight: 600;
  white-space: nowrap;

  ${props =>
    props.inc &&
    `
    color: ${props.theme.cProgressDone};
  `}

  ${props =>
    props.dec &&
    `
    color: ${props.theme.cWarning};
  `}
`

export default Trend
