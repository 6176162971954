import styled from 'styled-components/macro'

export const Container = styled.span`
  display: inline-block;
  white-space: nowrap;
`

export const Flag = styled.img`
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 15px; /* TODO: replace this with auto after import better flags images */
  margin-right: 8px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
`

export const FlagPlaceholder = styled.div`
  display: inline-block;
  height: 15px;
  width: 24px;
  margin-right: 8px;
`

export const Text = styled.span`
  display: inline-block;
  vertical-align: middle;
  font-size: 14px;
  line-height: 20px;
  white-space: normal;
`
