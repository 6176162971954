import React from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  InputWrap,
  ButtonWrap,
  Input,
} from './InputWithButton.styles'

const InputWithButton = ({
  children,
  type,
  id,
  name,
  placeholder,
  value,
  error,
  touched,
  onChange,
  onBlur,
  onFocus,
  separatorTop,
}) => (
  <Container separatorTop={separatorTop}>
    <InputWrap>
      <Input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        placeholder={placeholder}
      />
    </InputWrap>
    <ButtonWrap>{children}</ButtonWrap>
  </Container>
)

InputWithButton.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  separatorTop: PropTypes.bool,
}

InputWithButton.defaultProps = {
  type: 'search',
  placeholder: '',
  value: '',
  error: false,
  touched: false,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  separatorTop: false,
}

export default InputWithButton
