import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import getProfilePicture from 'utils/getProfilePicture'
import { iconsMap } from 'components/Icon/Icon.style'
import badge from 'static/images/badge2.png'

import { Popup } from 'semantic-ui-react'
import {
  Container,
  Head,
  Body,
  Foot,
  Message,
  AvatarBox,
  Avatar,
  AvatarImg,
  BadgeImg,
  Info,
  Name,
  Time,
  Options,
  Option,
  HeadOptions,
  OptionIcon,
  Replies,
} from './CommentItem.styles'
import avatarPlaceholder from 'static/images/avatar-placeholder.png'

const CommentItem = ({
  name,
  time,
  message,
  likes,
  userLike,
  superAdmin,
  isPinned,
  allowReply,
  options,
  replies,
  handleLikeClick,
  handleReplyClick,
  userID,
  project,
}) => {
  const [profilePicture, setProfilePicture] = useState(avatarPlaceholder)

  useEffect(() => {
    const getPicture = async () => {
      const picture = await getProfilePicture(userID)

      setProfilePicture(picture)
    }

    getPicture()
  }, [])

  return (
    <Container>
      <Head>
        <AvatarBox>
          <Avatar>
            <AvatarImg src={profilePicture} alt="avatar" />
          </Avatar>
          {superAdmin && (
            <Popup
              content={`${project.name} admin`}
              trigger={<BadgeImg src={badge} alt="Super admin" />}
              position="top center"
            />
          )}
        </AvatarBox>
        <Info>
          <Name>{name}</Name>
          <Time>{moment(time).fromNow()}</Time>
        </Info>
        <HeadOptions>{options}</HeadOptions>
      </Head>
      <Body>
        <Message isPinned={isPinned}>{message}</Message>
      </Body>
      <Foot>
        <Options>
          <Option onClick={handleLikeClick}>
            <OptionIcon icon={userLike ? iconsMap.heartFill : iconsMap.heart} />
            <span>{`(${likes}) ${likes === 1 ? 'like' : 'likes'}`}</span>
          </Option>
          {allowReply && (
            <Option onClick={handleReplyClick}>
              <OptionIcon icon={iconsMap.pen} />
              <span>reply</span>
            </Option>
          )}
        </Options>
      </Foot>
      {replies && <Replies>{replies}</Replies>}
    </Container>
  )
}

CommentItem.propTypes = {
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  time: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  likes: PropTypes.number,
  userLike: PropTypes.bool,
  superAdmin: PropTypes.bool,
  isPinned: PropTypes.bool,
  allowReply: PropTypes.bool,
  options: PropTypes.node,
  replies: PropTypes.node,
  handleLikeClick: PropTypes.func,
  handleReplyClick: PropTypes.func,
}

CommentItem.defaultProps = {
  likes: 0,
  userLike: false,
  superAdmin: false,
  isPinned: false,
  allowReply: true,
  options: null,
  replies: null,
  handleLikeClick: () => {},
  handleReplyClick: () => {},
  project: {},
}

export default CommentItem
