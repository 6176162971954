import styled, { css } from 'styled-components/macro'
import rwd from 'styles/rwd'
import { SelectWrapper } from 'components/Input/Select.styles'

export const Card = styled.div`
  padding: 28px 22px;
  background: ${props => props.theme.cWhite};

  ${rwd.Medium`
    padding: 40px 50px;

    ${props =>
      props.lessPadding &&
      css`
        padding: 28px 22px;
      `}

    ${props =>
      props.lessPaddingHorizontal &&
      css`
        padding: 40px 22px;
      `}

    ${props =>
      props.lessPaddingVertical &&
      css`
        padding: 28px 50px;
      `}

    ${props =>
      props.chartPadding &&
      css`
        padding: 20px;
      `}

    ${props =>
      props.noPadding &&
      css`
        padding: 0;
      `}
  `}

  ${props =>
    props.chartPadding &&
    css`
      padding: 10px;
    `}

  ${props =>
    props.noPadding &&
    css`
      padding: 0;
    `}

  ${props =>
    props.grow &&
    `
    flex-grow: ${props.grow};
  `}
`

export const CardTitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
`

export const CardTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const CardRightText = styled.div`
  margin-left: auto;
  font-size: 16px;
`

export const CardLeftText = styled.div`
  margin-left: 18px;
  font-size: 16px;
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  ${props =>
    props.paddingRight &&
    css`
      padding-right: ${props.paddingRight};
    `}

  ${SelectWrapper} {
    height: 22px;
    margin-top: -8px;
  }
`

export const CardHeaderRegular = styled.div`
  font-weight: 400;
  display: inline-block;
`
