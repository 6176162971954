import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Label, Input } from './Checkbox.styles'

const Checkbox = ({
  id,
  name,
  text,
  label,
  value,
  checked,
  large,
  error,
  touched,
  onChange,
  onBlur,
  onFocus,
}) => (
  <Wrapper>
    {label && (
      <Label error={error && touched} htmlFor={id}>
        {label}
      </Label>
    )}
    <Input error={error} large={large}>
      <input
        type="checkbox"
        id={id}
        name={name || id}
        value={value}
        checked={checked}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
      <label htmlFor={id}>{text}</label>
    </Input>
  </Wrapper>
)

export const CheckboxPropTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  large: PropTypes.bool,
  error: PropTypes.bool,
  touched: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
}

Checkbox.propTypes = CheckboxPropTypes

Checkbox.defaultProps = {
  name: '',
  text: '',
  label: '',
  value: '',
  checked: false,
  large: false,
  error: false,
  touched: false,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
}

export default Checkbox
