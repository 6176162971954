import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

import {
  Chart,
  Container,
  HeatmapGradientLegend,
  HeatmapGradient,
  HeatmapLabel,
} from './Heatmap.styles'

class Heatmap extends PureComponent {
  transitionDuration = 300

  transitionDatumDelay = 20

  constructor(props) {
    super(props)

    const maxVal = Math.max(...props.data.map(obj => obj.value))
    this.state = {
      range: {
        colors: props.range.colors,
        steps: [0, maxVal],
      },
    }
  }

  componentDidMount() {
    this.createChart()
    window.addEventListener('resize', this.onResize)
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  componentWillUnmount() {
    clearTimeout(this.resizeTimeout)
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('scroll', this.onScroll)
  }

  onResize = () => {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(this.onResizeTimeout, 200)
  }

  onResizeTimeout = () => {
    this.updateChart(true)
  }

  onScroll = e => {
    if (this.drawn) return
    if (this.chartEl.getBoundingClientRect().top < window.innerHeight) {
      this.initialDrawChart()
    }
  }

  initialDrawChart = () => {
    this.updateChart()
    this.drawn = true
  }

  createChart = () => {
    if (!this.chartEl) return

    const { range } = this.state

    const { daysLabels, hoursLabels, data } = this.props

    const wrapperWidth = this.chartEl.offsetWidth
    const wrapperHeight = 520
    const margin = {
      top: 0,
      left: 0,
      right: 50,
      bottom: 50,
    }
    const width = wrapperWidth - (margin.left + margin.right)
    const height = wrapperHeight - (margin.top + margin.bottom)

    const radius = 3

    this.svg = d3
      .select(this.chartEl)
      .append('svg')
      .attr('width', wrapperWidth)
      .attr('height', wrapperHeight)

    this.chart = this.svg
      .append('g')
      .attr('width', width)
      .attr('height', height)

    const colorRange = d3
      .scaleLinear()
      .range(range.colors)
      .domain(range.steps)

    const xScale = d3
      .scaleBand()
      .range([0, width])
      .domain(daysLabels)
      .paddingInner(0.2)
      .paddingOuter(0)

    const yScale = d3
      .scaleBand()
      .range([0, height])
      .domain(hoursLabels)
      .paddingInner(0.2)
      .paddingOuter(0)

    this.chart
      .selectAll()
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'box')
      .attr('x', d => xScale(d.group))
      .attr('y', d => yScale(d.variable))
      .attr('width', xScale.bandwidth())
      .attr('height', yScale.bandwidth())
      .attr('rx', radius)
      .attr('ry', radius)
      .style('fill', colorRange(range.steps[0]))

    this.xAxis = this.chart
      .append('g')
      .attr('class', 'axisX')
      .attr('transform', `translate(0, ${height + 5})`)
      .call(d3.axisBottom().scale(xScale))

    this.yAxis = this.chart
      .append('g')
      .attr('class', 'axisY')
      .attr('transform', `translate(${wrapperWidth}, 0)`)
      .call(d3.axisLeft().scale(yScale))
  }

  updateChart = resizeUpdate => {
    if (!this.chartEl) return

    const { range } = this.state

    const { daysLabels, hoursLabels, data } = this.props

    const durration = this.transitionDuration
    const delay = this.transitionDatumDelay

    const wrapperWidth = this.chartEl.offsetWidth
    const wrapperHeight = 520
    const margin = {
      top: 0,
      left: 0,
      right: 50,
      bottom: 50,
    }
    const width = wrapperWidth - (margin.left + margin.right)
    const height = wrapperHeight - (margin.top + margin.bottom)

    this.svg.attr('width', wrapperWidth).attr('height', wrapperHeight)

    this.chart.attr('width', width).attr('height', height)

    const colorRange = d3
      .scaleLinear()
      .range(range.colors)
      .domain(range.steps)

    const xScale = d3
      .scaleBand()
      .range([0, width])
      .domain(daysLabels)
      .paddingInner(0.2)
      .paddingOuter(0)

    const yScale = d3
      .scaleBand()
      .range([0, height])
      .domain(hoursLabels)
      .paddingInner(0.2)
      .paddingOuter(0)

    this.xAxis
      .transition()
      .duration(durration)
      .attr('transform', `translate(0, ${height + 5})`)
      .call(d3.axisBottom().scale(xScale))

    this.yAxis
      .transition()
      .duration(durration)
      .attr('transform', `translate(${wrapperWidth}, 0)`)
      .call(d3.axisLeft().scale(yScale))

    const boxes = this.chart.selectAll('.box')
    boxes
      .data(data)
      .transition()
      .duration(durration)
      .attr('x', d => xScale(d.group))
      .attr('y', d => yScale(d.variable))
      .attr('width', xScale.bandwidth())
      .attr('height', yScale.bandwidth())

    !resizeUpdate &&
      boxes
        .data(data)
        .attr('x', d => xScale(d.group))
        .attr('y', d => yScale(d.variable))
        .attr('width', xScale.bandwidth())
        .attr('height', yScale.bandwidth())
        .transition()
        .delay((_, index) => (resizeUpdate ? 0 : index * delay))
        .duration(durration)
        .style('fill', datum => colorRange(datum.value))
  }

  render() {
    const { range } = this.state
    const { center } = this.props
    return (
      <Container center={center}>
        <Chart
          ref={el => {
            this.chartEl = el
          }}
        />
        <HeatmapGradientLegend>
          <HeatmapGradient
            beginColor={range.colors[0]}
            endColor={range.colors[1]}
          />
          <HeatmapLabel beginLabel>{range.steps[0]}</HeatmapLabel>
          <HeatmapLabel endLabel>{range.steps[1]}</HeatmapLabel>
        </HeatmapGradientLegend>
      </Container>
    )
  }
}

Heatmap.propTypes = {
  daysLabels: PropTypes.arrayOf(Object).isRequired,
  hoursLabels: PropTypes.arrayOf(Object).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      group: PropTypes.string.isRequired,
      variable: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired,
    }),
  ).isRequired,
  range: PropTypes.objectOf(Object).isRequired,
  center: PropTypes.bool,
}

Heatmap.defaultProps = {
  center: false,
}

export default Heatmap
