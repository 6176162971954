import styled, { css } from 'styled-components/macro'
import PropTypes from 'prop-types'

const Button = styled.button.attrs(props => ({
  type: props.type || 'button',
}))`
  display: inline-block;
  height: auto;
  min-height: 40px;
  max-width: 100%;
  background-color: ${props => props.theme.cPrimary};
  border: 1px solid ${props => props.theme.cPrimary};
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: ${props => props.theme.cWhite};
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  border-radius: 28px;
  padding: 11px 32px;
  margin: 0;
  outline: 0;
  vertical-align: baseline;
  text-shadow: none;
  user-select: none;
  cursor: pointer;
  transition: color .2s, background-color .2s, border-color .2s;

  ${({ isUpdates, isCancel }) =>
    isUpdates &&
    isCancel &&
    css`
      padding: 11px 0;
      margin-left: 32px;
    `}

  ${({ isUpdates, isDone }) =>
    isUpdates &&
    isDone &&
    css`
      margin-left: 32px;

      @media screen and (max-width: 850px) {
        padding: 11px 20px;
      }
    `}

  ${({ withMarginBottom }) =>
    withMarginBottom &&
    css`
      margin-bottom: 32px;
    `}

  ${props =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `}

  ${props =>
    props.fluid &&
    css`
      display: block;
      width: 100%;
      padding: 11px 12px;
    `}

  ${props =>
    props.center &&
    css`
      margin-left: auto;
      margin-right: auto;
    `}

  &:hover,
  &:focus,
  &:active {
    background-color: ${props => props.theme.cPrimaryDark};
    border-color: ${props => props.theme.cPrimaryDark};
    color: ${props => props.theme.cWhite};
  }

  ${props =>
    props.secondary &&
    css`
      background-color: ${props.theme.cWhite};
      color: ${props.theme.cPrimary};

      &:hover,
      &:focus,
      &:active {
        background-color: ${props.theme.cPrimary};
        border-color: ${props.theme.cPrimary};
        color: ${props.theme.cWhite};
      }
    `}

  ${props =>
    props.onlyText &&
    css`
      border-color: transparent;
      background-color: transparent;
      color: ${props.theme.cBlack};

      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
        border-color: transparent;
        color: ${props.theme.cPrimary};
      }
    `}

  ${props =>
    props.disabled &&
    css`
      border-color: ${props.theme.cBlack};
      background-color: ${props.theme.cWhite};
      color: ${props.theme.cBlack};
      opacity: 0.2;
      cursor: default;
      box-shadow: none !important;
      pointer-events: none !important;

      &:hover,
      &:focus,
      &:active {
        background-color: ${props.theme.cPrimary};
        border-color: ${props.theme.cPrimary};
        color: ${props.theme.cWhite};
      }
    `}

  ${props =>
    props.negative &&
    css`
      border-color: ${props.theme.cWarning};
      background-color: ${props.theme.cWhite};
      color: ${props.theme.cWarning};

      &:hover,
      &:focus,
      &:active {
        background-color: ${props.theme.cWhite};
        border-color: ${props.theme.cWarningDark};
        color: ${props.theme.cWarningDark};
      }
    `}

  ${props =>
    props.outline &&
    css`
      border-color: ${props.theme.cWhite};
      background-color: transparent;
      color: ${props.theme.cWhite};

      &:hover,
      &:focus,
      &:active {
        background-color: ${props.theme.cWhite};
        border-color: ${props.theme.cWhite};
        color: ${props.theme.cWarning};
      }
    `}
`

Button.propTypes = {
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  negative: PropTypes.bool,
  center: PropTypes.bool,
}

Button.defaultProps = {
  secondary: false,
  disabled: false,
  negative: false,
  center: false,
}

export default Button
