import React from 'react'
import PropTypes from 'prop-types'
import { iconsMap } from 'components/Icon/Icon.style'

import {
  Container,
  Info,
  IconWrap,
  StyledIcon,
  Name,
  Counter,
} from './CategoryButton.styles'

const CategoryButton = ({
  name,
  icon,
  color,
  counter,
  handleClick,
  isActive,
  side,
  notClickable,
}) => (
  <Container
    onClick={handleClick}
    color={color}
    isActive={isActive}
    side={side}
    notClickable={notClickable}
  >
    <Info>
      <IconWrap color={color}>
        <StyledIcon icon={iconsMap[icon]} />
      </IconWrap>
      <Name>{name}</Name>
    </Info>
    <Counter>{counter}</Counter>
  </Container>
)

CategoryButton.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  counter: PropTypes.number,
  handleClick: PropTypes.func,
  isActive: PropTypes.bool,
  side: PropTypes.bool,
}

CategoryButton.defaultProps = {
  counter: 0,
  handleClick: () => {},
  isActive: false,
  side: false,
}

export default CategoryButton
