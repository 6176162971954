import React from 'react'
import PropTypes from 'prop-types'
// import { iconsMap } from 'components/Icon/Icon.style'
// import SidebarItem from 'components/AdminDashboard/SidebarItem/SidebarItem'
import ComponentScroll from 'components/ComponentScroll/ComponentScroll'
import { StyledSidebar, ItemsContainer, ItemsWrapper } from './Sidebar.styles'

const Sidebar = ({ children, isSidebarExpanded }) => (
  <StyledSidebar isSidebarExpanded={isSidebarExpanded}>
    <ItemsContainer>
      <ComponentScroll>
        <ItemsWrapper>{children}</ItemsWrapper>
      </ComponentScroll>
    </ItemsContainer>
    {/*
    <SidebarItem
      icon={iconsMap.logout}
      title="Logout"
      isExpanded={isSidebarExpanded}
    />
    */}
  </StyledSidebar>
)

Sidebar.propTypes = {
  children: PropTypes.node.isRequired,
  isSidebarExpanded: PropTypes.bool,
}

Sidebar.defaultProps = {
  isSidebarExpanded: false,
}

export default Sidebar
