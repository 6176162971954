import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Card,
  CardHeader,
  CardTitle,
} from 'components/AdminDashboard/Card/Card.styles'
import ButtonTabs, {
  ButtonTab,
} from 'components/AdminDashboard/ButtonTabs/ButtonTabs' // ButtonTab,
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
import Tabs, { Tab } from 'components/Tabs/Tabs'
import BarChart from 'components/BarChart/BarChart'
import { BarChartTooltip } from 'components/BarChart/BarChart.styles'
import Slider from 'components/Slider/Slider'
import ChartContainer from './OverviewChart.styles'
import LineChart from '../LineChart/LineChart'
import { LineChartTooltip } from '../LineChart/LineChart.styles'

const createBarChartTooltip = (value, tokenSymbol, tokenPrice) => (
  <BarChartTooltip>
    <h3>
      {value.toLocaleString()}
      {` ${tokenSymbol}`}
    </h3>
    <p>
      {'= € '}
      {(value * tokenPrice).toFixed(2)}
    </p>
  </BarChartTooltip>
)
const createLineChartTooltip = (value, tokenSymbol, tokenPrice) => (
  <LineChartTooltip>
    <h3>
      {value.toLocaleString()}
      {` ${tokenSymbol}`}
    </h3>
    <p>
      {'= € '}
      {(value * tokenPrice).toFixed(2)}
    </p>
  </LineChartTooltip>
)

const OverviewChart = ({
  title,
  barChartData,
  barChartLegend,
  id,
  colorTheme,
  tokenSymbol,
  tokenPrice,
}) => {
  //const [period] = useState('1m')
  const [period, setPeriod] = useState('max')

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <ButtonTabs activeTab={period}>
          <ButtonTab
            tabId="max"
            onClick={() => {
              setPeriod('max')
            }}
          >
            max
          </ButtonTab>
          <ButtonTab
            tabId="1y"
            onClick={() => {
              setPeriod('1y')
            }}
          >
            1y
          </ButtonTab>

          <ButtonTab
            tabId="1m"
            onClick={() => {
              setPeriod('1m')
            }}
          >
            1m
          </ButtonTab>
          <ButtonTab
            tabId="1w"
            onClick={() => {
              setPeriod('1w')
            }}
          >
            1w
          </ButtonTab>
          {/* <ButtonTab
            tabId="1d"
            onClick={() => {
              setPeriod('1d')
            }}
          >
            1d
          </ButtonTab> */}
        </ButtonTabs>
      </CardHeader>

      <ChartContainer
        noPaddingBottom
        noPaddingHorizontal
        colorTheme={colorTheme}
      >
        <Tabs activeTab={period}>
          <Tab tabId="1y">
            <Slider arrows infinite={false}>
              {barChartData.years.map((year, index) => (
                // <BarChart
                //   key={year.title}
                //   id={`${id}${index}BarChartYear`}
                //   colorTheme={colorTheme}
                //   title={year.title}
                //   data={year.values.map((datum) => ({
                //     ...datum,
                //     tooltip: createBarChartTooltip(
                //       datum.value,
                //       tokenSymbol,
                //       tokenPrice,
                //     ),
                //   }))}
                // />
                <LineChart
                  key={year.title}
                  id={`${id}${index}LineChartYear`}
                  // colorTheme={colorTheme}
                  fill="#ee220d"
                  stroke="#ee220d"
                  title={year.title}
                  data={year.values.map((datum) => ({
                    ...datum,
                    tooltip: createLineChartTooltip(
                      datum.value,
                      tokenSymbol,
                      tokenPrice,
                    ),
                  }))}
                  months={year.values.map((a) => a.name)}
                />
              ))}
            </Slider>
          </Tab>
          <Tab tabId="1m">
            <Slider arrows infinite={false}>
              {barChartData.months.reverse().map((month, index) => (
                // <BarChart
                //   key={month.title}
                //   id={`${id}${index}BarChartMonth`}
                //   colorTheme={colorTheme}
                //   title={month.title}
                //   data={month.values.map((datum) => ({
                //     ...datum,
                //     tooltip: createBarChartTooltip(
                //       datum.value,
                //       tokenSymbol,
                //       tokenPrice,
                //     ),
                //   }))}
                // />

                <LineChart
                  key={month.title}
                  id={`${id}${index}LineChartMonth`}
                  // colorTheme={colorTheme}
                  fill="#ee220d"
                  stroke="#ee220d"
                  title={month.title}
                  data={month.values.map((datum) => ({
                    ...datum,
                    tooltip: createLineChartTooltip(
                      datum.value,
                      tokenSymbol,
                      tokenPrice,
                    ),
                  }))}
                  months={month.values.map((a) => a.name)}
                />
              ))}
            </Slider>
          </Tab>
          <Tab tabId="1w">
            <Slider arrows infinite={false}>
              {barChartData.weeks.map((week, index) => (
                // <BarChart
                //   key={week.title}
                //   id={`${id}${index}BarChartWeek`}
                //   colorTheme={colorTheme}
                //   title={week.title}
                //   data={week.values.map((datum) => ({
                //     ...datum,
                //     tooltip: createBarChartTooltip(
                //       datum.value,
                //       tokenSymbol,
                //       tokenPrice,
                //     ),
                //   }))}
                // />
                <LineChart
                  key={week.title}
                  id={`${id}${index}LineChartWeek`}
                  colorTheme={colorTheme}
                  fill="#ee220d"
                  stroke="#ee220d"
                  title={week.title}
                  data={week.values.map((datum) => ({
                    ...datum,
                    tooltip: createLineChartTooltip(
                      datum.value,
                      tokenSymbol,
                      tokenPrice,
                    ),
                  }))}
                  months={week.values.map((a) => a.name)}
                />
              ))}
            </Slider>
          </Tab>
          <Tab tabId="max">
            <Slider arrows infinite={false}>
              {barChartData.max.map((max, index) => (
                // <BarChart
                //   key={max.title}
                //   id={`${id}${index}BarChartMax`}
                //   colorTheme={colorTheme}
                //   title={max.title}
                //   data={max.values.map((datum) => ({
                //     ...datum,
                //     tooltip: createBarChartTooltip(
                //       datum.value,
                //       tokenSymbol,
                //       tokenPrice,
                //     ),
                //   }))}
                // />
                <LineChart
                  key={max.title}
                  id={`${id}${index}LineChartAllTime`}
                  colorTheme={colorTheme}
                  fill="#ee220d"
                  stroke="#ee220d"
                  title={max.title}
                  splitName
                  data={max.values.map((datum) => ({
                    ...datum,
                    tooltip: createLineChartTooltip(
                      datum.value,
                      tokenSymbol,
                      tokenPrice,
                    ),
                  }))}
                  months={max.values.map((a) => a.name)}
                />
              ))}
            </Slider>
          </Tab>
        </Tabs>
      </ChartContainer>

      <ComponentWrapper noPaddingBottom noPaddingHorizontal>
        {barChartLegend}
      </ComponentWrapper>
    </Card>
  )
}

OverviewChart.propTypes = {
  title: PropTypes.string.isRequired,
  barChartData: PropTypes.shape({
    years: PropTypes.array,
    months: PropTypes.array,
    weeks: PropTypes.array,
    days: PropTypes.array,
  }).isRequired,
  barChartLegend: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  colorTheme: PropTypes.string,
}

OverviewChart.defaultProps = {
  colorTheme: 'red',
}

export default OverviewChart
