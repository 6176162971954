import styled from 'styled-components/macro'
import rwd from 'styles/rwd'

export const Container = styled.div`
  position: relative;
  width: 100%;
  font-size: 0;

  @media screen and (min-width: 768px) and (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`

export const ImageWrapper = styled.div`
  overflow: hidden;
  display: block;
  margin: 0 auto;
  width: 160px;
  height: 160px;
  border-radius: 80px;
  box-shadow: none;
  border: 1px solid rgb(225, 225, 225);

  ${rwd.Medium`
    z-index: 1;
    position: relative;
    display: inline-block;
    vertical-align: top;
    margin: 0;
    margin-right: -160px;
  `}

  @media screen and (min-width: 768px) and (max-width: 1000px) {
    margin: 0 auto;
  }
`

export const Avatar = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
`

export const ContentWrapper = styled.div`
  display: block;
  width: 100%;

  ${rwd.Medium`
    z-index: 0;
    position: relative;
    display: inline-block;
    vertical-align: top;
    padding-left: 160px;
  `}

  @media screen and (min-width: 768px) and (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: 768px) and (max-width: 1000px) {
    padding-left: 0;
  }
`

export const Name = styled.h3`
  position: relative;
  font-family: 'Open Sans';
  font-weight: 800;
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 1.2px;
  margin: 0;
  text-transform: uppercase;
  margin-top: 4px;
`

export const ContentGroup = styled.div`
  display: block;
  padding-top: 32px;

  ${rwd.Medium`
    display: inline-block;
    vertical-align: top;
    width: 50%;
    padding-top: 0;
    padding-left: 50px;
  `}

  @media screen and (min-width: 768px) and (max-width: 900px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) and (max-width: 1000px) {
    margin-top: 32px;
    padding-left: 0;
  }
`

export const SmallHeader = styled.div`
  display: block;

  svg {
    display: inline-block;
    vertical-align: middle;
    fill: ${props => props.theme.cPrimary};
    margin: -4px 0;
    margin-right: 12px;
  }

  span {
    display: inline-block;
    vertical-align: middle;
    font-size: 11px;
    line-height: 16px;
    text-transform: uppercase;
    padding: 4px 0;
  }
`

export const ProjectStats = styled.div`
  display: block;
  position: relative;
  margin-top: 25px;
`

export const ProjectStat = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
`

export const StatLabel = styled.div`
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;
  padding: 4px 0;
`

export const StatValue = styled.div`
  font-weight: 300;
  font-size: 16px;
  line-height: 16px;
  margin-top: 4px;
`

export const PersonPosition = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  color: ${props => props.theme.cPrimary};
  margin-top: 15px;
`

export const PersonEmail = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  margin-top: 20px;
`
