import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import './datePicker.style.css'

import { Modal } from 'components/Modal/Modal'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
// import TextEditor from 'components/AdminDashboard/TextEditor/TextEditor'
import Button from 'components/Button/Button.styles'
import Text from 'components/Text/Text.styles'
import { DayPicker } from 'react-day-picker'
import moment from 'moment'
import { PDFDownloadLink } from '@react-pdf/renderer'
import {
  InvestorRegisterDoc,
  transferTypes,
} from 'pages/admin-dashboard/token-holder'
import Project from 'context/ProjectContext/Project'
import { ProjectContext } from 'context/ProjectContext/ProjectContext'
import { ModalScroll } from 'components/Modal/Modal.styles'
import { fixDecimals } from 'utils'

// Render a new image everytime, avoiding jump on the spinning
const gif = `https://res.cloudinary.com/stokr/image/upload/f_auto,q_auto/v1575035788/Static/stokr_loader_white_400x400_egnhgb.gif?a=${Math.random()}`

const RequestInvestorsListModal = ({
  isModalOpen,
  onModalClose,
  project = {},
  investorRegister = {},
  closeModal = () => {},
}) => {
  const { patchTokenHolders } = useContext(ProjectContext)

  const [selectedDate, setselectedDate] = useState(new Date())
  const [startingDate, setstartingDate] = useState(new Date(2016))
  const [isLoadingData, setisLoadingData] = useState(false)
  const [errorMessage, seterrorMessage] = useState()
  const [successMessage, setsuccessMessage] = useState()
  const [renderDoc, setrenderDoc] = useState(false)
  const [newInvestorRegister, setnewInvestorRegister] =
    useState(investorRegister)

  React.useEffect(() => {
    //set the starting date for the date picker from the earliest date in the investor register
    if (investorRegister.length) {
      const earliestTxDate =
        getEarliestDateFromInvestorRegister(investorRegister)

      setstartingDate(earliestTxDate)
    }
  }, [investorRegister])

  const downloadFile = (blob, url, filename = 'pdfExample.pdf') => {
    if (blob && url) {
      const link = document.createElement('a')
      link.href = url
      link.download = filename
      link.click()
      setsuccessMessage(
        'You have successfully downloaded the Investor Register',
      )
      setrenderDoc(false)
    }
  }

  const downloadCSVFile = (
    tokenHolders = investorRegister,
    projectData = project,
  ) => {
    //in csv file we display transfers with investor info for each transfer
    let dataForCSV = []

    const redemptionSale = projectData?.sales.find((sale) => sale.isRedemption)

    tokenHolders.forEach((holder) => {
      holder?.txData.forEach((transfer) => {
        let csvRow = {
          'Investor name': holder?.fullName,
          'Investor email': holder.email,
          'Number of securities': holder.balance,
          'Investor wallet address': holder.wallets
            ?.map((wallet) => wallet.address)
            .join(', '),
          Country: holder.country,
          'Tax ID': holder.taxId,
          Date: moment(transfer.time)
            .format('DD MMM YYYY - HH:mm')
            .toUpperCase(),
          'Type of movement': transfer.type,
          'Number of securities moved': ' ' + transfer.amount,
          'Total balance after movement': ' ' + transfer.balanceAfterTx, //hack to avoid excel parse number as dates
          'Value of the movement':
            transfer?.currencyAmount && fixDecimals(transfer.currencyAmount, 2),
          Transferor:
            transfer.type === transferTypes.ISSUANCE
              ? 'Issuer'
              : transfer.sender,
          Transferee:
            transfer.type === transferTypes.REDEMPTION
              ? 'Issuer'
              : transfer.recipient,
          'Transaction hash': transfer.txid,
          Asset: transfer.asset,
        }

        if (
          project.name === 'blockstream-mining' &&
          transfer.type === transferTypes.REDEMPTION &&
          redemptionSale?.bmnInEur
        ) {
          csvRow['Value of the movement'] = fixDecimals(
            transfer.amount * redemptionSale.bmnInEur,
            2,
          )
        }
        dataForCSV.push(csvRow)
      })
    })

    const items = dataForCSV
    const replacer = (key, value) => (value === null ? '' : value) // specify how you want to handle null values here
    const header = Object.keys(items[0])
    const csv = [
      ['sep=,'], //make the file comma delimited (separate columns by comma)
      header.join(','), // header row first
      ...items.map((row) =>
        header
          .map((fieldName) => JSON.stringify(row[fieldName], replacer))
          .join(','),
      ),
    ].join('\r\n')

    var hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
    hiddenElement.target = '_blank'
    hiddenElement.download = `Investor_register_${project.title}_${moment(
      selectedDate,
    ).format('DD_MM_YYYY')}.csv`
    hiddenElement.click()

    //---
    setsuccessMessage('You have successfully downloaded the Investor Register')
    setisLoadingData(false)
  }

  const onConfirmDownloadClick = async (type) => {
    setisLoadingData(true)

    const today = new Date()
    today.setHours(0, 0, 0, 0)

    //case where users pick a snapshot date in the past for the investor register
    if (selectedDate < today) {
      //set hours to include the whole day based on UTC time
      const snapDate =
        moment(selectedDate).format('YYYY-MM-DD') + 'T23:59:59.999Z'

      try {
        const newInvestorRegister = await Project.getInvestorRegister(
          project,
          snapDate,
        )

        if (newInvestorRegister.length === 0) {
          //case where investor register is empty
          seterrorMessage('Investor Register is empty for the selected date')
          setisLoadingData(false)
        } else {
          const finalNewInvestorRegister = patchTokenHolders(
            newInvestorRegister,
            project,
          )
          setnewInvestorRegister(finalNewInvestorRegister)

          switch (type) {
            case 'pdf':
              setrenderDoc(true)
              break
            case 'csv':
              downloadCSVFile(finalNewInvestorRegister)
              break
            default:
              break
          }
        }
      } catch (error) {
        console.log(
          '🚀 ~ file: RequestInvestorsListModal.js:74 ~ error:',
          error,
        )
        seterrorMessage('Something went wrong, please try again later')
        setisLoadingData(false)
      }
    } else {
      //setTimeout is used to in order to show the spinner correctly
      //bc generating pdf frezes the UI
      switch (type) {
        case 'pdf':
          setTimeout(() => {
            setrenderDoc(true)
          }, 1000)
          break
        case 'csv':
          downloadCSVFile()
          break
        default:
          break
      }
    }
  }

  return (
    <Modal
      thin
      isOpen={isModalOpen}
      onClose={isLoadingData ? () => {} : onModalClose}
    >
      <ModalScroll>
        <ComponentWrapper center>
          <Text>
            <h3>
              {successMessage
                ? 'Investor register downloaded'
                : isLoadingData
                ? 'Generating investor register'
                : 'Download investor register'}
            </h3>
          </Text>
        </ComponentWrapper>
        {isLoadingData ? (
          <>
            <ComponentWrapper noPaddingTop center>
              <Text>
                <p>
                  We are fetching the data from the blockchain. This can take up
                  to 5 minutes.
                </p>
              </Text>
            </ComponentWrapper>
            <ComponentWrapper center>
              <img src={gif} alt="&nbsp;" width={200} height={200} />
            </ComponentWrapper>
          </>
        ) : errorMessage ? (
          <>
            <ComponentWrapper noPaddingTop center>
              <Text>
                <p>{errorMessage}</p>
              </Text>
            </ComponentWrapper>
          </>
        ) : successMessage ? (
          <>
            <ComponentWrapper noPaddingTop center>
              <Text>
                <p>{successMessage}</p>
              </Text>
            </ComponentWrapper>
          </>
        ) : (
          <>
            <ComponentWrapper center>
              <Text>
                <p>
                  You can now download the Investor Register that corresponds to
                  a specific date of your choice. If no date is selected, we’ll
                  provide you with the most recent version.
                </p>
              </Text>
            </ComponentWrapper>

            <ComponentWrapper noPaddingTop>
              <DayPicker
                mode="single"
                selected={selectedDate}
                //onSelect={setselectedDate}
                onDayClick={(day) => {
                  //set local hours to include the whole day
                  //for display date in investor register
                  day.setHours(23, 59, 59, 999)
                  setselectedDate(day)
                }}
                fromDate={startingDate}
                weekStartsOn={1}
                captionLayout="dropdown-buttons"
                fixedWeeks
                toDate={new Date()}
              />
            </ComponentWrapper>
            <ComponentWrapper
              noPaddingTop
              center
              flex
              style={{ justifyContent: 'center' }}
            >
              <Button
                onClick={() => onConfirmDownloadClick('pdf')}
                style={{ margin: '5px 10px' }}
              >
                {' '}
                Download in pdf
              </Button>
              <Button
                onClick={() => onConfirmDownloadClick('csv')}
                style={{ margin: '5px 10px' }}
              >
                Download in csv
              </Button>
            </ComponentWrapper>
          </>
        )}

        {renderDoc && (
          <>
            <PDFDownloadLink
              document={
                <InvestorRegisterDoc
                  holders={newInvestorRegister && newInvestorRegister}
                  project={project}
                  selectedDate={selectedDate}
                />
              }
              fileName={`Investor_register_${project.title}_${moment(
                selectedDate,
              ).format('DD_MM_YYYY')}.pdf`}
            >
              {({ blob, url, loading, error }) => {
                //when the blob/document is ready, we can close the modal and spinner
                if (blob) {
                  setisLoadingData(false)
                }

                return downloadFile(
                  blob,
                  url,
                  `Investor_register_${project.title}_${moment(
                    selectedDate,
                  ).format('DD_MM_YYYY')}.pdf`,
                )
              }}
            </PDFDownloadLink>
          </>
        )}
      </ModalScroll>
    </Modal>
  )
}

// Function to get the earliest date from the list of transaction objects
function getEarliestDateFromTxData(txDataList) {
  if (!txDataList || !txDataList.length) {
    return null
  }

  let earliestDate = new Date(txDataList[0].time)

  for (let i = 1; i < txDataList.length; i++) {
    const currentDate = new Date(txDataList[i].time)
    if (currentDate < earliestDate) {
      earliestDate = currentDate
    }
  }

  return earliestDate
}

// Function to get the earliest date from the list of objects
function getEarliestDateFromInvestorRegister(list) {
  if (!list || !list.length) {
    return null
  }

  let earliestDate = getEarliestDateFromTxData(list[0].txData)

  for (let i = 1; i < list.length; i++) {
    const currentDate = getEarliestDateFromTxData(list[i].txData)
    if (currentDate != null && currentDate < earliestDate) {
      earliestDate = currentDate
    }
  }

  return earliestDate
}

RequestInvestorsListModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  onModalClose: PropTypes.func.isRequired,
}

export default RequestInvestorsListModal
