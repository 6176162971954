import React, { useContext } from 'react'
import Text from 'components/Text/Text.styles'
import {
  Background,
  Button,
  Row,
  Column,
  ComponentWrapper,
  SectionTitle,
  AuthContext,
} from '@stokr/components-library'
import backgroundImage from 'static/images/covers/rocket.png'
import { useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'
import Layout from 'components/Layout/Layout'
//import Layout from 'components/AdminDashboard/Layout/Layout'

const OopsPage = (props) => {
  let { logoutUser } = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <Layout>
      <Row main>
        <Column part={8}>
          <Background src={backgroundImage} mobileRatio="16:9" />
        </Column>

        <Column part={8} withBorder>
          <ComponentWrapper>
            <SectionTitle>Venture dashboard</SectionTitle>
          </ComponentWrapper>
          <ComponentWrapper noPaddingTop>
            <Text>
              <h3>{location.state?.title || 'OOPS...'}</h3>
              {
                <p>
                  {location.state?.message ||
                    'Something went wrong, please start again.'}
                </p>
              }
            </Text>
          </ComponentWrapper>

          <ComponentWrapper noPaddingTop>
            <Button
              fluid
              onClick={() => {
                if (location.state?.redirectUrl) {
                  Cookies.set('STOKR_REDIRECT_URL', location.state.redirectUrl)
                }
                if (location.state?.tokenExpired) {
                  logoutUser()
                }
                navigate(location.state.ctaUrl)
              }}
            >
              {location.state?.ctaLabel || 'BACK TO LOGIN'}
            </Button>
          </ComponentWrapper>
        </Column>
      </Row>
    </Layout>
  )
}

export default OopsPage
