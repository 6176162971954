import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { Modal, ModalInner } from 'components/Modal/Modal'
import Text from 'components/Text/Text.styles'
import DoubleButtons from 'components/DoubleButton/DoubleButton.styles'
import Button from 'components/Button/Button.styles'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
import Select from 'components/Input/Select'

const CommentPinModal = ({
  isOpen,
  pinnedComments,
  onModalClose,
  onButtonClick,
}) => {
  const [priority, setPriority] = useState(1)

  const options = [
    { key: 1, value: 1, label: '1' },
    { key: 2, value: 2, label: '2' },
    { key: 3, value: 3, label: '3' },
    { key: 4, value: 4, label: '4' },
    { key: 5, value: 5, label: '5' },
  ]

  const availableOptions = options.filter(
    option =>
      !pinnedComments.some(comment => comment.priority === option.value),
  )

  return (
    <Modal thin isOpen={isOpen} onClose={onModalClose}>
      <ModalInner>
        <Text>
          <h5>Pin comment</h5>
          <p>Select pinned comment priority</p>
        </Text>
        <ComponentWrapper noPaddingHorizontal>
          <Select
            options={availableOptions}
            label="Pin priority"
            id="priority-select"
            name="priority"
            value={priority}
            onChange={({ value }) => setPriority(value)}
          />
        </ComponentWrapper>
        <ComponentWrapper noPaddingHorizontal noPaddingBottom>
          <DoubleButtons>
            <Button secondary onClick={onModalClose}>
              Cancel
            </Button>
            <Button primary onClick={() => onButtonClick(priority)}>
              Pin comment
            </Button>
          </DoubleButtons>
        </ComponentWrapper>
      </ModalInner>
    </Modal>
  )
}

CommentPinModal.propTypes = {
  isOpen: PropTypes.bool,
  pinnedComments: PropTypes.instanceOf(Array),
  onModalClose: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
}

CommentPinModal.defaultProps = {
  isOpen: false,
  pinnedComments: [],
}

export default CommentPinModal
