import styled from 'styled-components/macro'

export const iconsMap = {
  expand: '\\e923',
  shrink: '\\e924',
  emoji: '\\e922',
  arrowDown: '\\e900',
  arrowLeft: '\\e901',
  arrowRight: '\\e902',
  arrowUp: '\\e903',
  heart: '\\e904',
  heartFill: '\\e921',
  lock: '\\e905',
  menu: '\\e906',
  pen: '\\e907',
  star: '\\e908',
  trash: '\\e909',
  unread: '\\e90a',
  user: '\\e90b',
  edit: '\\e90c',
  check: '\\e90d',
  inProgress: '\\e90e',
  warning: '\\e90f',
  logout: '\\e910',
  tokenHolder: '\\e911',
  teams: '\\e912',
  tokenSales: '\\e913',
  analytics: '\\e914',
  updates: '\\e915',
  overview: '\\e916',
  comments: '\\e917',
  hamburger: '\\e918',
  share: '\\e919',
  facebook: '\\e91a',
  linkedin: '\\e91b',
  medium: '\\e91c',
  reddit: '\\e91d',
  telegram: '\\e91e',
  twitter: '\\e91f',
  youtube: '\\e920',
  cog: '\\e926',
  pin: '\\e925',
  cross: '\\274C',
}

export const TooltipIcon = styled.i.attrs({
  className: 'ion ion-md-alert',
})`
  font-size: 18px;
  opacity: 0.2;
`

const Icon = styled.i`
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before {
    content: '${(props) => props.icon}';
  }
`

export default Icon
