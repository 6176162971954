import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  display: block;
  overflow: hidden;
  /* max-width: 360px; */

  ${props =>
    props.center &&
    `
    margin: 0 auto;
  `}
`

export const Chart = styled.div`
  display: block;

  .axisX {
    .domain {
      display: none;
    }

    .tick {
      line {
        display: none;
      }

      text {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 11px;
        line-height: 20px;
        color: ${props => props.theme.cGrey2};
      }
    }
  }

  .axisY {
    .domain {
      display: none;
    }

    .tick {
      line {
        display: none;
      }

      text {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 11px;
        line-height: 20px;
        color: ${props => props.theme.cGrey2};
      }

      &:nth-child(odd) {
        display: none;
      }
    }
  }
`

export const HeatmapLegend = styled.div`
  display: flex;
  padding: 0 5px;
  margin-left: 5px;
  width: calc(100% - 5px);
`

export const HeatmapLegendItem = styled.div`
  width: 20%;
  position: relative;
  margin-bottom: 21px;
  height: 8px;
  position: relative;

  &:before {
    background-color: ${props => props.color};
    border-radius: 5px;
    width: calc(100% + 5px);
    margin-left: -5px;
    content: '';
    height: 8px;
    position: absolute;
  }
`

export const HeatmapLegendNumber = styled.span`
  font-family: 'Open Sans';
  font-weight: 600;
  font-size: 11px;
  line-height: 20px;
  position: absolute;
  color: ${props => props.theme.cGrey2};
  bottom: -25px;
  right: -5px;

  ${props =>
    props.left &&
    `
    left: 0px;
  `}
`

export const HeatmapGradientLegend = styled.div`
  position: relative;
  height: 32px;
  width: 100%;
`

export const HeatmapGradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: ${props => props.begin};
  background-image: ${props =>
    `linear-gradient(to right, ${props.beginColor}, ${props.endColor})`};
`

export const HeatmapLabel = styled.span`
  display: block;
  position: absolute;
  bottom: 0;
  font-weight: 600;
  font-size: 11px;
  line-height: 12px;
  color: ${props => props.theme.cGrey2};

  ${props =>
    props.beginLabel &&
    `
    left: 0;
  `}

  ${props =>
    props.endLabel &&
    `
    right: 0;
  `}
`
