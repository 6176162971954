import styled from 'styled-components/macro'

export const ReplyRows = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const CommentsRow = styled.div`
  flex-grow: 1;
  height: 50%;
`

export const ReplyRow = styled.div``
