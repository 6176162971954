import styled from 'styled-components/macro'

export const Container = styled.div`
  position: relative;
  display: block;
  padding: 25px;
  font-size: 0;
  border-left: 10px solid ${props => props.theme.cLightGrey};

  ${props =>
    props.soft &&
    `
    border-left-color: ${props.theme.cPrimary};
  `}

  ${props =>
    props.hard &&
    `
    border-left-color: ${props.theme.cWarning};
  `}

  &:before {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    min-height: 120px;
  }
`

export const IconWrapper = styled.div`
  z-index: 1;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 20%;
  margin-right: -20%;
  padding-right: 25px;
  text-align: center;

  & > svg {
    fill: ${props => props.theme.cLightGrey};

    ${props =>
      props.soft &&
      `
      fill: ${props.theme.cPrimary};
    `}

    ${props =>
      props.hard &&
      `
      fill: ${props.theme.cWarning};
    `}
  }
`

export const ContentWrapper = styled.div`
  z-index: 0;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  padding-left: 20%;
  padding-right: 80px;
  font-size: 10px;
`

export const OptionsWrapper = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
`

export const DateBox = styled.div`
  font-family: 'Open Sans';
  font-weight: 400;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 2.06px;
`

export const Title = styled.h3`
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  margin-top: 11px;
`

export const Text = styled.p`
  margin-top: 12px;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.6px;
`

export const Type = styled.div`
  font-weight: 700;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 2.06px;
  text-transform: uppercase;
  margin-top: 11px;

  ${props =>
    props.soft &&
    `
    color: ${props.theme.cPrimary};
  `}

  ${props =>
    props.hard &&
    `
    color: ${props.theme.cWarning};
  `}
`
