import React from 'react'

const UpdateSoftSvg = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76"
    width="76"
    height="76"
  >
    <g transform="translate(-266 -1840)">
      <path d="m291.1 1842.2c-9.496 3.385-17.106 10.266-21.427 19.375-4.322 9.108-4.837 19.355-1.452 28.85 3.386 9.497 10.267 17.107 19.375 21.428 9.109 4.321 19.355 4.837 28.852 1.451a1.537 1.537 0 0 0-1.032-2.894c-8.723 3.11-18.136 2.637-26.503-1.333-8.367-3.969-14.688-10.96-17.798-19.683s-2.636-18.136 1.333-26.503c3.97-8.367 10.96-14.688 19.683-17.798 8.724-3.11 18.136-2.636 26.503 1.333 8.368 3.97 14.689 10.96 17.798 19.684 3.486 9.777 2.43 20.503-2.894 29.426a1.536 1.536 0 1 0 2.638 1.574 37.696 37.696 0 0 0 5.352-19.34c0-4.27-0.728-8.56-2.202-12.692-3.385-9.497-10.266-17.106-19.375-21.427-9.108-4.321-19.355-4.837-28.851-1.451z" />
      <path d="M303.769 1860.861c-.848 0-1.536.688-1.536 1.536v38.259a1.536 1.536 0 0 0 3.072 0v-38.259c0-.848-.688-1.536-1.536-1.536z" />
      <path d="m319.84 1873.1a1.536 1.536 0 1 0 2.173-2.173l-17.154-17.153c-0.6-0.6-1.573-0.6-2.172 0l-17.154 17.153a1.536 1.536 0 1 0 2.172 2.173l16.068-16.068z" />
    </g>
  </svg>
)

export default UpdateSoftSvg
