import React, { PureComponent } from 'react'
import Layout from 'components/AdminDashboard/Layout/Layout'
// import SEO from 'components/SEO/SEO'
import {
  Row,
  Column,
  ContentBox,
} from 'components/AdminDashboard/Grid/Grid.styles'
import {
  Card,
  //  CardHeader,
  CardTitle,
} from 'components/AdminDashboard/Card/Card.styles'
import { Modal, ModalInner, ModalInnerWithBorder } from 'components/Modal/Modal'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
/*
import ButtonTabs, {
  ButtonTab,
} from 'components/AdminDashboard/ButtonTabs/ButtonTabs'
*/
import Text from 'components/Text/Text.styles'
import TextEditor from 'components/AdminDashboard/TextEditor/TextEditor'
import {
  TextEditorHeader,
  ErrorMessageBox,
  ErrorMessage,
} from 'components/AdminDashboard/TextEditor/TextEditor.styles'
import Checkbox from 'components/Checkbox/Checkbox'
import Select from 'components/Input/Select'
import { SelectWrapper } from 'components/Input/Select.styles'
import Input from 'components/Input/Input'
import DatePickerInput from 'components/Input/DatePickerInput'
import { AuthConsumer } from '@stokr/components-library'
import { ProjectContext } from 'context/ProjectContext/ProjectContext'

class AdminUpdates extends PureComponent {
  static contextType = ProjectContext

  state = {
    isModalOpen: false,
    type: 'normal',
    title: '',
    eventDate: '',
    publicationDate: '',
    error: '',
    message: '',
    notifyAll: false,
    updatesType: 'published',
    project: undefined,
  }

  componentDidMount() {
    const project = this.context.project

    if (project) {
      this.setState({
        project: project,
      })
    }
  }

  componentDidUpdate() {
    const project = this.context.project

    if (project) {
      this.setState({
        project: project,
      })
    }
  }

  onModalOpen = () => {
    this.setState({
      isModalOpen: true,
    })
  }

  onModalClose = () => {
    // this.setState({
    //   isModalOpen: false,
    // })
    this.resetForm()
  }

  setNewUpdateType = (e) => {
    this.setState({
      type: e.value,
    })
  }

  setUpdatesType = (type) => {
    this.setState({
      updatesType: type,
    })
  }

  updateMessage = (e) => {
    this.setState({
      message: e.target.value,
    })
  }

  updateTitle = (e) => {
    this.setState({
      title: e.target.value,
    })
  }

  updateEventDate = (e) => {
    this.setState({
      eventDate: e,
    })
  }

  updatePublicationDate = (e) => {
    this.setState({
      publicationDate: e,
    })
  }

  toggelNotifyAll = (e) => {
    const { notifyAll } = this.state
    this.setState({
      notifyAll: !notifyAll,
    })
  }

  resetForm = () => {
    this.setState({
      isModalOpen: false,
      type: 'normal',
      title: '',
      eventDate: '',
      publicationDate: '',
      message: '',
      error: '',
      notifyAll: false,
      updatesType: 'published',
    })
  }

  requestUpdateEmail = async (sendEmail, authUser) => {
    const {
      type,
      title,
      eventDate,
      publicationDate,
      message,
      notifyAll,
      project,
    } = this.state

    try {
      await sendEmail({
        venture: project.name,
        venture_email: authUser.email,
        update_type: type,
        update_title: title,
        update_happening_date: eventDate.toLocaleDateString(),
        update_publication_date: publicationDate.toLocaleDateString(),
        update_description: notifyAll
          ? `NOTIFY ALL INVESTORS - ${message}`
          : message,
      })

      this.onModalOpen()
    } catch (error) {
      console.log('error: ', error)
      this.setState({ error: 'Something went wrong' })
    }
  }

  render() {
    const {
      isModalOpen,
      type,
      message,
      title,
      eventDate,
      publicationDate,
      notifyAll,
      updatesType,
      error,
    } = this.state

    return (
      <Layout currentPage="updates">
        {/* <SEO
          title="Admin Dashboard - updates"
          keywords={['gatsby', 'application', 'react']}
        /> */}
        <Row mainDashboard>
          <Column>
            <ContentBox>
              <Row>
                <Column>
                  <Card>
                    <Text>
                      <h3>Request update</h3>
                    </Text>
                    <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                      <CardTitle>
                        Add new update
                        <SelectWrapper minWidth="120px">
                          <Select
                            id="type"
                            name="type"
                            value={type}
                            options={[
                              { key: '1', value: 'normal', label: 'Normal' },
                              {
                                key: '2',
                                value: 'soft',
                                label: 'Soft cap reached',
                              },
                              {
                                key: '3',
                                value: 'hard',
                                label: 'Hard cap reached',
                              },
                            ]}
                            onChange={this.setNewUpdateType}
                          />
                        </SelectWrapper>
                      </CardTitle>
                    </ComponentWrapper>
                    <ComponentWrapper noPaddingHorizontal noPaddingBottom>
                      <TextEditorHeader>
                        <Row>
                          <Column partLg={6} partXl={8}>
                            <Input
                              dashboard
                              id="title"
                              name="title"
                              placeholder="Enter a title for your update"
                              value={title}
                              onChange={this.updateTitle}
                              onBlur={() => {}}
                            />
                          </Column>
                          <Column partMd={8} partLg={5} partXl={4}>
                            <DatePickerInput
                              dashboard
                              id="event-date"
                              name="event-date"
                              placeholder="Event date DD MM YYYY"
                              value={eventDate}
                              onChange={this.updateEventDate}
                              onBlur={() => {}}
                            />
                          </Column>
                          <Column partMd={8} partLg={5} partXl={4}>
                            <DatePickerInput
                              dashboard
                              id="publication-date"
                              name="publication-date"
                              placeholder="Publication date DD MM YYYY"
                              value={publicationDate}
                              onChange={this.updatePublicationDate}
                              onBlur={() => {}}
                            />
                          </Column>
                        </Row>
                      </TextEditorHeader>
                      <AuthConsumer>
                        {({ user, requestUpdateEmail }) => (
                          <>
                            <TextEditor
                              isUpdates
                              noRadiusTop
                              id="message"
                              name="message"
                              placeholder="Give your followers the latest buzz"
                              info="Write update request"
                              value={message}
                              onChange={this.updateMessage}
                              limit={280}
                              minHeight={110}
                              maxHeight={110}
                              done={{
                                label: 'Request update',
                                onClick: () =>
                                  this.requestUpdateEmail(
                                    requestUpdateEmail,
                                    user,
                                  ),
                              }}
                              cancel={{
                                label: 'Cancel',
                                onClick: () => {},
                              }}
                              extension={
                                <Checkbox
                                  id="notify-all"
                                  name="notify-all"
                                  text="Notify all investors"
                                  value="notify-all"
                                  checked={notifyAll}
                                  onChange={this.toggelNotifyAll}
                                />
                              }
                            />
                            <ErrorMessageBox>
                              <ErrorMessage>{error && error}</ErrorMessage>
                            </ErrorMessageBox>
                          </>
                        )}
                      </AuthConsumer>
                    </ComponentWrapper>
                  </Card>
                </Column>
              </Row>
            </ContentBox>
          </Column>
        </Row>

        <Modal isOpen={isModalOpen} onClose={this.onModalClose} tiny>
          <ModalInner center>
            <Text>
              <h3>New update requested</h3>
              <ModalInnerWithBorder>
                <p>
                  {
                    'You have requested new update and STOKR admin will review it soon. You will receive a notification when the status of your update changes.'
                  }
                </p>
              </ModalInnerWithBorder>
            </Text>
          </ModalInner>
        </Modal>
      </Layout>
    )
  }
}

export default AdminUpdates
