import styled from 'styled-components/macro'

export const Chart = styled.div`
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;

  .axisX,
  .axisY {
    & > path {
      stroke: #e1e1e1;
    }

    .tick {
      text {
        font-family: 'Open Sans';
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        /* fill: #9b9b9b; */
        color: ${props => props.theme.cGrey2};
      }

      line {
        stroke: none;
      }
    }
  }

  .axisY {
    & > path {
      stroke: none;
    }
  }

  .gridX,
  .gridY {
    & > path {
      stroke: none;
    }
  }

  .gridX {
    .tick line {
      stroke: none;
    }
  }

  .gridY {
    .tick line {
      stroke: #dfe5f1;
    }
  }
`

export default Chart

export const TooltipWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
`

export const BarChartTooltip = styled.div`
  font-size: 10px;
  min-width: 40px;

  & > h3 {
    font-weight: 400;
    font-family: 'Open Sans';
    font-size: 16px;
    margin: 0;
    padding: 0;
  }

  & > p {
    /* color: ${props => props.theme.cProgressDone}; */
    color: #79ca65;
    font-family: 'Open Sans';
    font-size: 11px;
    text-transform: uppercase;
  }
`

export const ChartTitle = styled.div`
  font-family: 'Open Sans';
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
`
