import React from 'react'

const UpdateSoftSvg = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 76 76"
    width="76"
    height="76"
  >
    <g transform="translate(-266 -1398)">
      <path d="M317.119 1442.937a1.234 1.234 0 0 1 .445-1.372l9.583-7.03c1.526-1.12 2.134-3.006 1.549-4.805-.585-1.8-2.185-2.968-4.077-2.977l-11.885-.055c-.532-.003-1-.343-1.167-.848l-3.725-11.286c-.593-1.797-2.199-2.958-4.091-2.958-1.892 0-3.498 1.16-4.09 2.957l-1.68 5.086a1.536 1.536 0 1 0 2.918.963l1.679-5.085c.26-.787.963-.848 1.173-.848.21 0 .913.06 1.173.848l3.725 11.286a4.304 4.304 0 0 0 4.07 2.958l11.886.055c.828.004 1.104.654 1.169.854.064.199.224.887-.445 1.377l-9.582 7.03a4.304 4.304 0 0 0-1.555 4.786l3.621 11.324c.253.79-.28 1.253-.45 1.376-.17.123-.775.487-1.447.003l-9.648-6.943a4.304 4.304 0 0 0-5.033 0l-9.65 6.944c-.673.484-1.278.12-1.447-.003-.17-.123-.703-.586-.45-1.375l3.62-11.326a4.304 4.304 0 0 0-1.555-4.785l-9.582-7.03c-.669-.49-.51-1.18-.444-1.378.064-.2.34-.85 1.168-.854l11.886-.055a1.537 1.537 0 0 0-.007-3.073h-.007l-11.886.056c-1.892.008-3.492 1.177-4.077 2.976-.585 1.8.023 3.686 1.549 4.805l9.582 7.03c.428.315.608.866.446 1.373l-3.62 11.325c-.577 1.803.04 3.686 1.571 4.798.768.557 1.65.837 2.532.837.875 0 1.751-.276 2.517-.826l9.649-6.945a1.234 1.234 0 0 1 1.443 0l9.648 6.943c1.536 1.106 3.517 1.102 5.048-.01 1.531-1.113 2.148-2.996 1.572-4.798z" />
      <path d="m303.75 1398c-20.815 0-37.75 16.935-37.75 37.75s16.935 37.75 37.75 37.75a1.537 1.537 0 0 0 0-3.073c-19.121 0-34.677-15.556-34.677-34.677s15.556-34.677 34.677-34.677 34.677 15.556 34.677 34.677c0 11.902-5.997 22.837-16.043 29.25a1.537 1.537 0 0 0 1.654 2.59c10.934-6.98 17.462-18.883 17.462-31.84 0-20.815-16.934-37.75-37.75-37.75z" />
    </g>
  </svg>
)

export default UpdateSoftSvg
