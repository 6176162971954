import React from 'react'
import styled from 'styled-components/macro'
import { useNavigate } from 'react-router-dom'

import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import { Row, Column } from 'components/Grid/Grid.styles'
import Text from 'components/Text/Text.styles'
import ComponentWrapper from 'components/ComponentWrapper/ComponentWrapper.styles'
import Button from 'components/Button/Button.styles'
import routes from 'routes'

const TextBig = styled.div`
  font-size: 128px;
  font-weight: bold;
`

const NotFoundPage = () => {
  const navigate = useNavigate()
  return (
    <Layout>
      <SEO title="404: Not found" />
      <Row main>
        <Column part={8} mainFixed>
          <ComponentWrapper center>
            <TextBig>404</TextBig>
          </ComponentWrapper>
        </Column>

        <Column part={8} mainRelative>
          <ComponentWrapper center>
            <Text>
              <h3>Whooops, something went wrong</h3>
            </Text>
          </ComponentWrapper>
          <ComponentWrapper center>
            <Button onClick={() => navigate('/')}>Go to homepage</Button>
          </ComponentWrapper>
          {process.env.NODE_ENV === 'development' && (
            <ul>
              {routes.map((route) => (
                <li style={{ fontSize: '14px', lineHeight: '24px' }}>
                  <a href={route.path}>{route.path}</a>
                </li>
              ))}
            </ul>
          )}
        </Column>
      </Row>
    </Layout>
  )
}

export default NotFoundPage
