import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { iconsMap } from 'components/Icon/Icon.style'

import {
  Container,
  Toggler,
  OptionsWrapper,
  Options,
  Option,
  StyledIcon,
} from './ItemOptions.styles'

class ItemOptions extends PureComponent {
  state = {
    isOpened: false,
  }

  toggleOptions = () => {
    const { isOpened } = this.state

    this.setState({
      isOpened: !isOpened,
    })
  }

  render() {
    const { isOpened } = this.state
    const { icon, options } = this.props

    return (
      <Container>
        <Toggler onClick={this.toggleOptions} isActive={isOpened}>
          <StyledIcon large icon={iconsMap[icon]} />
        </Toggler>
        <OptionsWrapper isOpened={isOpened} items={options.length}>
          <Options items={options.length}>
            {options.map((option) => {
              const optionProps = {
                key: option.name,
              }

              if (option.onClick) {
                optionProps.onClick = option.onClick
              } else if (option.href) {
                optionProps.href = option.href
                optionProps.as = 'a'
              } else if (option.to) {
                optionProps.to = option.to
                optionProps.as = Link
              }

              return (
                <Option {...optionProps}>
                  <StyledIcon icon={iconsMap[option.icon]} />
                </Option>
              )
            })}
          </Options>
        </OptionsWrapper>
      </Container>
    )
  }
}

export const optionsPropTypes = PropTypes.arrayOf(
  PropTypes.shape({
    name: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    href: PropTypes.string,
    to: PropTypes.string,
  }),
)

ItemOptions.propTypes = {
  options: optionsPropTypes.isRequired,
  icon: PropTypes.string,
}

ItemOptions.defaultProps = {
  icon: 'menu',
}

export default ItemOptions
