import styled from 'styled-components/macro'

export const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin: 0 12px;
  font-size: 0;
  line-height: 0;

  ${props =>
    props.noMargin &&
    `
    margin: 0;
  `}
  ${props =>
    props.noMarginLeft &&
    `
    margin-left: 0;
  `}
  ${props =>
    props.noMarginRight &&
    `
    margin-right: 0;
  `}

  & > div {
    position: relative;
    display: inline-block!important;
  }
`

export const Icon = styled.i.attrs({
  className: 'ion ion-md-alert',
})`
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  line-height: 16px;
  vertical-align: middle;
  color: ${props => props.theme.cGrey2};
  transition: color 0.2s;

  &:hover {
    color: ${props => props.theme.cPrimary};
  }
`
