import styled from 'styled-components/macro'

const gradients = props => ({
  blue: `linear-gradient(to right, ${props.theme.cProgressBlueStart} , ${
    props.theme.cProgressBlueStop
  })`,
  red: `linear-gradient(to right, ${props.theme.cProgressRedStart} , ${
    props.theme.cProgressRedStop
  })`,
  green: `linear-gradient(to right, ${props.theme.cProgressAccentStart} , ${
    props.theme.cProgressAccentStop
  })`,
  redInverted: '#e1e1e1',
})

export const Bar = styled.div`
  position: relative;
  display: block;
  height: 10px;
  background-color: #e1e1e1;
  border-radius: 5px;
  overflow: hidden;

  ${props =>
    props.colorTheme === 'redInverted' && `background: ${gradients(props).red}`}
`

export const ProgressBar = styled.div`
  width: ${props => `${Math.min(props.progress, 100)}%`};
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 5px;

  ${props => {
    if (!props.colorTheme) return ''
    return `background: ${gradients(props)[props.colorTheme]}`
  }}
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const Label = styled.div`
  margin-top: 8px;
  font-size: 11px;
  line-height: 20px;
  margin-left: auto;
  color: ${props => props.theme.cGrey2};

  ${props => props.labelSuccess && `color: ${props.theme.cSuccess}`}
`
