import React from 'react'
import PropTypes from 'prop-types'

import { StyledTab, LinkText } from './DashboardTabsNav.styles'

const DashboardTabNav = ({ children, isActive, onClick }) => (
  <StyledTab onClick={onClick}>
    <LinkText isActive={isActive}>{children}</LinkText>
  </StyledTab>
)

DashboardTabNav.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
}

DashboardTabNav.defaultProps = {
  isActive: false,
  onClick: () => {},
}

export default DashboardTabNav
