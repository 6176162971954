import React from 'react'
import PropTypes from 'prop-types'

import { Table, Row, Label, Value } from './BasicTable.styles'

const BasicTable = ({ data }) => (
  <Table>
    {data.map((item, itemKey) => (
      <Row key={item.label}>
        <Label>{item.label}</Label>
        <Value>{item.value}</Value>
      </Row>
    ))}
  </Table>
)

BasicTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default BasicTable
