import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import { Tooltip } from 'react-tippy'

/*
  inspired on https://blog.risingstack.com/d3-js-tutorial-bar-charts-with-javascript/
*/

import { Chart, TooltipWrapper, ChartTitle } from './BarChart.styles'

const gradients = {
  default: { start: '#0050ca', end: '#0050ca' },
  red: { start: '#f24f45', end: '#ee220d' },
  blue: { start: '#6a97e0', end: '#0050ca' },
}

class BarChart extends PureComponent {
  transitionDuration = 800

  transitionDatumDelay = 100

  state = {
    tooltipOpen: false,
    dataIndex: 0,
  }

  componentDidMount() {
    this.createChart()
    window.addEventListener('resize', this.onResize)
    window.addEventListener('scroll', this.onScroll)
    this.onScroll()
  }

  componentWillUnmount() {
    clearTimeout(this.resizeTimeout)
    window.removeEventListener('resize', this.onResize)
    window.removeEventListener('scroll', this.onScroll)
  }

  onResize = () => {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(this.onResizeTimeout, 200)
  }

  onResizeTimeout = () => {
    this.updateChart(true)
  }

  onScroll = e => {
    if (this.drawn) return
    if (this.chartEl.getBoundingClientRect().top < window.innerHeight) {
      this.initialDrawChart()
    }
  }

  initialDrawChart = () => {
    this.updateChart()
    this.drawn = true
  }

  createChart = () => {
    if (!this.chartEl) return

    const { id, data, colorTheme } = this.props
    const initData = data.map(datum => ({
      ...datum,
      value: 0,
    }))
    const { dataIndex } = this.state
    const tooltip = !!data[dataIndex].tooltip

    const wrapperWidth = this.chartEl.offsetWidth
    const wrapperHeight = 320
    const margin = {
      top: 20,
      left: 0,
      right: 40,
      bottom: 30,
    }
    const width = wrapperWidth - (margin.left + margin.right)
    const height = wrapperHeight - (margin.top + margin.bottom)
    const thickness = 6
    const hThickness = thickness / 2
    this.hThickness = hThickness

    // #region x scale
    const xScale = this.getXScale(data, width)
    // #endregion x scale

    // #region y scale
    const yScale = this.getYScale(data, height)
    // #endregion y scale

    this.svg = d3
      .select(this.chartEl)
      .append('svg')
      .attr('width', wrapperWidth)
      .attr('height', wrapperHeight)

    this.chart = this.svg
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`)

    const defs = this.svg.append('defs')

    // #region gradient
    const gradient = defs
      .append('linearGradient')
      .attr('id', `${id}-barChart-gradient`)
      .attr('x1', '50%')
      .attr('x2', '50%')
      .attr('y1', '100%')
      .attr('y2', '0%')

    gradient
      .append('stop')
      .attr('class', 'start')
      .attr('offset', '0%')
      .attr('stop-color', gradients[colorTheme].start)
      .attr('stop-opacity', 1)

    gradient
      .append('stop')
      .attr('class', 'end')
      .attr('offset', '100%')
      .attr('stop-color', gradients[colorTheme].end)
      .attr('stop-opacity', 1)
    // #endregion gradient

    // #region grid x axis
    this.xGrid = this.chart
      .append('g')
      .attr('class', 'gridX')
      .attr('transform', `translate(0, ${height})`)
      .call(
        d3
          .axisBottom()
          .scale(xScale)
          .tickSize(-height, 0, 0)
          .tickFormat(''),
      )
    // #endregion grid x axis

    // #region grid y axis
    this.yGrid = this.chart
      .append('g')
      .attr('class', 'gridY')
      .attr('transform', `translate(${width}, 0)`)
      .call(
        d3
          .axisRight()
          .scale(yScale)
          .tickSize(-width, 0, 0)
          .ticks(4)
          .tickFormat(''),
      )
    // #endregion grid y axis

    const tickValues =
      xScale.domain().length > 20
        ? xScale.domain().filter((datum, index) => !(index % 2))
        : xScale.domain()

    // #region x axis
    this.xAxis = this.chart
      .append('g')
      .attr('class', 'axisX')
      .attr('transform', `translate(0, ${height})`)
      .call(
        d3
          .axisBottom()
          .scale(xScale)
          .tickValues(tickValues),
      )
    // #endregion x axis

    // #region y axis
    this.yAxis = this.chart
      .append('g')
      .attr('class', 'axisY')
      .attr('transform', `translate(${width}, 0)`)
      .call(
        d3
          .axisRight()
          .scale(yScale)
          .ticks(4),
      )
    // #endregion y axis

    // #region bars
    const chartContext = this
    this.chart
      .selectAll()
      .data(initData)
      .enter()
      .append('path')
      .attr('class', 'bar')
      .style('fill', `url(#${id}-barChart-gradient)`)
      .attr('transform', `translate(${-hThickness}, 0)`)
      .each(function bindContext(d, i) {
        d3.select(this).node().chartContext = chartContext
      })
      .on('mouseenter', tooltip ? this.onMouseEnter : () => {})
      .on('mouseleave', tooltip ? this.onMouseLeave : () => {})
      .attr(
        'd',
        this.getBarDAttrFn(xScale, yScale, height, hThickness, thickness),
      )
    // #endregion bars
  }

  setTooltip = (opened, dataIndex) => {
    const newState = {
      tooltipOpen: opened,
    }

    if (dataIndex !== undefined) newState.dataIndex = dataIndex

    this.setState(newState)
  }

  onMouseEnter = function onMouseEnter(d, i) {
    const chartRect = this.chartContext.chartEl.getBoundingClientRect()
    const barRect = this.getBoundingClientRect()
    const y = barRect.y - chartRect.y
    const x = barRect.x - chartRect.x + this.chartContext.hThickness
    this.chartContext.tooltipEl.style.left = `${x}px`
    this.chartContext.tooltipEl.style.top = `${y}px`
    this.chartContext.setTooltip(true, i)
  }

  onMouseLeave = function onMouseLeave(d, i) {
    this.chartContext.setTooltip(false)
  }

  updateChart = resizeUpdate => {
    if (!this.chartEl) return

    const { data } = this.props

    const durration = this.transitionDuration
    const delay = this.transitionDatumDelay

    const wrapperWidth = this.chartEl.offsetWidth
    const wrapperHeight = 320
    const margin = {
      top: 20,
      left: 0,
      right: 40,
      bottom: 30,
    }
    const width = wrapperWidth - (margin.left + margin.right)
    const height = wrapperHeight - (margin.top + margin.bottom)
    const thickness = 6
    const hThickness = thickness / 2

    // #region x scale
    const xScale = this.getXScale(data, width)
    // #endregion x scale

    // #region y scale
    const yScale = this.getYScale(data, height)
    // #endregion y scale

    // #region root svg
    this.svg
      .transition()
      .duration(durration)
      .attr('width', wrapperWidth)
      .attr('height', wrapperHeight)
    // #endregion root svg

    // #region grid x axis
    this.xGrid
      .transition()
      .duration(durration)
      .attr('transform', `translate(0, ${height})`)
      .call(
        d3
          .axisBottom()
          .scale(xScale)
          .tickSize(-height, 0, 0)
          .tickFormat(''),
      )
    // #endregion grid x axis

    // #region grid y axis
    this.yGrid
      .transition()
      .duration(durration)
      .attr('transform', `translate(${width}, 0)`)
      .call(
        d3
          .axisRight()
          .scale(yScale)
          .tickSize(-width, 0, 0)
          .ticks(4)
          .tickFormat(''),
      )
    // #endregion grid y axis

    const tickValues =
      xScale.domain().length > 20
        ? xScale.domain().filter((datum, index) => !(index % 2))
        : xScale.domain()

    // #region x axis
    this.xAxis
      .transition()
      .duration(durration)
      .attr('transform', `translate(0, ${height})`)
      .call(
        d3
          .axisBottom()
          .scale(xScale)
          .tickValues(tickValues),
      )
    // #endregion x axis

    // #region y axis
    this.yAxis
      .transition()
      .duration(durration)
      .attr('transform', `translate(${width}, 0)`)
      .call(
        d3
          .axisRight()
          .scale(yScale)
          .ticks(4),
      )
    // #endregion y axis

    // #region bars
    this.chart
      .selectAll('.bar')
      .data(data)
      .transition()
      .delay((_, index) => (resizeUpdate ? 0 : index * delay))
      .duration(durration)
      .attr(
        'd',
        this.getBarDAttrFn(xScale, yScale, height, hThickness, thickness),
      )
    // #endregion bars
  }

  getBarDAttrFn = (xScale, yScale, height, hThickness, thickness) => datum => `
    M${xScale(datum.name)},${yScale(datum.value) + hThickness}
    a${hThickness},${hThickness} 0 0 1 ${hThickness},${-hThickness}
    a${hThickness},${hThickness} 0 0 1 ${hThickness},${hThickness}
    v${height - yScale(datum.value) - hThickness}
    h${-thickness}Z
  `

  getXScale = (data, width) =>
    d3
      .scaleBand()
      .range([0, width])
      .domain(data.map(datum => datum.name))
      .paddingInner(1)
      .paddingOuter(0.5)

  getYScale = (data, height) =>
    d3
      .scaleLinear()
      .range([height, 0])
      .domain([0, d3.max(data, datum => Math.ceil(datum.value / 10) * 10)])

  render() {
    const { tooltipOpen, dataIndex } = this.state
    const { data, title } = this.props

    return (
      <Fragment>
        {title && <ChartTitle>{title}</ChartTitle>}
        <Chart
          ref={el => {
            this.chartEl = el
          }}
        >
          {data[dataIndex].tooltip && (
            <TooltipWrapper
              ref={el => {
                this.tooltipEl = el
              }}
            >
              <Tooltip
                open={tooltipOpen}
                position="top"
                animation="fade"
                html={data[dataIndex].tooltip}
                theme="light"
                arrow
                duration={200}
              />
            </TooltipWrapper>
          )}
        </Chart>
      </Fragment>
    )
  }
}

BarChart.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number.isRequired,
      name: PropTypes.string,
      tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    }),
  ).isRequired,
  colorTheme: PropTypes.oneOf(['default', 'red', 'blue']),
}

BarChart.defaultProps = {
  title: '',
  colorTheme: 'default',
}

export default BarChart
