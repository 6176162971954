import styled, { css } from 'styled-components/macro'
import { Link } from 'react-router-dom'

const styles = css`
  position: relative;
  font-family: 'Open Sans';
  font-weight: bold;
  font-size: 11px;
  line-height: 14px;
  text-transform: uppercase;
  display: inline-block;
  padding-left: 18px;
  padding-right: 6px;
  transition: padding 0.2s;

  &:before {
    content: '\\f3d1';
    font-family: 'Ionicons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
    line-height: 8px;
    display: block;
    width: 8px;
    height: 8px;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -4px;
    color: ${(props) => props.theme.cWarning};
  }

  &:hover {
    padding-left: 24px;
    padding-right: 0;
  }
`

const TextLink = styled(Link)`
  ${styles}
`

export const TextButton = styled.button`
  ${styles}
`

export default TextLink
