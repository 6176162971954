import React from 'react'
import PropTypes from 'prop-types'
import { Popup } from 'semantic-ui-react'
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

import { Container, EmojiIcon } from './EmojiPicker.styles'

const EmojiPicker = ({ handleSelect }) => (
  <Container>
    <Popup
      interactive
      on="click"
      position="bottom center"
      content={
        <Picker
          data={data}
          onEmojiSelect={handleSelect}
          skin={4}
          emojiSize={16}
          perLine="16"
        />
      }
      trigger={<EmojiIcon />}
    />
  </Container>
)

EmojiPicker.propTypes = {
  handleSelect: PropTypes.func,
}

EmojiPicker.defaultProps = {
  handleSelect: () => {},
}

export default EmojiPicker
