import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'

import {
  Container,
  Bar,
  LabelBox,
  Label,
  Value,
} from './BasicDonutChartLegend.styles'

class BasicDonutChartLegend extends PureComponent {
  componentDidMount() {
    this.createChart()
  }

  createChart = () => {
    if (!this.barEl) return

    const { color, smallBar } = this.props

    const width = smallBar ? 25 : 70
    const thickness = smallBar ? 2 : 8
    const radius = thickness / 2

    this.svg = d3
      .select(this.barEl)
      .append('svg')
      .attr('width', width)
      .attr('height', thickness)

    // #region bars
    this.bar = this.svg
      .append('path')
      .attr('class', 'bar')
      .style('fill', color)
      .attr(
        'd',
        item => `
        M 0 0
        A ${radius} ${radius} 0 0 1 ${radius} ${-radius}
        H ${width - radius}
        A ${radius} ${radius} 0 0 1 ${width} 0
        A ${radius} ${radius} 0 0 1 ${width - radius} ${radius}
        H ${radius}
        A ${radius} ${radius} 0 0 1 0 0 
        Z
      `,
      )
      .attr('transform', `translate(0, ${radius})`)
    // #endregion bars
  }

  render() {
    const { label, percent, value, smallBar } = this.props

    return (
      <Container>
        <Bar
          smallBar={smallBar}
          ref={el => {
            this.barEl = el
          }}
        />
        <LabelBox smallBar={smallBar}>
          <Label>
            <strong>{label}</strong>
            {percent}
          </Label>
          {value && <Value>{value}</Value>}
        </LabelBox>
      </Container>
    )
  }
}

BasicDonutChartLegend.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string.isRequired,
  percent: PropTypes.string.isRequired,
  value: PropTypes.string,
  smallBar: PropTypes.bool,
}

BasicDonutChartLegend.defaultProps = {
  color: '#202020',
  value: '',
  smallBar: false,
}

export default BasicDonutChartLegend
