import styled, { css } from 'styled-components/macro'
import rwd from 'styles/rwd'

const ComponentWrapper = styled.div`
  position: relative;
  padding: 32px;

  ${rwd.Large`
    padding: 32px 64px;
  `}

  ${(props) =>
    props.borderLeft &&
    css`
      border-left: 1px solid ${props.theme.cLightGrey};
    `}

  ${(props) =>
    props.borderRight &&
    css`
      border-right: 1px solid ${props.theme.cLightGrey};
    `}

  ${(props) =>
    props.borderTop &&
    css`
      border-top: 1px solid ${props.theme.cLightGrey};
    `}

  ${(props) =>
    props.borderBottom &&
    css`
      border-bottom: 1px solid ${props.theme.cLightGrey};
    `}

  ${(props) =>
    props.noPaddingLeft &&
    css`
      padding-left: 0 !important;
    `}

  ${(props) =>
    props.noPaddingRight &&
    css`
      padding-right: 0 !important;
    `}

  ${(props) =>
    props.noPaddingTop &&
    css`
      padding-top: 0 !important;
    `}

  ${(props) =>
    props.noPaddingBottom &&
    css`
      padding-bottom: 0 !important;
    `}

  ${(props) =>
    props.noPaddingVertical &&
    css`
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    `}

  ${(props) =>
    props.horizontalPaddingHalf &&
    css`
      padding-left: 18px !important;
      padding-right: 18px !important;
    `}

  ${(props) =>
    props.noPaddingHorizontal &&
    css`
      padding-left: 0 !important;
      padding-right: 0 !important;
    `}

  ${(props) =>
    props.paddingVeticalHalf &&
    css`
      padding-top: 16px !important;
      padding-bottom: 16px !important;
    `}

  ${(props) =>
    props.noPadding &&
    css`
      padding: 0 !important;
    `}

  ${(props) =>
    props.center &&
    css`
      text-align: center;
    `}

  ${(props) =>
    props.grow &&
    css`
      flex-grow: 1;
    `}

  ${(props) =>
    props.background &&
    css`
      background-color: ${props.theme.cGrey};
    `}

  ${(props) =>
    props.fluid &&
    css`
      width: 100%;
    `}

    ${(props) =>
    props.isAddMember &&
    css`
      @media screen and (min-width: 768px) {
        padding-left: 10px !important;
        padding-right: 10px !important;
      }
    `}

    ${(props) =>
    props.flex &&
    `
        display:flex;
        align-items: center;
    `}
`

export default ComponentWrapper
