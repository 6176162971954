import styled, { css } from 'styled-components/macro'
import rwd from 'styles/rwd'

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;

  ${(props) =>
    props.noMobileScroll &&
    css`
      & > div > div {
        overflow: visible !important;

        > div:first-child {
          overflow: visible !important;
        }
      }

      ${rwd.Medium`
      & > div > div {
        overflow: hidden !important;

        > div:first-child {
          overflow: scroll !important;  
        }
      }
    `}
    `}
`

export const TrackH = styled.div`
  background-color: ${(props) => props.theme.cGrey};
  left: 16px;
  right: 16px;
  bottom: 14px;
  height: 4px;
  border-radius: 4px;

  ${rwd.Large`
    left: 32px;
    right: 32px;
    bottom: 30px;
  `}
`

export const ThumbH = styled.div`
  background-color: ${(props) => props.theme.cWarning};
  height: 4px;
  border-radius: 4px;
`

export const TrackV = styled.div`
  background-color: ${(props) => props.theme.cGrey};
  top: 16px;
  bottom: 16px;
  right: 14px;
  width: 4px;
  border-radius: 4px;

  ${rwd.Large`
    top: 32px;
    bottom: 32px;
    right: 30px;
  `}
`

export const ThumbV = styled.div`
  background-color: ${(props) => props.theme.cWarning};
  width: 4px;
  border-radius: 4px;
`

export const Outer = styled.div`
  position: relative;
  flex-grow: 1;
  max-height: 100%;
  max-width: 100%;

  ${(props) =>
    props.barPadding > -1 &&
    props.offset &&
    `
   // margin-right: ${props.offset}px;
    margin-bottom: ${props.offset}px;

    ${TrackH} {
      left: ${props.barPadding}px;
      right: ${props.barPadding - props.offset}px;
      bottom: 0;
    }

    ${TrackV} {
      top: ${props.barPadding}px;
      bottom: ${props.barPadding - props.offset}px;
      right: 0;
    }
  `}

  ${(props) =>
    props.topPadding !== 0 &&
    `
    ${TrackV} {
      top: ${props.topPadding}px;
    }
  `}
`

export const Inner = styled.div`
  position: relative;

  ${(props) =>
    props.offset &&
    `
    padding-right: ${-props.offset}px;
    padding-bottom: ${-props.offset}px;
  `}
`
